import { ReceivingEntryContract } from "api/types/contracts/receivings";
import BoxSelection from "./_BoxSelection";
import { Card } from "reactstrap";
import { useTranslation } from "react-i18next";
import ProcessStorage from "./Storage/_ProcessStorage";
import ProcessFBA from "./FBA/_ProcessFBA";
import { processReceivingEntry, selectBox } from "slices/receivings/thunk";
import Loader from "Components/Common/Loader";
import { useEffect } from "react";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import { useSearchParams } from "react-router-dom";
import { createAppSelector, useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";

type ProcessProps = {
    receivingEntry: ReceivingEntryContract
};

const Process = ({ receivingEntry }: ProcessProps) => {
    const [params, setParams] = useSearchParams();
    const boxId = params.get("box");
    const { t } = useTranslation();
    const { setPageTitle } = useMetadata();
    const dispatch = useAppDispatch();
    
    const { selectedBox, processedEntry } = useAppSelector(
        createAppSelector([state => state.Receiving],
            (receiving) => ({
                selectedBox: receiving.selectedBox,
                processedEntry: receiving.processedEntry,
            })
        )
    );

    setPageTitle(`${t("Process Receiving")} - ${t("Receivings")}`);

    // useEffect(() => {
    //     dispatch(processReceivingEntry(receivingEntry)).then(() => {
    //         if (boxId) {
    //             const box = receivingEntry.boxes.find(b => b.receivingBoxId === boxId);

    //             if (box) {
    //                 dispatch(selectBox(box));
    //             }
    //         }
    //     });
    // }, [receivingEntry]);

    if (!processedEntry) {
        return <Loader height="500px" />;
    }
    return <>
    </>;
}

export default Process;