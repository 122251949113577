import { useTranslation } from "react-i18next";
import { InboundContext } from "./InboundContext";
import { useContext } from "react";
import { Card } from "reactstrap";
import InboundStatusBadge from "Components/Displays/InboundStatusBadge";
import DateDisplay from "Components/Displays/DateDisplay";
import ListSummaryDisplay from "Components/Common/ListSummaryDisplay";
import AddressDisplay from "Components/Displays/AddressDisplay";
import { useProfile } from "Components/Hooks/ProfileHooks";
import Restricted from "Components/Common/Restricted";
import InboundStatusSelect from "Components/EnumSelects/InboundStatusSelect";
import { useMutation } from "@tanstack/react-query";
import { patchInbound } from "api/inbound";
import BusyOverlay from "Components/Common/BusyOverlay";

const InformationSidebar = () => {
    const { t } = useTranslation();
    const [inbound, setInbound] = useContext(InboundContext);
    const { userProfile } = useProfile();

    const updateInboundMutation = useMutation({
        mutationFn: patchInbound,
        onSuccess: result => {
            if (result) {
                setInbound(result);
            }
        }
    });

    return <>
        <Card body className="border border-dark-subtle">
            <dl className="vstack gap-3">
                <div>
                    <dt>{t("Status")}</dt>
                    <dd>
                        <Restricted require="receiving.inbound" write fallback={() => <InboundStatusBadge value={inbound.inboundStatus} />}>
                            <BusyOverlay busy={updateInboundMutation.isPending} size="sm">
                                <InboundStatusSelect value={inbound.inboundStatus} onChange={val => {
                                    updateInboundMutation.mutate({
                                        inboundId: inbound.inboundId,
                                        inbound: {
                                            status: val
                                        }
                                    });
                                }} />
                            </BusyOverlay>
                        </Restricted>
                    </dd>
                </div>
                <div>
                    <dt>{t("Shipment Plan ID")}</dt>
                    <dd># {inbound.inboundCode}</dd>
                </div>
                <div>
                    <dt>{t("Create Date")}</dt>
                    <dd><DateDisplay date={inbound.createdAt} /></dd>
                </div>
                <div>
                    <dt>{t("Last Update")}</dt>
                    <dd><DateDisplay date={inbound.updatedAt ?? inbound.createdAt} /></dd>
                </div>
                <div>
                    <dt>{t("Warehouse")}</dt>
                    <dd>{inbound.warehouse.name}</dd>
                </div>
                <div>
                    <dt>{t("Suppliers")}</dt>
                    <dd><ListSummaryDisplay items={inbound.suppliers} displayFn={s => s.name} displayCount={2} /></dd>
                </div>
                <div>
                    <dt>{t("Ship To")}</dt>
                    <dd><AddressDisplay address={inbound.warehouse.address} inboundCode={inbound.inboundCode} warehouseName={inbound.warehouse.name} /></dd>
                </div>
                {userProfile?.user.userId !== inbound.owner.userId && <div>
                    <dt>{t("Owner")}</dt>
                    <dd>{inbound.owner.name}</dd>
                </div>}
            </dl>
        </Card>
    </>;
}

export default InformationSidebar;