import { ColumnDef } from "@tanstack/react-table";
import TitleBreadcrumb from "Components/Common/TitleBreadcrumb";
import Dialog, { DialogRef } from "Components/Common/Dialog";
import Restricted from "Components/Common/Restricted";
import TableContainer from "Components/Common/TableContainer";
import CustomerDisplay from "Components/Displays/CustomerDisplay";
import DateDisplay from "Components/Displays/DateDisplay";
import FbaShipmentStatusBadge from "Components/Displays/FbaShipmentStatusBadge";
import CompanySelect from "Components/EntitySelects/CompanySelect";
import CustomerSelect from "Components/EntitySelects/CustomerSelect";
import DateRangeInput from "Components/Form/DateRangeInput";
import SearchBox from "Components/Form/SearchBox";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import { FbaShipmentContract, FbaShipmentStatus } from "api/types/contracts/shipping";
import { ListFbaShipmentsQuery } from "api/types/queries";
import _ from "lodash";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col, Container, Row, UncontrolledTooltip } from "reactstrap";
import { useImmer } from "use-immer";
import StatusPills from "./_StatusPills";
import Currency from "Components/Displays/UnitDisplay/Currency";
import CountryDisplay from "Components/Displays/CountryDisplay";
import { useMutation, useQuery } from "@tanstack/react-query";
import { patchCancelFbaShipping, getFbaShipmentsList, postDuplicateFbaShipment } from "api/fbaShipping";
import BusyOverlay from "Components/Common/BusyOverlay";
import { toast } from "react-toastify";

const ListFbaShipmentsPage = () => {
    const [query, updateQuery] = useImmer<ListFbaShipmentsQuery>({
        page: 1,
        pageSize: 10
    });

    const { t } = useTranslation();
    const { setPageTitle } = useMetadata();
    const dialogRef = useRef<DialogRef>(null);
    const [selectedItems, setSelectedItems] = useState<FbaShipmentContract[]>([]);
    const fbaShipment = useMemo<FbaShipmentContract | undefined>(() => selectedItems[0], [selectedItems]);
    const [selectedStatus, setSelectedStatus] = useState<FbaShipmentStatus>();

    setPageTitle(`${t("FBA Shipments List")} - ${t("FBA Prep & Shipping")}`);
    
    const { data: list, isFetching: loading, refetch: reload } = useQuery({
        queryKey: ["fba-shipments", query],
        queryFn: () => getFbaShipmentsList(query)
    })

    const filteredList = useMemo(() => (list?.items || []).filter(i => selectedStatus ? i.status === selectedStatus : true), [list, selectedStatus]);
    
    
    const cancelFbaShipmentMutation = useMutation({
        mutationFn: patchCancelFbaShipping,
        mutationKey: ["cancel-fba-shipment"],
        onSuccess: () => {
            setSelectedItems([]);
            dialogRef.current?.hide();
            reload();
        }
    });
    
    const duplicateFbaShipmentMutation = useMutation({
        mutationFn: postDuplicateFbaShipment,
        mutationKey: ["duplicate-fba-shipment"],
        onSuccess: () => {
            toast.success(t("Shipment copied"));
            setSelectedItems([]);
            reload();
        }
    });

    const handleCancelClick = (arg: FbaShipmentContract) => {
        setSelectedItems([arg]);
        dialogRef.current?.show();
    };

    const columns = useMemo<ColumnDef<FbaShipmentContract, any>[]>(() => [
        {
            header: t("ACTIONS"),
            enableHiding: false,
            cell: (cell) => {
                return <>
                    <div className="hstack gap-1">
                        {cell.row.original.status !== "draft" ? <>
                            <Restricted require="shipping.fbashipping" write ownership={cell.row.original} fallback={() => <Button color="ghost-primary" className="btn-icon" size="sm" disabled id={`fba-shipment-${cell.row.original.fbaShipmentId}-view`}>
                                    <i className="ri-eye-fill fs-16"></i>
                                </Button>}>
                                <Link to={`/fba-shipment/${cell.row.original.fbaShipmentId}`} className="btn btn-ghost-primary btn-icon btn-sm" id={`fba-shipment-${cell.row.original.fbaShipmentId}-view`}>
                                    <i className="ri-eye-fill fs-16"></i>
                                </Link>
                            </Restricted>
                            <Restricted require="shipping.fbashipping" create fallback={() => <Button color="ghost-success" className="btn-icon" size="sm" disabled id={`fba-shipment-${cell.row.original.fbaShipmentId}-copy`}>
                                    <i className="ri-file-copy-fill fs-16"></i>
                                </Button>}>
                                <BusyOverlay busy={duplicateFbaShipmentMutation.isPending && duplicateFbaShipmentMutation.variables.fbaShipmentId === cell.row.original.fbaShipmentId} 
                                    backgroundColor="body-secondary"
                                    spinnerColor="tenant-primary"
                                    size="sm"
                                    inline
                                    opaque>
                                    <Button color="ghost-success" size="sm" className="btn-icon" id={`fba-shipment-${cell.row.original.fbaShipmentId}-copy`} onClick={() => duplicateFbaShipmentMutation.mutate({ 
                                        fbaShipmentId: cell.row.original.fbaShipmentId 
                                    })}>
                                        <i className="ri-file-copy-fill fs-16"></i>
                                    </Button>
                                </BusyOverlay>
                            </Restricted>
                            <UncontrolledTooltip target={`fba-shipment-${cell.row.original.fbaShipmentId}-view`} placement="top">
                                {t("View")}
                            </UncontrolledTooltip>
                            <UncontrolledTooltip target={`fba-shipment-${cell.row.original.fbaShipmentId}-copy`} placement="top">
                                {t("Duplicate")}
                            </UncontrolledTooltip>
                        </> : <>
                            <Restricted require="shipping.fbashipping" create fallback={() => <Button color="ghost-primary" className="btn-icon" size="sm" disabled id={`fba-shipment-${cell.row.original.fbaShipmentId}-continue`}>
                                    <i className="ri-history-fill fs-16"></i>
                                </Button>}>
                                <Link to={`/get-a-quote/${cell.row.original.fbaShipmentId}`} className="btn btn-ghost-primary btn-icon btn-sm" id={`fba-shipment-${cell.row.original.fbaShipmentId}-continue`}>
                                    <i className="ri-history-fill fs-16"></i>
                                </Link>
                            </Restricted>
                            <UncontrolledTooltip target={`fba-shipment-${cell.row.original.fbaShipmentId}-continue`} placement="top">
                                {t("Continue to Get A Quote")}
                            </UncontrolledTooltip>
                        </>}
                        {["created", "productPending", "fnskuPending", "ready"].includes(cell.row.original.status) && <>
                            <Restricted require="shipping.fbashipping" delete ownership={cell.row.original} fallback={() => <Button color="ghost-danger" className="btn-icon" size="sm" disabled id={`fba-shipment-${cell.row.original.fbaShipmentId}-cancel`}>
                                    <i className="ri-forbid-2-fill fs-16"></i>
                                </Button>}>
                                <Link to="#" onClick={() => handleCancelClick(cell.row.original)} id={`fba-shipment-${cell.row.original.fbaShipmentId}-cancel`}
                                    className="btn btn-ghost-danger btn-icon btn-sm">
                                    <i className="ri-forbid-2-fill fs-16"></i>
                                </Link>
                            </Restricted>
                            <UncontrolledTooltip target={`fba-shipment-${cell.row.original.fbaShipmentId}-cancel`} placement="top">
                                {t("Cancel")}
                            </UncontrolledTooltip>
                        </>}
                        {cell.row.original.status === "draft" && <>
                            <Restricted require="shipping.fbashipping" delete ownership={cell.row.original} fallback={() => <Button color="ghost-danger" className="btn-icon" size="sm" disabled id={`fba-shipment-${cell.row.original.fbaShipmentId}-delete`}>
                                    <i className="ri-delete-bin-2-fill fs-16"></i>
                                </Button>}>
                                <Link to="#" onClick={() => handleCancelClick(cell.row.original)} id={`fba-shipment-${cell.row.original.fbaShipmentId}-delete`}
                                    className="btn btn-ghost-danger btn-icon btn-sm">
                                    <i className="ri-delete-bin-2-fill fs-16"></i>
                                </Link>
                            </Restricted>
                            <UncontrolledTooltip target={`fba-shipment-${cell.row.original.fbaShipmentId}-delete`} placement="top">
                                {t("Delete")}
                            </UncontrolledTooltip>
                        </>}
                    </div>
                </>;
            },
        },
        {
            header: t("ID"),
            accessorFn: item => item.shipmentCode,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => cell.row.original.status === "draft" ? <Restricted require="shipping.fbashipping" create fallback={() => <span># {cell.row.original.shipmentCode}</span>}>
                <Link to={`/get-a-quote/${cell.row.original.fbaShipmentId}`} className="fw-medium link-secondary">
                    # {cell.row.original.shipmentCode}
                </Link>
            </Restricted> : <Restricted require="shipping.fbashipping" write ownership={cell.row.original} fallback={() => <span># {cell.row.original.shipmentCode}</span>}>
                <Link to={`/fba-shipment/${cell.row.original.fbaShipmentId}`} className="fw-medium link-secondary">
                    # {cell.row.original.shipmentCode}
                </Link>
            </Restricted>,
        },
        {
            header: t("CUSTOMER"),
            accessorFn: item => `${item.customer.name} ${item.customer.surname}`,
            enableColumnFilter: false,
            cell: (cell) => <CustomerDisplay customer={cell.row.original.customer} />,
        },
        {
            header: t("CREATE DATE"),
            accessorFn: item => item.createdAt,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => <DateDisplay date={cell.row.original.createdAt} />,
        },
        {
            header: t("STATUS"),
            accessorFn: item => item.status,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => <FbaShipmentStatusBadge value={cell.row.original.status} />,
        },
        {
            header: t("TOTAL PRODUCT"),
            accessorFn: item => _.sumBy(item.items, i => i.count),
            enableHiding: false,
            enableColumnFilter: false
        },
        {
            header: t("ACCEPTED & REJECTED"),
            accessorFn: item => _.sumBy(item.items, i => i.accepted),
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => <div className="mb-1">{_.sumBy(cell.row.original.items, i => i.accepted)} / {_.sumBy(cell.row.original.items, i => i.rejected)}</div>
        },
        {
            header: t("SKU COUNT"),
            accessorFn: item => item.items.length,
            enableHiding: false,
            enableColumnFilter: false
        },
        {
            header: t("TOTAL PRICE"),
            accessorFn: item => item.estimatedCost.total,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => <Currency value={cell.getValue()} currency={cell.row.original.warehouse.settings.unitOfCurrency} />
        },
        {
            header: t("TO COUNTRY"),
            accessorFn: item => item.shipmentTarget.address.country.name,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => <CountryDisplay country={cell.row.original.shipmentTarget.address.country} />
        },
    ], [t, duplicateFbaShipmentMutation]);

    const statusCounts: Record<string, number> = useMemo(() => {
        const items = list?.items || [];
        return { ..._.chain(items).groupBy(i => i.status).mapValues(v => v.length).value(), all: items.length };
    }, [list?.items]);

    return <>
        <div className="page-content">
            <Container fluid>
                <TitleBreadcrumb active={t("FBA Shipments List")} parents={[t("FBA Prep & Shipping")]} />
                <Restricted require="shipping.fbashipping" throw>
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader className="border-0">
                                    <Row className="align-items-center gy-3">
                                        <div className="col-sm">
                                            <h5 className="card-title mb-0">{t("FBA Shipments List")}</h5>
                                        </div>
                                        <div className="col-sm-auto">
                                            <div className="d-flex gap-1 flex-wrap">
                                                <Restricted require="shipping.fbashipping" create>
                                                    <Link to="/get-a-quote" className="btn btn-info">
                                                        {t("Get A Quote")}
                                                    </Link>
                                                </Restricted>
                                            </div>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody className="pt-0">
                                    <div>
                                        <Row className="mb-2">
                                            <Restricted require="management.company" read>
                                                <Col sm={6} md={4}>
                                                    <CompanySelect value={query.companyId} onSelect={company => updateQuery(q => {
                                                        q.companyId = company?.companyId;
                                                    })} />
                                                </Col>
                                            </Restricted>
                                            <Restricted require="management.customer" read>
                                                <Col sm={6} md={4}>
                                                    <CustomerSelect value={query.customerId} companyId={query.companyId} onSelect={customer => updateQuery(q => {
                                                        q.customerId = customer?.customerId;
                                                    })} />
                                                </Col>
                                            </Restricted>
                                            <Col sm={6} md={4}>
                                                <DateRangeInput value={query.createDateRange} onChange={val => updateQuery(q => {
                                                    q.createDateRange = val;
                                                })} />
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col xs={12}>
                                                <SearchBox value={query.search || ""} placeholder="Search by Shipment ID (FBA- prefix), Box ID (BOX- prefix) or Customer Name"
                                                    onChange={val => updateQuery(q => {
                                                        q.search = val;
                                                    })}></SearchBox>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <StatusPills value={selectedStatus} onChange={setSelectedStatus} statusCounts={statusCounts} />
                                            </Col>
                                        </Row>
                                        <TableContainer
                                            busy={loading}
                                            columns={columns}
                                            nowrap
                                            data={filteredList}
                                            totalDataLength={list?.totalCount}
                                            pagination={{
                                                pageIndex: query.page - 1,
                                                pageSize: query.pageSize
                                            }}
                                            onPaginationChanged={pagination => updateQuery(q => {
                                                q.page = pagination.pageIndex + 1;
                                                q.pageSize = pagination.pageSize;
                                            })}
                                            divClass="mb-1"
                                            tableClass="align-middle"
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Dialog ref={dialogRef} color="warning" buttons={["yes", "no"]} busy={cancelFbaShipmentMutation.isPending} iconClass="ri-forbid-2-line"  
                        message={`Do you want to continue?`} title={`Cancelling shipment "${fbaShipment?.shipmentCode}"`}
                        onButtonClick={(button, hide) => {
                            if (button === "yes") {
                                cancelFbaShipmentMutation.mutate({
                                    fbaShipmentId: fbaShipment!.fbaShipmentId
                                });
                            }
                            else {
                                hide();
                            }
                        }} />
                </Restricted>
            </Container>
        </div>
    </>;
}

export default ListFbaShipmentsPage;