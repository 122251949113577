import TitleBreadcrumb from "Components/Common/TitleBreadcrumb";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import Restricted from "Components/Common/Restricted";
import WizardForm from "./_WizardForm";
import type { FbaShipmentContract } from "api/types/contracts/shipping";
import { useLoaderData } from "react-router-dom";
import ResolveWrapper from "Components/Common/ResolveWrapper";

type LoaderData = {
    fbaShipment: Promise<FbaShipmentContract> | undefined
} | undefined;

const GetAQuotePage = () => {
    const { t } = useTranslation();
    const { setPageTitle } = useMetadata();
    const loaderData = useLoaderData() as LoaderData;

    setPageTitle(`${t("Get A Quote")} - ${t("FBA Prep & Shipping")}`);

    return <>
        <div className="page-content">
            <Container fluid>
                <TitleBreadcrumb active={t("Get A Quote")} parents={[t("FBA Prep & Shipping")]} />
                <Restricted require="shipping.fbashipping" create throw>
                    {loaderData?.fbaShipment ? <ResolveWrapper promise={loaderData.fbaShipment}>
                        {(fbaShipment) => <WizardForm draft={fbaShipment} />}
                    </ResolveWrapper> : <WizardForm />}
                </Restricted>
            </Container>
        </div>
    </>;
}

export default GetAQuotePage;