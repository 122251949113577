import { ComponentProps, useMemo } from "react";
import { useTranslation } from "react-i18next";
import BusyOverlay from "./BusyOverlay";
import { useTenant } from "Components/Hooks/TenantHooks";
import { toQueryString } from "helpers/api_helper";
import { withCdn } from "helpers/urlHelper";
import classNames from "classnames";
import { Button } from "reactstrap";

type PrivateImageProps = ComponentProps<"img"> & {
    linkClassName?: string,
    src: string,
    openInNewTab?: boolean
}

const PrivateImage = (props: PrivateImageProps) => {
    const { t } = useTranslation();
    const { linkClassName, src, openInNewTab, ...rest } = props;
    const { storageAuthorization, refreshStorageAuthorization } = useTenant();
    
    const signedUrl = useMemo(() => {
        if (storageAuthorization.mode === "queryString") {
            const query = toQueryString(storageAuthorization.signatureValues);
            return withCdn(`${src}?${query}`);
        }
        else {
            return withCdn(src);
        }
    }, [storageAuthorization.mode, storageAuthorization.signatureValues, src]);

    return <>
        {storageAuthorization.ready ? openInNewTab ? <a className={classNames(linkClassName)} href={signedUrl} target="_blank" rel="noreferrer">
            <img alt="" src={signedUrl} {...rest} />
        </a> : <img alt="" src={signedUrl} {...rest} /> : 
        <BusyOverlay busy={storageAuthorization.loading} overlayClassName="w-100 h-100" size="sm" backgroundColor="body-secondary">
            <Button color="ghost-dark" block className="w-100 h-100" onClick={() => refreshStorageAuthorization()}>
                {t("Click here to show image")}
            </Button>
        </BusyOverlay>}
    </>;
};

export default PrivateImage;