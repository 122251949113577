import { useQuery } from "@tanstack/react-query";
import type { ColumnDef } from "@tanstack/react-table";
import { getCreditRequestList } from "api/payment";
import type { CreditRequestContract } from "api/types/contracts/payment";
import { ListCreditRequestsQuery } from "api/types/queries";
import TableContainer, { type TableContainerRef } from "Components/Common/TableContainer";
import CreditRequestStatusBadge from "Components/Displays/CreditLoadRequestBadge";
import CustomerDisplay from "Components/Displays/CustomerDisplay";
import DateDisplay from "Components/Displays/DateDisplay";
import Currency from "Components/Displays/UnitDisplay/Currency";
import WarehouseSelect from "Components/EntitySelects/WarehouseSelect";
import CreditRequestStatusSelect from "Components/EnumSelects/CreditRequestStatusSelect";
import PaymentMethodSelect from "Components/EnumSelects/PaymentMethodSelect";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import { forwardRef, useImperativeHandle, useMemo, useRef, type Ref, type RefAttributes } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { useImmer } from "use-immer";

export type CreditRequestListRef = {
    reload: VoidFunction
}

const List = (props: {}, ref: Ref<CreditRequestListRef>) => {
    const { t } = useTranslation();
    const { setPageTitle } = useMetadata();
    const tableRef = useRef<TableContainerRef>(null);
    const [query, updateQuery] = useImmer<ListCreditRequestsQuery>({
        page: 1,
        pageSize: 10,
    });

    const { data: creditRequests, isFetching: creditRequestLoading, refetch: reloadCreditRequests } = useQuery({
        queryKey: ["credit-requests", query],
        queryFn: () => getCreditRequestList(query)
    });

    useImperativeHandle(ref, () => {
        return {
            reload: () => {
                reloadCreditRequests();
            }
        };
    }, [reloadCreditRequests]);

    setPageTitle(`${t("Credit Load Requests")} - ${t("Billing")}`);

    const columns = useMemo<ColumnDef<CreditRequestContract, any>[]>(() => [
        {
            header: "",
            id: t("ACTIONS"),
            enableHiding: false,
            cell: (cell) => <>
                <div className="hstack gap-1">
                    <Link to={`/credit-requests/${cell.row.original.creditRequestId}`} className="btn btn-ghost-dark btn-sm btn-icon">
                        <i className="ri-eye-fill fs-16"></i>
                    </Link>
                </div>
            </>
        }, 
        {
            header: t("REQUEST ID"),
            accessorFn: item => item.requestCode,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => <>
                <Link to={`/credit-requests/${cell.row.original.creditRequestId}`} className="fw-medium link-secondary">
                    {cell.row.original.requestCode}
                </Link>
            </>
        },
        {
            header: t("WAREHOUSE"),
            accessorFn: item => item.warehouse.name,
            enableHiding: false,
            enableColumnFilter: false
        },
        {
            header: t("USER"),
            accessorFn: item => item.customer.name,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => <CustomerDisplay customer={cell.row.original.customer} />
        },
        {
            header: t("PAYMENT METHOD"),
            accessorFn: item => item.paymentMethod,
            enableColumnFilter: false,
            cell: (cell) => <span>{t(`enums:PaymentMethod.${cell.row.original.paymentMethod}`)}</span>
        },
        {
            header: t("AMOUNT"),
            accessorFn: item => item.requestedAmount,
            enableColumnFilter: false,
            cell: (cell) => {
                if (cell.row.original.status === "approved") {
                    return <Currency value={cell.row.original.approvedAmount} currency={cell.row.original.unitOfCurrency} />;
                }
                else {
                    return <Currency value={cell.row.original.requestedAmount} currency={cell.row.original.unitOfCurrency} />;
                }
            }
        },
        {
            header: t("STATUS"),
            accessorFn: item => item.status,
            enableColumnFilter: false,
            cell: (cell) => <CreditRequestStatusBadge value={cell.row.original.status} />
        },
        {
            header: t("DATE SUBMITTED"),
            accessorFn: item => item.createdAt,
            enableColumnFilter: false,
            cell: (cell) => <DateDisplay date={cell.row.original.createdAt} />
        }],
        [t]
    );

    return <>
        <Row>
            <Col>
                <Card>
                    <CardHeader className="border-0 pb-0">
                        <Row className="align-items-center gy-3">
                            <div className="col-sm">
                                <h4 className="card-title mb-0">{t("Credit Load Requests")}</h4>
                            </div>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <p className="mb-0">{t("paragraphs:CreditLoadRequestPageDescription")}</p>
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader className="border-0">
                        <Row className="align-items-center gy-3">
                            <div className="col-sm">
                                <h5 className="card-title mb-0">{t("Load Requests")}</h5>
                            </div>
                        </Row>
                    </CardHeader>
                    <CardBody className="pt-0">
                        <div>
                            <Row>
                                <Col sm={6} md={4}>
                                    <PaymentMethodSelect showClear value={query.paymentMethod} placeholder={t("All Methods")} onChange={val => {
                                        updateQuery(q => {
                                            q.paymentMethod = val;
                                            return q;
                                        });
                                    }} />
                                </Col>
                                <Col sm={6} md={4}>
                                    <CreditRequestStatusSelect showClear value={query.status} placeholder={t("All Statuses")} onChange={val => {
                                        updateQuery(q => {
                                            q.status = val;
                                            return q;
                                        });
                                    }} />
                                </Col>
                                <Col sm={6} md={4}>
                                    <WarehouseSelect showClear value={query.warehouseId} placeholder={t("All Warehouses")} onChange={val => {
                                        updateQuery(q => {
                                            q.warehouseId = val;
                                            return q;
                                        });
                                    }} />
                                </Col>
                            </Row>
                            <TableContainer
                                ref={tableRef}
                                busy={creditRequestLoading}
                                columns={columns}
                                nowrap
                                data={(creditRequests?.items || [])}
                                totalDataLength={creditRequests?.totalCount}
                                pagination={{
                                    pageIndex: query.page - 1,
                                    pageSize: query.pageSize
                                }}
                                onPaginationChanged={pagination => updateQuery(q => {
                                    q.page = pagination.pageIndex + 1;
                                    q.pageSize = pagination.pageSize;
                                })}
                                divClass="mb-1"
                                tableClass="align-middle"
                            />
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </>;
}

export default forwardRef(List) as (
    props: RefAttributes<CreditRequestListRef>
) => ReturnType<typeof List>;