import TextInput from "Components/Form/TextInput";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card } from "reactstrap";
import { InboundContext } from "./InboundContext";
import { useMutation } from "@tanstack/react-query";
import { patchInbound } from "api/inbound";
import BusyOverlay from "Components/Common/BusyOverlay";
import { useProfile } from "Components/Hooks/ProfileHooks";
import Restricted from "Components/Common/Restricted";

const Note = () => {
    const { t } = useTranslation();
    const [inbound, setInbound] = useContext(InboundContext);
    const [notes, setNotes] = useState(inbound.notes);
    const [internalNotes, setInternalNotes] = useState(inbound.internalNotes);
    const { userProfile } = useProfile();

    const updateInboundMutation = useMutation({
        mutationFn: patchInbound,
        onSuccess: result => {
            if (result) {
                setInbound(result);
            }
        }
    }); 

    return <>
        <Card body className="border border-dark-subtle">
            {userProfile?.user.userId === inbound.owner.userId ? <>
                <h6 className="fw-semibold text-body">{t("Note to Warehouse")}</h6>
                <div className="vstack gap-2">
                    <TextInput type="textarea" style={{ height: "8rem" }} onChange={setNotes} value={notes} />
                    <div>
                        <BusyOverlay busy={updateInboundMutation.isPending} size="sm" inline>
                            <Button color="secondary" className="btn-label" onClick={() => updateInboundMutation.mutate({
                                inboundId: inbound.inboundId,
                                inbound: {
                                    notes: notes
                                }
                            })}>
                                <i className="label-icon ri-save-line"></i>
                                {t("Save")}
                            </Button>
                        </BusyOverlay>
                    </div>
                </div>
            </> : <>
                <h6 className="fw-semibold text-body">{t("Customer Note")}</h6>
                <div>
                    {notes ? <div className="text-muted">{notes}</div> : <div className="text-muted">{t("No notes")}</div>}
                </div>
            </>}
        </Card>
        <Restricted require="receiving.inbound" write>
            <Card body className="border border-dark-subtle">
                <h6 className="fw-semibold text-body">{t("Internal Note")}</h6>
                <div className="vstack gap-2">
                    <TextInput type="textarea" style={{ height: "8rem" }} placeholder={t("Add notes for internal use")} onChange={setInternalNotes} value={internalNotes} />
                    <div>
                        <BusyOverlay busy={updateInboundMutation.isPending} size="sm" inline>
                            <Button color="secondary" className="btn-label" onClick={() => updateInboundMutation.mutate({
                                inboundId: inbound.inboundId,
                                inbound: {
                                    internalNotes: internalNotes
                                }
                            })}>
                                <i className="label-icon ri-save-line"></i>
                                {t("Save")}
                            </Button>
                        </BusyOverlay>
                    </div>
                </div>
            </Card>
        </Restricted>
    </>;
}

export default Note;