import TitleBreadcrumb from "Components/Common/TitleBreadcrumb";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, Container } from "reactstrap";
import SelectWarehouseStep from "./_SelectWarehouseStep";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import SelectProductsStep from "./_SelectProductsStep";
import { useMutation, useQuery } from "@tanstack/react-query";
import { postInbound, patchInbound, getInbound } from "api/inbound";
import type { InboundContract, InboundForm, InboundFormItem, InboundFormTracking, InboundPackingMethod, ShippingPurpose } from "api/types/contracts/inbound";
import type { TypedShape } from "helpers/types";
import * as Yup from "yup";
import { useFormik } from "formik";
import type { UpdateInboundCommand } from "api/types/commands";
import PackingDetailsStep from "./_PackingDetailsStep";
import ShippingInfoStep from "./_ShippingInfoStep";
import CompletedStep from "./_CompletedStep";
import InboundStatusBadge from "Components/Displays/InboundStatusBadge";
import { useParams } from "react-router-dom";
import Loader from "Components/Common/Loader";

const createInboundForm = (inbound: InboundContract): InboundForm => ({
    notes: inbound.notes,
    packingMethod: inbound.packingMethod,
    boxCount: inbound.boxCount,
    warehouseId: inbound.warehouse.warehouseId,
    estimatedArrivalDate: inbound.estimatedArrivalDate,
    items: inbound.items.map(item => ({
        quantity: item.quantity,
        productId: item.product.productId,
    })),
    trackings: inbound.trackings.map(tracking => ({
        trackingNumber: tracking.trackingNumber,
        carrierId: tracking.carrier.carrierId
    })),
    suppliers: inbound.suppliers.map(s => s.supplierId)
});

const steps = {
    selectWarehouse: 0,
    selectProducts: 1,
    packingDetails: 2,
    shippingInfo: 3,
    completed: 4
}

type CreateShippingPlanPageProps = {
    draft?: boolean
}

const CreateShippingPlanPage = ({ draft }: CreateShippingPlanPageProps) => {
    const { t } = useTranslation();
    const { setPageTitle } = useMetadata();
    const [inbound, setInbound] = useState<InboundContract>();
    const [step, setStep] = useState<keyof typeof steps>("selectWarehouse");
    const [maxStep, setMaxStep] = useState<keyof typeof steps>(draft ? "shippingInfo" : "selectWarehouse");
    const selectWarehouseStepRef = useRef<HTMLDivElement>(null);
    const selectProductsStepRef = useRef<HTMLDivElement>(null);
    const packingDetailsStepRef = useRef<HTMLDivElement>(null);
    const shippingInfoStepRef = useRef<HTMLDivElement>(null);
    const completedStepRef = useRef<HTMLDivElement>(null);
    const params = useParams<{ inboundId: string }>();

    setPageTitle(t("Create Shipping Plan"));

    useEffect(() => {
        if (step === "selectWarehouse") {
            selectWarehouseStepRef.current?.scrollIntoView({ behavior: "smooth" });
        }
        else if (step === "selectProducts") {
            selectProductsStepRef.current?.scrollIntoView({ behavior: "smooth" });
            if (steps[maxStep] < steps["selectProducts"]) {
                setMaxStep("selectProducts");
            }
        }
        else if (step === "packingDetails") {
            packingDetailsStepRef.current?.scrollIntoView({ behavior: "smooth" });
            if (steps[maxStep] < steps["packingDetails"]) {
                setMaxStep("packingDetails");
            }
        }
        else if (step === "shippingInfo") {
            shippingInfoStepRef.current?.scrollIntoView({ behavior: "smooth" });
            if (steps[maxStep] < steps["shippingInfo"]) {
                setMaxStep("shippingInfo");
            }
        }
        else if (step === "completed") {
            completedStepRef.current?.scrollIntoView({ behavior: "smooth" });
        }
    }, [step]);

    const createInboundMutation = useMutation({
        mutationFn: postInbound,
        onSuccess: inbound => {
            setInbound(inbound);
        }
    });

    const updateInboundMutation = useMutation({
        mutationFn: patchInbound,
        onSuccess: inbound => {
            setInbound(inbound);
        }
    });

    const inboundQuery = useQuery({
        queryKey: ["inbound", params.inboundId],
        queryFn: async () => getInbound({ inboundId: params.inboundId! }),
        enabled: draft && !!params.inboundId
    });

    useEffect(() => {
        if (inboundQuery.data) {
            setInbound(inboundQuery.data);
        }
    }, [inboundQuery.data]);

    useEffect(() => {
        if (inbound) {
            validation.setValues({
                inboundId: inbound.inboundId,
                inbound: createInboundForm(inbound),
            });
        }
    }, [inbound]);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {} as UpdateInboundCommand,
        validationSchema: Yup.object<UpdateInboundCommand>({
            inboundId: Yup.string().required(),
            inbound: Yup.object<InboundForm, TypedShape<InboundForm>>({
                notes: Yup.string().notRequired(),
                estimatedArrivalDate: Yup.date().notRequired(),
                packingMethod: Yup.string<InboundPackingMethod>().required(t("Packing Method is required")),
                boxCount: Yup.number().notRequired(),
                warehouseId: Yup.string().required(t("Warehouse is required")),
                items: Yup.array<InboundFormItem>().of(Yup.object({
                    productId: Yup.string().required(t("Product is required")),
                    quantity: Yup.number().required(t("Quantity is required"))
                })).required(t("Items is required")).min(1, t("At least one product is required")),
                trackings: Yup.array<InboundFormTracking>().of(Yup.object({
                    trackingNumber: Yup.string().required(t("Tracking Number is required")),
                    carrierId: Yup.string().required(t("Carrier is required"))
                })).required(t("Trackings is required")),
                suppliers: Yup.array().of(Yup.string().required()).notRequired()
            })
        }),
        onSubmit: async values => {
            await updateInboundMutation.mutateAsync(values);
        },
    });

    const inStep = (stepName: keyof typeof steps) => {
        return steps[step] >= steps[stepName];
    };

    const inMaxStep = (stepName: keyof typeof steps) => {
        return steps[maxStep] >= steps[stepName];
    }

    return <>
        <div className="page-content">
            <Container fluid>
                <TitleBreadcrumb title={t("Shipping Plans")} active={t("Create")} parents={[
                    t("Receiving"), {
                        title: t("Shipping Plan"),
                        to: "/shipping-plans"
                    }]} />
                {inboundQuery.isFetching ? 
                    <Loader height="500px" /> : <>
                    <Card body className="mb-4 hstack gap-2">
                        <span>{t("Create Shipping Plan")}</span>
                        {inbound && <>
                            <span>- # {inbound.inboundCode}</span>
                            <InboundStatusBadge value={inbound.inboundStatus} />
                        </>}
                    </Card>
                    <div className="vstack gap-2 mx-5">
                        <div ref={selectWarehouseStepRef}>
                            <SelectWarehouseStep 
                                editable={!inStep("completed")}
                                collapsed={step !== "selectWarehouse"} 
                                inbound={inbound}
                                onSaved={async warehouse => {
                                    if (!inbound) {
                                        await createInboundMutation.mutateAsync({
                                            warehouseId: warehouse.warehouseId
                                        });
                                    }
                                    setStep("selectProducts");
                                }} 
                                onEdit={() => {
                                    setStep("selectWarehouse");
                                }} />
                        </div>
                        {inMaxStep("selectProducts") && <div ref={selectProductsStepRef}>
                            <SelectProductsStep 
                                editable={!inStep("completed")}
                                collapsed={step !== "selectProducts"} 
                                inbound={inbound}
                                onSaved={async items => {
                                    await validation.setFieldValue("inbound.items", items);
                                    await validation.submitForm();
                                    setStep("packingDetails");
                                }} 
                                onEdit={() => {
                                    setStep("selectProducts");
                                }} />
                        </div>}
                        {inMaxStep("packingDetails") && <div ref={packingDetailsStepRef}>
                            <PackingDetailsStep
                                editable={!inStep("completed")}
                                collapsed={step !== "packingDetails"}
                                inbound={inbound}
                                onSaved={async values => {
                                    await validation.setFieldValue("inbound.packingMethod", values.packingMethod);
                                    await validation.setFieldValue("inbound.boxCount", values.boxCount);
                                    await validation.submitForm();
                                    setStep("shippingInfo");
                                }}
                                onEdit={() => {
                                    setStep("packingDetails");
                                }} />
                        </div>}
                        {inMaxStep("shippingInfo") && <div ref={shippingInfoStepRef}>
                            <ShippingInfoStep
                                editable={!inStep("completed")}
                                collapsed={step !== "shippingInfo"}
                                inbound={inbound!}
                                onSaved={async values => {
                                    await validation.setFieldValue("inbound.estimatedArrivalDate", values.estimatedArrivalDate);
                                    await validation.setFieldValue("inbound.notes", values.notes);
                                    await validation.setFieldValue("inbound.suppliers", values.suppliers);
                                    await validation.setFieldValue("confirm", true);
                                    await validation.submitForm();
                                    setStep("completed");
                                }} onEdit={() => {
                                    setStep("shippingInfo");
                                }} />
                        </div>}
                        {inStep("completed") && <div ref={completedStepRef}>
                            <CompletedStep
                                inbound={inbound!}
                            />
                        </div>}
                    </div>
                </>}
            </Container>
        </div>
    </>;
}

export default CreateShippingPlanPage;