import { Link } from 'react-router-dom';

type TitleBreadcrumbProps = {
    active?: string,
    title?: string,
    parents?: (string | {
        title: string,
        to: string
    })[]
}

const TitleBreadcrumb = ({ active, title, parents }: TitleBreadcrumbProps) => {

    return <div className="page-title-box d-sm-flex align-items-center justify-content-between">
        <h4 className="mb-sm-0">{title ?? active}</h4>

        <div className="page-title-right">
            <ol className="breadcrumb m-0">
                {parents?.map((parent, index) => 
                    <li key={index} className="breadcrumb-item">
                        {typeof parent === "string" ? <Link to="#">{parent}</Link> : <Link to={parent.to}>{parent.title}</Link>}
                    </li>
                )}
                {active && <li className="breadcrumb-item active">{active}</li>}
            </ol>
        </div>
    </div>;
};

export default TitleBreadcrumb;