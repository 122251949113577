import { APIClient, PagedList } from "helpers/api_helper";
import { GetReceivingEntryQuery, LatestReceivingEntriesQuery, ListReceivingEntriesQuery } from "./types/queries";
import { ReceivingEntryContract } from "./types/contracts/receivings";
import { AddReceivingBoxCommand, AddReceivingDocumentCommand, AssignRecevingProductsCommand, CreateReceivingEntryCommand, CreateReceivingProductLabelsCommand, FinalizeReceivingCommand, RemoveProductLabelAssignmentCommand, RemoveReceivingBoxCommand, ResumeReceivingCommand, SaveReceivingProductStockCommand, type SaveAcceptedProductsCommand, type SaveRejectedProductsCommand } from "./types/commands";
import type { InboundContract } from "./types/contracts/inbound";

const api = new APIClient();

export const getReceivingEntry = async (data: GetReceivingEntryQuery) => {
    const result = await api.get<ReceivingEntryContract>(`/api/receiving/${data.receivingEntryId}`);
    return result;
}

export const getLatestReceivingEntries = async (data: LatestReceivingEntriesQuery) => {
    const result = await api.get<ReceivingEntryContract[]>(`/api/receiving/latest`, data);
    return result;
}

export const getReceivingEntriesList = async (data: ListReceivingEntriesQuery) => {
    const result = await api.get<PagedList<ReceivingEntryContract>>(`/api/receiving`, data);
    return result;
}

export const postReceivingEntry = async (data: CreateReceivingEntryCommand) => {
    const response = await api.post(`/api/receiving`, data);
    
    return response.data as ReceivingEntryContract;
}

export const patchReceivingEntryBox = async (data: AddReceivingBoxCommand): Promise<void> => {
    await api.patch(`/api/receiving/${data.receivingEntryId}/box`, { box: data.box, boxQuantity: data.boxQuantity });
}

export const patchReceivingEntryDocument = async (data: AddReceivingDocumentCommand): Promise<void> => {
    await api.patch(`/api/receiving/${data.receivingEntryId}/document`, { ...data.document });
}

export const postReceivingProductLabels = async (data: CreateReceivingProductLabelsCommand): Promise<string[]> => {
    const response = await api.post(`/api/receiving/${data.receivingEntryId}/labels`, data);

    return response.data;
}

export const patchAssignProductLabel = async (data: AssignRecevingProductsCommand): Promise<void> => {
    const { serialNumber, ...rest } = data;
    await api.patch(`/api/receiving/label/${serialNumber}/assign`, rest);
}

export const patchSaveStocks = async (data: SaveReceivingProductStockCommand): Promise<void> => {
    await api.patch(`/api/receiving/${data.receivingEntryId}/box/${data.receivingBoxId}/stocks`, data.stocks);
}

export const deleteReceivingProductLabel = async (data: RemoveProductLabelAssignmentCommand): Promise<void> => {
    await api.delete(`/api/receiving/label/${data.serialNumber}`);
}

export const patchFinalizeReceivingEntry = async (data: FinalizeReceivingCommand): Promise<void> => {
    await api.patch(`/api/receiving/${data.receivingEntryId}/finalize`, {});
}

export const patchResumeReceivingEntry = async (data: ResumeReceivingCommand): Promise<void> => {
    await api.patch(`/api/receiving/${data.receivingEntryId}/resume`, {});
}

export const deleteReceivingBoxEntry = async (data: RemoveReceivingBoxCommand): Promise<void> => {
    await api.delete(`/api/receiving/${data.receivingEntryId}/box/${data.receivingBoxId}`);
}

export const putSaveAcceptedProducts = async (data: SaveAcceptedProductsCommand) => {
    const result = await api.put(`/api/receiving/accept`, { ...data });
    return result.data as InboundContract;
}

export const putSaveRejectedProducts = async (data: SaveRejectedProductsCommand) => {
    const result = await api.put(`/api/receiving/reject`, { ...data });
    return result.data as InboundContract;
}