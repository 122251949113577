import { useMutation } from "@tanstack/react-query";
import { postGenerateBarcodes } from "api/printing";
import { BarcodePrintInfo } from "api/types/contracts/printing";
import BusyOverlay from "Components/Common/BusyOverlay";
import Radiobox from "Components/Form/Radiobox";
import StepInput from "Components/Form/StepInput";
import { multiDownload, withCdn } from "helpers/urlHelper";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Badge, Button, ListGroup, ListGroupItem } from "reactstrap";

type PrintBarcodesProps = {
    barcodes: {
        barcode: string,
        receivedCount: number,
        expectedCount: number
    }[]
}

const PrintBarcodes = (props: PrintBarcodesProps) => {
    const { t } = useTranslation();
    const [printCount, setPrintCount] = useState(1);
    const [printCountOption, setPrintCountOption] = useState<"expected" | "received" | "custom">("custom");

    const printBarcodesMutation = useMutation({
        mutationFn: postGenerateBarcodes,
        onSuccess: results => {
            if (results[0].printQueued) {
                toast.success(t("Barcode(s) sent to printer"));
            }
            else {
                multiDownload(results.map(r => withCdn(r.filePath)));
            }
        }
    });

    const printBarcodes = () => {
        const barcodes = props.barcodes.map<BarcodePrintInfo>(b => {
            let processCount = 1;

            if (printCountOption === "expected") {
                processCount = b.expectedCount;
            }
            else if (printCountOption === "received") {
                processCount = b.receivedCount;
            }
            else {
                processCount = printCount;
            }

            return {
                barcode: b.barcode,
                quantity: processCount
            };
        });

        printBarcodesMutation.mutate({ barcodes });
    };

    return <div>
        <h5 className="mb-1">
            <span>{t("Print Barcode Options")}</span>
            {props.barcodes.length > 1 && <Badge color="fnsku" pill className="ms-2 bg-info-subtle text-info fw-normal">{t("{{count}} items", { count: props.barcodes.length })}</Badge>}
        </h5>
        <p className="small text-muted">{t("paragraphs:ShippingPlanPrintBarcodeModalDescription")}</p>
        <div className="fs-14">
            <ListGroup flush>
                <ListGroupItem>
                    <Radiobox name="printCount" id="printCount-custom" value={printCountOption === "custom"} onChange={val => setPrintCountOption("custom")} className="mt-2">
                        <div className="vstack gap-1">
                            <div className="hstack gap-3">
                                {props.barcodes.length > 1 ? 
                                    <span>{t("Specified Amount Barcode Per Item")}</span>
                                : 
                                    <span>{t("Specified Amount Barcode")}</span>
                                }
                                <div className="w-25">
                                    <StepInput min={1} value={printCount} onChange={val => {
                                        setPrintCount(val ?? 1);
                                        setPrintCountOption("custom");
                                    }} size="sm" />
                                </div>
                            </div>
                            <span className="text-muted small fw-normal">{t("paragraphs:ShippingPlanPrintBarcodeCustomQuantityDescription")}</span>
                        </div>
                    </Radiobox>
                </ListGroupItem>
                <ListGroupItem>
                    <Radiobox name="printCount" id="printCount-expected" value={printCountOption === "expected"} onChange={val => setPrintCountOption("expected")}>
                        <div className="vstack">
                            <span>{t("Print Barcode Per Expected Product")}</span>
                            <span className="text-muted small fw-normal">{t("paragraphs:ShippingPlanPrintBarcodeExpectedQuantityDescription")}</span>
                        </div>
                    </Radiobox>
                </ListGroupItem>
                <ListGroupItem>
                    <Radiobox name="printCount" id="printCount-received" value={printCountOption === "received"} onChange={val => setPrintCountOption("received")}>
                        <div className="vstack">
                            <span>{t("Print Barcode Per Received Product")}</span>
                            <span className="text-muted small fw-normal">{t("paragraphs:ShippingPlanPrintBarcodeReceivedQuantityDescription")}</span>
                        </div>
                    </Radiobox>
                </ListGroupItem>
            </ListGroup>
        </div>
        <div className="hstack justify-content-end">
            <BusyOverlay busy={printBarcodesMutation.isPending} size="sm">
                <Button color="info" onClick={printBarcodes} className="btn-label">
                    <i className="ri-printer-fill label-icon"></i>
                    {t("Print")}
                </Button>
            </BusyOverlay>
        </div>
    </div>;
}

export default PrintBarcodes;