import { useMutation } from "@tanstack/react-query";
import type { InboundContract } from "api/types/contracts/inbound";
import type { WarehouseContract } from "api/types/contracts/warehouses";
import BusyOverlay from "Components/Common/BusyOverlay";
import WarehouseGridSelect from "Components/EntitySelects/WarehouseGridSelect";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, CardHeader } from "reactstrap";

type SelectWarehouseStepProps = {
    editable: boolean,
    collapsed: boolean,
    inbound: InboundContract | undefined,
    onSaved: (warehouse: WarehouseContract) => Promise<void>,
    onEdit: () => void
};

const SelectWarehouseStep = (props: SelectWarehouseStepProps) => {
    const { t } = useTranslation();
    const [selectedWarehouse, setSelectedWarehouse] = useState<WarehouseContract>();

    useEffect(() => {
        if (props.inbound) {
            setSelectedWarehouse(props.inbound.warehouse);
        }
    }, [props.inbound]);

    const saveMutation = useMutation({
        mutationFn: props.onSaved
    });

    return <>
        <Card>
            <CardHeader className="py-1">
                <div className="hstack gap-3">
                    {props.collapsed ? <i className="ri-checkbox-circle-fill text-success ri-3x"></i>
                    : <i className="ri-checkbox-circle-line text-light-emphasis ri-3x"></i>}
                    <div>
                        <h4 className="card-title">{t("Select Warehouse")}</h4>
                        <p className="card-subtitle">{t("paragraphs:ShippingPlanSelectWarehouseHeader")}</p>
                    </div>
                    {props.collapsed ? <>
                        {selectedWarehouse && <div className="ms-auto text-muted">
                            {selectedWarehouse.name}
                        </div>}
                        <Button color="outline-info" disabled={!props.editable} className="btn-label" onClick={props.onEdit}>
                            <i className="label-icon ri-pencil-line"></i>
                            {t("Edit")}
                        </Button>
                    </> : <>
                        <BusyOverlay busy={saveMutation.isPending} size="sm" overlayClassName="ms-auto">
                            <Button color="info" disabled={!selectedWarehouse} className="btn-label" onClick={() => saveMutation.mutate(selectedWarehouse!)}>
                                <i className="label-icon ri-save-3-line"></i>
                                {t("Save & Continue")}
                            </Button>
                        </BusyOverlay>
                    </>}
                </div>
            </CardHeader>
            {!props.collapsed && <CardBody>
                <div className="px-5 py-4">
                    <h5 className="">{t("paragraphs:ShippingPlanSelectWarehouseTitle")}</h5>
                    <p>{t("paragraphs:ShippingPlanSelectWarehouseDescription")}</p>
                    <WarehouseGridSelect value={selectedWarehouse?.warehouseId} onSelect={setSelectedWarehouse} />
                    <p className="text-muted small">
                        <strong>{t("Tip")}</strong>! {t("paragraphs:ShippingPlanSelectWarehouseTip")}
                    </p>
                </div>
            </CardBody>}
        </Card>
    </>;
}

export default SelectWarehouseStep;