import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Badge, Card, Progress } from "reactstrap";
import { ShippingPlanContext } from "./ShippingPlanReceivingContext";

const ConsolidatedStatus = () => {
    const { t } = useTranslation();
    const [{ inbound }] = useContext(ShippingPlanContext);

    const consolidatedStatus = useMemo(() => {
        const total = _.sumBy(inbound.items, i => i.quantity);
        const received = _.sumBy(inbound.items, i => i.accepted + i.rejected);
        let status = "";

        if (total === 0) {
            status = t("Unreceived");
        }
        else if (received < total) {
            status = t("Partially Received");
        }
        else if (received === total) {
            status = t("Received");
        }
        else if (received > total) {
            status = t("Over Received");
        }

        return {
            status,
            totalQuantity: total,
            receivedQuantity: received,
            percentage: _.round((received / total) * 100)
        };
    }, [inbound, t]);
    
    return <Card body className="border border-dark-subtle mb-0">
        <h6 className="fw-semibold text-body">{consolidatedStatus.status}</h6>
        <div className="vstack gap-2">
            <Progress value={consolidatedStatus.percentage} />
            <div className="d-flex justify-content-between gap-3">
                <span>{t("{{receivedQuantity}} / {{totalQuantity}} units", consolidatedStatus)}</span>
                <Badge color="percent" className="bg-primary-subtle text-primary">{consolidatedStatus.percentage}%</Badge>
            </div>
        </div>
    </Card>;
}

export default ConsolidatedStatus;