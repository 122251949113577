import { useContext, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { InboundContext } from "./InboundContext";
import _ from "lodash";
import { Button, Card, Form, FormGroup, FormText, Label } from "reactstrap";
import Dialog, { type DialogRef } from "Components/Common/Dialog";
import { patchInbound } from "api/inbound";
import { useMutation } from "@tanstack/react-query";
import type { InboundTrackingContract } from "api/types/contracts/inbound";
import { useFormik } from "formik";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import CarrierSelect from "Components/EntitySelects/CarrierSelect";
import TextInput from "Components/Form/TextInput";
import BusyOverlay from "Components/Common/BusyOverlay";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import DateInput from "Components/Form/DateInput";
import { useProfile } from "Components/Hooks/ProfileHooks";
import DateDisplay from "Components/Displays/DateDisplay";

const Trackings = () => {
    const { t } = useTranslation();
    const [inbound, setInbound] = useContext(InboundContext);
    const removeConfirmDialogRef = useRef<DialogRef>(null);
    const [selectedTracking, setSelectedTracking] = useState<InboundTrackingContract>();
    const { userProfile } = useProfile();

    const updateInboundMutation = useMutation({
        mutationFn: patchInbound,
        onSuccess: result => {
            if (result) {
                setInbound(result);
            }
        }
    });

    const handleDeleteTracking = (tracking: InboundTrackingContract) => {
        setSelectedTracking(tracking);
        removeConfirmDialogRef.current?.show();
    }

    const validation = useFormik({
        initialValues: {
            carrierId: "",
            trackingNumber: ""
        },
        onSubmit: values => { 
            updateInboundMutation.mutate({
                inboundId: inbound.inboundId,
                inbound: {
                    trackings: [
                        ...inbound.trackings.map(t => ({
                            trackingNumber: t.trackingNumber,
                            carrierId: t.carrier.carrierId
                        })),
                        values
                    ]
                }
            }, {
                onSuccess: () => {
                    validation.resetForm();
                }
            });
        }
    });

    const trackings = useMemo(() => {
        return inbound.trackings.map(tn => {
            return {
                ...tn,
                trackingUrl: _.template(tn.carrier.trackingUrlTemplate)({ trackingNumber: tn.trackingNumber })
            };
        });
    }, [inbound.trackings]);

    return <>
        <Card body className="border border-dark-subtle">
            <h6 className="fw-semibold text-body">{t("Tracking Numbers")}</h6>
            {trackings.length > 0 ? <ul className="list-unstyled mb-2">
                {trackings.map((tn, i) => <li key={i}>
                    <span className="hstack gap-1 align-items-center">
                        {userProfile?.user.userId === inbound.owner.userId && <Button color="link" size="sm" className="btn-icon p-0 link-danger" onClick={() => handleDeleteTracking(tn)}>
                            <i className="ri-delete-bin-2-fill fs-16"></i>
                        </Button>}
                        <a href={tn.trackingUrl} target="_blank" rel="noreferrer" className="link-secondary text-decoration-underline">
                            {tn.carrier.name} {tn.trackingNumber}
                        </a>
                    </span>
                </li>)}
            </ul> : <div className="text-muted mb-2">
                <span>{t("No items")}</span>
            </div>}
            {userProfile?.user.userId === inbound.owner.userId && <Form onSubmit={validation.handleSubmit}>
                <div className="vstack gap-2 mb-2">
                    <ValidationWrapper validation={validation} field="carrierId">
                        <CarrierSelect placeholder={t("Select Carrier")} />
                    </ValidationWrapper>
                    <FormGroup noMargin>
                        <ValidationWrapper validation={validation} field="trackingNumber">
                            <TextInput placeholder={t("Tracking Number")} />
                        </ValidationWrapper>
                        <FormText className="fs-10">{t("Only one tracking number per field")}</FormText>
                    </FormGroup>
                    <FormGroup noMargin>
                        <BusyOverlay busy={updateInboundMutation.isPending} size="sm" inline>
                            <ValidatorButton type="submit" validation={validation} color="secondary" className="btn-label">
                                <i className="label-icon ri-add-line"></i>
                                {t("Add Tracking Number")}
                            </ValidatorButton>
                        </BusyOverlay>
                    </FormGroup>
                </div>
            </Form>}
            <div>
                <FormGroup noMargin>
                    <Label className="mb-1">{t("Expected Date")}</Label>
                    {userProfile?.user.userId === inbound.owner.userId ? <BusyOverlay busy={updateInboundMutation.isPending} size="sm">
                        <DateInput value={inbound.estimatedArrivalDate} onChange={val => {
                            updateInboundMutation.mutate({
                                inboundId: inbound.inboundId,
                                inbound: {
                                    estimatedArrivalDate: val
                                }
                            });
                        }} />
                    </BusyOverlay> : <div>
                        {inbound.estimatedArrivalDate ? <DateDisplay date={inbound.estimatedArrivalDate} /> : <span className="text-muted">{t("No date specified")}</span>}
                    </div>}
                </FormGroup>
            </div>
        </Card>
        <Dialog ref={removeConfirmDialogRef} color="danger" buttons={["yes", "no"]} busy={updateInboundMutation.isPending} iconClass="ri-delete-bin-line"  
            message={t('You are removing tracking number')} title={`Do you want to continue?`}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    updateInboundMutation.mutate({
                        inboundId: inbound.inboundId,
                        inbound: {
                            trackings: inbound.trackings.filter(t => t.trackingNumber !== selectedTracking?.trackingNumber).map(t => ({
                                trackingNumber: t.trackingNumber,
                                carrierId: t.carrier.carrierId
                            }))
                        }
                    }, {
                        onSuccess: () => {
                            hide();
                        }
                    });
                }
                else {
                    hide();
                }
            }} />
    </>;
}

export default Trackings;