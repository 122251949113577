import { useMutation } from "@tanstack/react-query";
import { putSaveRejectedProducts } from "api/receiving";
import { SaveRejectedProductsCommand } from "api/types/commands";
import type { InboundContract, InboundItemContract } from "api/types/contracts/inbound";
import type { ProductLabelContract } from "api/types/contracts/inventory";
import BusyOverlay from "Components/Common/BusyOverlay";
import TruncatableText from "Components/Common/TruncatableText";
import ProductImageDisplay from "Components/Displays/ProductImageDisplay";
import UnusableProductReasonSelect from "Components/EntitySelects/UnusableProductReasonSelect";
import StepInput from "Components/Form/StepInput";
import TextInput from "Components/Form/TextInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { useFormik } from "formik";
import type { TypedShape } from "helpers/types";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Button, Form, Table } from "reactstrap";
import * as Yup from "yup";

type RejectItemFormProps = {
    inboundId: string,
    item: InboundItemContract,
    unusables: ProductLabelContract[],
    onSave: (inbound: InboundContract) => void
}

const RejectItemForm = (props: RejectItemFormProps) => {
    const { t } = useTranslation();

    const rejectProductsMutation = useMutation({
        mutationFn: putSaveRejectedProducts,
        onSuccess: result => {
            props.onSave(result);
        }
    })

    const validation = useFormik({
        initialValues: {
            inboundId: props.inboundId,
            items: _.chain(props.unusables).groupBy(u => u.unusableProductReason!.unusableProductReasonId).map((group, unusableProductReasonId) => ({
                unusableProductReasonId,
                productId: props.item.product.productId,
                quantity: group.length,
                notes: group[0].notes
            })).value()
        },
        validationSchema: Yup.object<SaveRejectedProductsCommand, TypedShape<SaveRejectedProductsCommand>>({
            inboundId: Yup.string().required(),
            items: Yup.array().of(Yup.object({
                unusableProductReasonId: Yup.string().required(t("Reason is required")),
                productId: Yup.string().required(),
                quantity: Yup.number().required().min(1),
                notes: Yup.string().notRequired()
            })).required()
        }),
        onSubmit: values => {
            rejectProductsMutation.mutate(values);
        }
    });

    return <div className="mx-4">
        <Form onSubmit={validation.handleSubmit}>
            <h4>{t("Rejected")}</h4>
            <p className="text-muted small">
                {t("paragraphs:ShippingPlanRejectFormModalDescription")}
            </p>
            <div className="bg-light hstack gap-2 px-4 py-3 mx-n4 mb-2">
                <ProductImageDisplay product={props.item.product} className="flex-shrink-0" />
                <div className="p-2">
                    <TruncatableText maxLines={2}>{props.item.product.name}</TruncatableText>
                    <span className="text-muted">{props.item.product.sku}</span>
                </div>
                <div className="vstack justify-content-center w-50">
                    <span className="text-nowrap">{t("Total Rejected")}</span>
                    <span className="text-secondary fw-bold">{props.item.rejected}</span>
                </div>
            </div>
            <div>
                <Table className="table-nowrap">
                    <thead>
                        <tr>
                            <th style={{ width: "20%" }}>{t("Quantity")}</th>
                            <th style={{ width: "40%" }}>{t("Reason")}</th>
                            <th style={{ width: "35%" }}>{t("Notes")}</th>
                            <th style={{ width: "5%" }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {validation.values.items.length === 0 ? <tr>
                            <td colSpan={4} className="text-center text-muted">{t("No items")}</td>
                        </tr> : <>
                            {validation.values.items.map((product, i) => <tr key={i}>
                                <td>
                                    <ValidationWrapper validation={validation} field={`items.${i}.quantity`}>
                                        <StepInput size="sm" min={1} />
                                    </ValidationWrapper>
                                </td>
                                <td>
                                    <ValidationWrapper validation={validation} field={`items.${i}.unusableProductReasonId`}>
                                        <UnusableProductReasonSelect size="sm" />
                                    </ValidationWrapper>
                                </td>
                                <td>
                                    <div className="form-icon">
                                        <ValidationWrapper validation={validation} field={`items.${i}.notes`}>
                                            <TextInput className="form-control-icon" size="sm" />
                                        </ValidationWrapper>
                                        <i className="ri-edit-fill fs-18"></i>
                                    </div>
                                </td>
                                <td>
                                    <Button color="ghost-danger" size="sm" className="btn-icon" onClick={() => {
                                        validation.setFieldValue("items", validation.values.items.filter((_, j) => i !== j));
                                    }}>
                                        <i className="ri-delete-bin-fill fs-16"></i>
                                    </Button>
                                </td>
                            </tr>)}
                        </>}
                    </tbody>
                </Table>
                <Button color="info" className="btn-label" onClick={() => {
                    validation.setFieldValue("items", [...validation.values.items, {
                        unusableProductReasonId: "",
                        productId: props.item.product.productId,
                        quantity: 1,
                        notes: ""
                    }]);
                }}>
                    <i className="ri-add-line label-icon"></i>
                    {t("Add new reason")}
                </Button>
            </div>
            <div className="hstack justify-content-end">
                <BusyOverlay busy={rejectProductsMutation.isPending} size="sm" inline>
                    <ValidatorButton validation={validation} type="submit" color="secondary" className="btn-label">
                        <i className="ri-save-line label-icon"></i>
                        {t("Save")}
                    </ValidatorButton>
                </BusyOverlay>
            </div>
        </Form>
    </div>;
}

export default RejectItemForm;