import { AppDispatch, RootState } from "slices";
import { setList, loading, apiError, setProcessingItem } from "./reducer";
import { GetInboundQuery, ListInboundsQuery, SearchInboundsQuery } from "api/types/queries";
import { CancelInboundCommand, SetInboundCompletedCommand } from "api/types/commands";
import { ApiError } from "helpers/types";
import { toast } from "react-toastify";
import i18n from "i18n";
import { patchCancelInbound, getInbound, getInboundList, patchCompleteInbound, searchInbounds } from "api/inbound";

export const loadList = (params: ListInboundsQuery) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["list", true]));

        var result = await getInboundList(params);
        
        dispatch(setList(result));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["list", false]));
    }
};

export const searchList = (params: SearchInboundsQuery) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        var result = await searchInbounds(params);

        return result;
    } catch (error) {
        const apiError = error as ApiError;
    }
    finally {
        
    }
}

export const resetList = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setList({
        items: [],
        currentPage: 1,
        totalCount: 0
    }));
}

export const cancelInbound = (params: CancelInboundCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["delete", true]));

        await patchCancelInbound(params);
        toast.success(i18n.t("Inbound cancelled", { count: 1 }));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}

export const completeInbound = (params: SetInboundCompletedCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["save", true]));

        await patchCompleteInbound(params);
        toast.success(i18n.t("Updated"));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const loadInbound = (params: GetInboundQuery) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["load", true]));

        var result = await getInbound(params);
        
        dispatch(setProcessingItem(result));

        dispatch(loading(["load", false]));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
}