import { useMetadata } from "Components/Hooks/MetadataHooks";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Container } from "reactstrap";
import ShippingPlanView from "./_View";
import { InboundContext } from "./InboundContext";
import { useQuery } from "@tanstack/react-query";
import { getInbound } from "api/inbound";
import Loader from "Components/Common/Loader";
import type { InboundContract } from "api/types/contracts/inbound";
import { useEffect, useState } from "react";

const ShippingPlanDetailsPage = () => {
    const { inboundId } = useParams<{ inboundId: string }>();
    const { t } = useTranslation();
    const { setPageTitle } = useMetadata();
    const [inbound, setInbound] = useState<InboundContract>();

    const { data, isLoading, isSuccess } = useQuery({
        queryKey: ["inbound", inboundId],
        queryFn: async () => getInbound({ inboundId: inboundId! })
    });

    useEffect(() => {
        if (data) {
            setInbound(data);
        }
    }, [data]);

    useEffect(() => {
        setPageTitle(t(`{{inboundCode}} - Shipping Plan Details`, { inboundCode: inbound?.inboundCode }));
    }, [inbound]);

    return <>
        <div className="page-content">
            <Container fluid>
                {!isSuccess ? <Loader height="500px" /> : <InboundContext.Provider value={[inbound ?? data, setInbound]}>
                    <ShippingPlanView />
                </InboundContext.Provider>}
            </Container>
        </div>
    </>;
}

export default ShippingPlanDetailsPage;