import { useMutation } from "@tanstack/react-query";
import { getBinByLabel } from "api/bin";
import { getBinLocationByLabel } from "api/binLocation";
import type { BinContract } from "api/types/contracts/bin";
import type { BinLocationContract } from "api/types/contracts/locations";
import { inputKeyEventHandler } from "helpers/componentHelpers";
import { trimLabel } from "helpers/string";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import TextInput from "./TextInput";
import BusyOverlay from "Components/Common/BusyOverlay";
import classNames from "classnames";
import { locationOf } from "helpers/locationOf";
import { Button, FormFeedback } from "reactstrap";

type LocationInputProps = {
    warehouseId: string | undefined,
    onSelect: (location: BinContract | BinLocationContract | undefined) => void;
};

const LocationInput = (props: LocationInputProps) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState<string>();
    const [location, setLocation] = useState<BinContract | BinLocationContract>();
    const inputRef = useRef<HTMLInputElement>(null);

    const searchMutation = useMutation({
        mutationFn: async (label: string) => {
            const trimmedLabel = trimLabel(label);

            if (trimmedLabel.startsWith("BIN-")) {
                return await getBinByLabel({ label: trimmedLabel, warehouseId: props.warehouseId! });
            }
            else {
                return await getBinLocationByLabel({ label: trimmedLabel, warehouseId: props.warehouseId! });
            }
        },
        onError: () => {
            inputRef.current?.focus();
        },
        onSuccess: (location) => {
            if (location) {
                setLocation(location);
            }
            else {
                setLocation(undefined);
            }
            setSearch("");
        }
    })

    const doSearch = () => {
        if (search) {
            searchMutation.mutate(search);
        }
        else {
            setLocation(undefined);
        }
    };

    useEffect(() => {
        props.onSelect(location);

        if (!location) {
            inputRef.current?.focus();
        }
    }, [location]);
    
    const locationType = location ? "binId" in location ? t("Bin") : t("Bin Location") : "";

    return <>
        {!location ? <BusyOverlay busy={searchMutation.isPending} size="sm">
            <div className="form-icon">
                <TextInput disabled={!props.warehouseId} 
                    innerRef={inputRef}
                    className={classNames("form-control-icon", { "is-invalid": searchMutation.isError })}
                    value={search} onChange={setSearch} 
                    placeholder={t("Scan or enter bin / bin location...")}
                    onKeyDown={inputKeyEventHandler("Enter", e => doSearch())}
                    onFocus={e => e.target.select()} />
                <i className="ri-barcode-line fs-18"></i>
            </div>
        </BusyOverlay> 
        :
        <div className={classNames("hstack rounded border p-2 py-1 gap-2 border-dark")}>
            <i className="ri-price-tag-3-line text-secondary"></i>
            <span>
                {"binId" in location ? `# ${location.binCode}` : `# ${location.name}`}
            </span>
            <span className="text-muted">{locationType}</span>
            <span className="text-success">
                {t("Dest: {{destination}}", { destination: locationOf(location) || "-" })}
            </span>
            <Button size="sm" color="ghost-danger" className="btn-icon ms-auto" onClick={() => setLocation(undefined)}>
                <i className="ri-close-line fs-20"></i>
            </Button>
        </div>}
    </>
}

export default LocationInput;