import { useMetadata } from "Components/Hooks/MetadataHooks";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { useQuery } from "@tanstack/react-query";
import { getInbound } from "api/inbound";
import Loader from "Components/Common/Loader";
import { useEffect, useState } from "react";
import { ShippingPlanContext, type ShippingPlanReceivingContextType } from "./ShippingPlanReceivingContext";
import ShippingPlanProcess from "./_Process";

const ShippingPlanReceivingPage = () => {
    const { inboundId } = useParams<{ inboundId: string }>();
    const { t } = useTranslation();
    const { setPageTitle } = useMetadata();
    const [context, setContext] = useState<ShippingPlanReceivingContextType>();

    const { data } = useQuery({
        queryKey: ["inbound", inboundId],
        queryFn: async () => getInbound({ inboundId: inboundId! })
    });

    useEffect(() => {
        if (data) {
            setContext({
                ...context,
                inbound: data
            });
        }
    }, [data]);

    useEffect(() => {
        setPageTitle(t(`{{inboundCode}} - Shipping Plan Receiving`, { inboundCode: context?.inbound.inboundCode }));
    }, [context]);

    return <>
        <div className="page-content">
            <Container fluid>
                {!context ? <Loader height="500px" /> : <ShippingPlanContext.Provider value={[context, setContext]}>
                    <ShippingPlanProcess />
                </ShippingPlanContext.Provider>}
            </Container>
        </div>
    </>;
}

export default ShippingPlanReceivingPage;