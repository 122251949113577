import { useMutation } from "@tanstack/react-query";
import { downloadShippingBoxLabel } from "api/inbound";
import type { InboundContract, InboundForm } from "api/types/contracts/inbound";
import BusyOverlay from "Components/Common/BusyOverlay";
import AddressDisplay from "Components/Displays/AddressDisplay";
import ProductSupplierSelect from "Components/EntitySelects/ProductSupplierSelect";
import DateInput from "Components/Form/DateInput";
import TextInput from "Components/Form/TextInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { useFormik } from "formik";
import type { TypedShape } from "helpers/types";
import { multiDownload } from "helpers/urlHelper";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Label, Row } from "reactstrap";
import * as Yup from "yup";

type ShippingInfoStepValues = Pick<InboundForm, "suppliers" | "estimatedArrivalDate" | "notes">;

type ShippingInfoStepProps = {
    editable: boolean,
    collapsed: boolean,
    inbound: InboundContract,
    onSaved: (values: ShippingInfoStepValues) => Promise<void>,
    onEdit: () => void
}

const ShippingInfoStep = (props: ShippingInfoStepProps) => {
    const { t } = useTranslation();

    const validation = useFormik({
        initialValues: {} as ShippingInfoStepValues,
        validationSchema: Yup.object<ShippingInfoStepValues, TypedShape<ShippingInfoStepValues>>({
            estimatedArrivalDate: Yup.date().notRequired(),
            notes: Yup.string().notRequired(),
            suppliers: Yup.array().of(Yup.string().required()).notRequired()
        }),
        onSubmit: values => {
            saveMutation.mutate(values);
        }
    });

    const saveMutation = useMutation({
        mutationFn: props.onSaved
    });

    const downloadMutation = useMutation({
        mutationFn: downloadShippingBoxLabel,
        onSuccess: result => {
            multiDownload([result]);
        }
    });

    return <>
        <Card>
            <CardHeader className="py-1">
                <div className="hstack gap-3">
                {props.collapsed ? <i className="ri-checkbox-circle-fill text-success ri-3x"></i>
                    : <i className="ri-checkbox-circle-line text-light-emphasis ri-3x"></i>}
                    <div>
                        <h4 className="card-title">{t("Packing Details")}</h4>
                        <p className="card-subtitle">{t("paragraphs:ShippingPlanPackingDetailsHeader")}</p>
                    </div>
                    {props.collapsed ? <>
                        <div className="ms-auto text-muted">
                            {t("Submitted")}
                        </div>
                        <Button color="outline-info" disabled={!props.editable} className="btn-label" onClick={props.onEdit}>
                            <i className="label-icon ri-pencil-line"></i>
                            {t("Edit")}
                        </Button>
                    </> : <>
                        <BusyOverlay busy={saveMutation.isPending} size="sm" overlayClassName="ms-auto">
                            <ValidatorButton validation={validation} color="info" className="btn-label" onClick={() => {
                                validation.submitForm();
                            }}>
                                <i className="label-icon ri-save-3-line"></i>
                                {t("Save & Continue")}
                            </ValidatorButton>
                        </BusyOverlay>
                    </>}
                </div>
            </CardHeader>
            {!props.collapsed && <CardBody>
                <div className="px-5 py-4" style={{ minHeight: "400px" }}>
                    <h5 className="">{t("paragraphs:ShippingPlanShippingInfoTitle")}</h5>
                    <p>{t("paragraphs:ShippingPlanShippingInfoDescription")}</p>
                    <Row>
                        <Col xs={6}>
                            <div className="vstack justify-content-between gap-3 h-100">
                                <FormGroup noMargin>
                                    <Label>{t("Suppliers")}</Label>
                                    <ValidationWrapper validation={validation} field="suppliers">
                                        <ProductSupplierSelect isMulti userId={props.inbound?.owner.userId} />
                                    </ValidationWrapper>
                                </FormGroup>
                                <FormGroup noMargin>
                                    <Label>{t("Estimated Arrival Date")}</Label>
                                    <ValidationWrapper validation={validation} field="estimatedArrivalDate">
                                        <DateInput min={new Date()} />
                                    </ValidationWrapper>
                                </FormGroup>
                                <FormGroup noMargin>
                                    <Label>{t("Note to Warehouse")}</Label>
                                    <ValidationWrapper validation={validation} field="notes">
                                        <TextInput type="textarea" />
                                    </ValidationWrapper>
                                </FormGroup>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <div className="vstack gap-4 justify-content-between">
                                <div className="w-75 mx-auto">
                                    <h6 className="fw-bold mb-1">{t("Ship To")}</h6>
                                    <AddressDisplay address={props.inbound.warehouse.address} inboundCode={props.inbound.inboundCode} />
                                </div>
                                {props.inbound.packingMethod !== "unknown" && <div className="w-75 mx-auto">
                                    <h6 className="fw-bold mb-1">{t("Download Box Labels")}</h6>
                                    <div className="small text-muted my-2">
                                        {t("paragraphs:ShippingPlanShippingInfoDownloadBoxLabelsDescription")}
                                    </div>
                                    <BusyOverlay busy={downloadMutation.isPending} size="sm" inline>
                                        <Button color="secondary" className="btn-label" onClick={() => {
                                            downloadMutation.mutate({ inboundId: props.inbound.inboundId });
                                        }}>
                                            <i className="label-icon ri-download-2-line"></i>
                                            {t("Download")}
                                        </Button>
                                    </BusyOverlay>
                                </div>}
                            </div>
                        </Col>
                    </Row>
                </div>
            </CardBody>}
        </Card>
    </>;
}

export default ShippingInfoStep;