import BusyOverlay from "Components/Common/BusyOverlay";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import ProductBrandSelect from "Components/EntitySelects/ProductBrandSelect";
import ProductCategorySelect from "Components/EntitySelects/ProductCategorySelect";
import ProductColorSelect from "Components/EntitySelects/ProductColorSelect";
import ProductSizeSelect from "Components/EntitySelects/ProductSizeSelect";
import ProductSupplierSelect from "Components/EntitySelects/ProductSupplierSelect";
import AllocationMethodSelect from "Components/EnumSelects/AllocationMethodSelect";
import UnitOfMeasurementSelect from "Components/EnumSelects/UnitOfMeasurementSelect";
import Checkbox from "Components/Form/Checkbox";
import ValidatedInput from "Components/Form/Validated/ValidatedInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { UnitOfMeasurement } from "api/types/contracts/common";
import { AllocationMethod, ProductCategoryContract, ProductContract, ProductForm } from "api/types/contracts/products";
import classNames from "classnames";
import { useFormik } from "formik";
import { SelfPopulatedSelectInputRef, TypedShape } from "helpers/types";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, CardHeader, CardImg, Col, Container, Form, InputGroup, InputGroupText, Label, Modal, ModalBody, Nav, NavItem, NavLink, Row, TabContent, TabPane, Table } from "reactstrap";
import * as Yup from "yup";
import CategoryForm from "../../pages/Products/_CategoryForm";
import BrandForm from "../../pages/Products/_BrandForm";
import SupplierForm from "../../pages/Products/_SupplierForm";
import ColorForm from "../../pages/Products/_ColorForm";
import SizeForm from "../../pages/Products/_SizeForm";
import CountrySelect from "Components/EntitySelects/CountrySelect";
import NumberInput from "Components/Form/NumberInput";
import RequiredLabel from "Components/Common/RequiredLabel";
import CurrencyInput from "Components/Form/UnitInputs/CurrencyInput";
import WeightInput from "Components/Form/UnitInputs/WeightInput";
import { useTenant } from "Components/Hooks/TenantHooks";
import { stockOf } from "helpers/stockOf";
import _ from "lodash";
import TextInput from "Components/Form/TextInput";
import { inputKeyEventHandler } from "helpers/componentHelpers";
import { useProfile } from "Components/Hooks/ProfileHooks";
import { convertLength, convertWeight } from "helpers/unitConversions";
import { FileUploadRef } from "Components/Form/FileUpload";
import ValidatedFileUpload from "Components/Form/Validated/ValidatedFileUpload";
import { useAppSelector } from "Components/Hooks/StoreHooks";
import { useMutation } from "@tanstack/react-query";
import { postProduct, putProduct, searchProductByAsin } from "api/product";
import { postProductBrand } from "api/productBrand";
import { DangerAlert } from "Components/Alerts";
import { withCdn } from "helpers/urlHelper";

type ProductEditFormProps = {
    product?: ProductContract,
    onSuccess?: VoidFunction
}

const defaultForm: Partial<ProductForm> = {
    unitOfMeasurement: "item",
    darkStorage: false,
    frozenStorage: false,
    humidityControlledStorage: false,
    lockedStorage: false,
    needsBubbleWrap: false,
    needsPolyBagging: false,
    refrigeratedStorage: false,
    roomTemperatureStorage: false,
    trackExpireDate: false,
    isHazardous: false,
    allocationMethod: "firstInFirstOut",
};

const createProductForm = (product: ProductContract): ProductForm => {
    return {
        name: product.name,
        description: product.description,
        asin: product.asin,
        upc: product.upc || product.ean,
        ean: product.ean,
        sku: product.sku,
        fnsku: product.fnsku,
        sellPrice: product.sellPrice,
        buyPrice: product.buyPrice,
        width: product.width,
        height: product.height,
        length: product.length,
        weight: product.weight,
        categoryId: product.category?.categoryId,
        brandId: product.brand?.brandId,
        supplierId: product.supplier?.supplierId,
        sizeId: product.size?.sizeId,
        colorId: product.color?.colorId,
        originCountryCode: product.originCountry?.code,
        hsCode: product.hsCode,
        unitOfMeasurement: product.unitOfMeasurement,
        itemsPerUnit: product.itemsPerUnit,
        allocationMethod: product.allocationMethod,
        image: product.options.image,
        darkStorage: product.options.storage.darkStorage,
        refrigeratedStorage: product.options.storage.refrigeratedStorage,
        frozenStorage: product.options.storage.frozenStorage,
        roomTemperatureStorage: product.options.storage.roomTemperatureStorage,
        humidityControlledStorage: product.options.storage.humidityControlledStorage,
        lockedStorage: product.options.storage.lockedStorage,
        minStockCountForNotify: product.options.notification.minStockCount,
        needsBubbleWrap: product.options.packing.needsBubbleWrap,
        needsPolyBagging: product.options.packing.needsPolyBagging,
        trackExpireDate: product.options.tracking.trackExpireDate,
        holdThresholdForExpiry: product.options.tracking.holdThreshold,
        isHazardous: product.options.isHazardous
    };
};

const ProductEditForm = (props: ProductEditFormProps) => {
    const [activeTab, setActiveTab] = useState("1");
    const { t } = useTranslation();
    const { company } = useTenant();
    const { userProfile } = useProfile();
    const [fileUploadBusy, setFileUploadBusy] = useState(false);
    const fileUploadRef = useRef<FileUploadRef>(null);
    const product = props.product ? createProductForm(props.product) : defaultForm;

    const lengthUnit = props.product?.options.unitOfLength ?? userProfile?.user.warehouse?.settings.unitOfLength ?? "inch";
    const weightUnit = props.product?.options.unitOfWeight ?? userProfile?.user.warehouse?.settings.unitOfWeight ?? "lbs";
    const currency = props.product?.options.unitOfCurrency ?? userProfile?.user.warehouse?.settings.unitOfCurrency;

    const [selectedCategory, setSelectedCategory] = useState<ProductCategoryContract>();

    const [productImageEdit, setProductImageEdit] = useState(false);
    const [categoryModal, setCategoryModal] = useState(false);
    const [brandModal, setBrandModal] = useState(false);
    const [supplierModal, setSupplierModal] = useState(false);
    const [colorModal, setColorModal] = useState(false);
    const [sizeModal, setSizeModal] = useState(false);

    const categorySelectRef = useRef<SelfPopulatedSelectInputRef>(null);
    const brandSelectRef = useRef<SelfPopulatedSelectInputRef>(null);
    const colorSelectRef = useRef<SelfPopulatedSelectInputRef>(null);
    const sizeSelectRef = useRef<SelfPopulatedSelectInputRef>(null);
    const supplierSelectRef = useRef<SelfPopulatedSelectInputRef>(null);

    //setPageTitle(`${t(props.product ? "Update Product" : "Create Product")} - ${t("Inventory")}`);

    const toggleCategoryModal = (categoryId?: string) => {
        setCategoryModal(prev => {
            if (prev) {
                categorySelectRef.current?.reload().then(() => {
                    if (categoryId) {
                        validation.setFieldValue("categoryId", categoryId);
                    }
                });
            }
            return !prev
        });
    }

    const toggleBrandModal = (brandId?: string) => {
        setBrandModal(prev => {
            if (prev) {
                brandSelectRef.current?.reload().then(() => {
                    if (brandId) {
                        validation.setFieldValue("brandId", brandId);
                    }
                });
            }
            return !prev
        });
    }

    const toggleSupplierModal =  (supplierId?: string) => {
        setSupplierModal(prev => {
            if (prev) {
                supplierSelectRef.current?.reload().then(() => {
                    if (supplierId) {
                        validation.setFieldValue("supplierId", supplierId);
                    }
                });
            }
            return !prev
        });
    };

    const toggleColorModal = (colorId?: string) => {
        setColorModal(prev => {
            if (prev) {
                colorSelectRef.current?.reload().then(() => {
                    if (colorId) {
                        validation.setFieldValue("colorId", colorId);
                    }
                });
            }
            return !prev
        });
    }

    const toggleSizeModal = (sizeId?: string) => {
        setSizeModal(prev => {
            if (prev) {
                sizeSelectRef.current?.reload().then(() => {
                    if (sizeId) {
                        validation.setFieldValue("sizeId", sizeId);
                    }
                });
            }
            return !prev
        });
    }

    const tabChange = (tab: string) => {
        if (activeTab !== tab) 
            setActiveTab(tab);
    };

    const { brandList } = useAppSelector(
        (state) => ({
            brandList: state.ProductBrand.list
        })
    );

    const saveProductMutation = useMutation({
        mutationFn: async (product: ProductForm) => {
            if (props.product?.productId) {
                await putProduct({
                    productId: props.product.productId,
                    product: product
                });
            }
            else {
                await postProduct({
                    product: product
                });
            }
        },
        mutationKey: ["create-product"],
        onSuccess: () => {
            props.onSuccess?.();
        }
    });

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: product,
        validationSchema: Yup.object<ProductForm, TypedShape<ProductForm>>({
            name: Yup.string().required(t("Name is required")),
            description: Yup.string().notRequired(),
            asin: Yup.string().notRequired(),
            upc: Yup.string().notRequired(),
            ean: Yup.string().notRequired(),
            sku: Yup.string().required(t("SKU is required")),
            fnsku: Yup.string().notRequired(),
            buyPrice: Yup.number().required(t("Buy Price is required")),
            sellPrice: Yup.number().notRequired(),
            width: Yup.number().required(t("Width is required")),
            height: Yup.number().required(t("Height is required")),
            length: Yup.number().required(t("Length is required")),
            weight: Yup.number().required(t("Weight is required")),
            categoryId: Yup.string().notRequired(),
            brandId: Yup.string().notRequired(),
            supplierId: Yup.string().notRequired(),
            sizeId: Yup.string().notRequired(),
            colorId: Yup.string().notRequired(),
            originCountryCode: Yup.string().notRequired(),
            hsCode: Yup.string().notRequired(),
            unitOfMeasurement: Yup.string<UnitOfMeasurement>().required(t("Unit of Measurement is required")),
            itemsPerUnit: Yup.number().notRequired(),
            allocationMethod: Yup.string<AllocationMethod>().required(t("Allocation Method is required")),
            image: Yup.string().notRequired(),
            darkStorage: Yup.boolean().required(),
            refrigeratedStorage: Yup.boolean().required(),
            frozenStorage: Yup.boolean().required(),
            roomTemperatureStorage: Yup.boolean().required(),
            humidityControlledStorage: Yup.boolean().required(),
            lockedStorage: Yup.boolean().required(),
            minStockCountForNotify: Yup.number().notRequired(),
            needsBubbleWrap: Yup.boolean().required(),
            needsPolyBagging: Yup.boolean().required(),
            trackExpireDate: Yup.boolean().required(),
            holdThresholdForExpiry: Yup.number().notRequired(),
            isHazardous: Yup.boolean().required()
        }),
        onSubmit: values => {
            saveProductMutation.mutate(values as ProductForm);
        },
    });

    const createProductBrandMutation = useMutation({
        mutationFn: postProductBrand,
        mutationKey: ["create-product-brand"],
        onSuccess: async (newBrand) => {
            if (newBrand) {
                await brandSelectRef.current?.reload();
                validation.setFieldValue("brandId", newBrand.brandId);
            }
        }
    });

    const retrieveProductMutation = useMutation({
        mutationFn: searchProductByAsin,
        mutationKey: ["search-product"],
        onSuccess: productInfo => {
            if (productInfo) {
                validation.setFieldValue("name", productInfo.name);
                validation.setFieldValue("description", productInfo.description);
                validation.setFieldValue("upc", productInfo.upc);
                validation.setFieldValue("ean", productInfo.ean);
                validation.setFieldValue("buyPrice", productInfo.buyPrice);
                validation.setFieldValue("width", convertLength(productInfo.width ?? 0, productInfo.unitOfLength, lengthUnit));
                validation.setFieldValue("height", convertLength(productInfo.height ?? 0, productInfo.unitOfLength, lengthUnit));
                validation.setFieldValue("length", convertLength(productInfo.length ?? 0, productInfo.unitOfLength, lengthUnit));
                validation.setFieldValue("weight", convertWeight(productInfo.weight ?? 0, productInfo.unitOfWeight, weightUnit));

                if (productInfo.imageUrl) {
                    fileUploadRef.current?.uploadFileFromUrl(productInfo.imageUrl);
                }

                if (productInfo.brand) {
                    const foundBrand = brandList.items?.find(b => b.name === productInfo.brand);

                    if (foundBrand) {
                        validation.setFieldValue("brandId", foundBrand.brandId);
                    }
                    else {
                        createProductBrandMutation.mutate({
                            productBrand: {
                                name: productInfo.brand
                            }
                        });
                    }
                }
            }
        }
    });

    const retrieveProductByAsin = (asin: string) => {
        if (asin) {
            retrieveProductMutation.mutate({ asin });
        }
    };

    return <>
        <Form className="needs-validation form-steps" action="#" onSubmit={validation.handleSubmit}>
            <Card className='mb-0'>
                <CardHeader>
                    <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                        <NavItem>
                            <NavLink className={classNames({ active: activeTab === "1" })} onClick={() => tabChange("1")}>
                                {t("General Info")}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to="#" className={classNames({ active: activeTab === "2" })} onClick={() => tabChange("2")} type="button">
                                {t("Inventory Settings")}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to="#" className={classNames({ active: activeTab === "3" })} onClick={() => tabChange("3")} type="button">
                                {t("Stocks")}
                            </NavLink>
                        </NavItem>
                    </Nav>
                </CardHeader>
                <CardBody className="p-4 pt-0">
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <Container>
                                <Row>
                                    <Col sm={4} className="g-3">
                                        <Label>{t("Product Image")}</Label>
                                        {!productImageEdit && product.image ? <Card className="product-image-upload-display">
                                            <CardImg src={withCdn(product.image)}></CardImg>
                                            <div>
                                                <a href="javascript:;" className="" onClick={() => setProductImageEdit(true)}>
                                                    <i className="text-light ri-pencil-line fs-48"></i>
                                                </a>
                                            </div>
                                        </Card> :
                                            <ValidatedFileUpload validation={validation} field="image" ref={fileUploadRef} 
                                                onInput={() => setFileUploadBusy(true)} onUploaded={f => {
                                                    if (f.length > 0) {
                                                        setFileUploadBusy(false);
                                                    }
                                                }} />
                                        }
                                    </Col>
                                    <Col sm={8}>
                                        <Row>
                                            <Col md={4} className="g-3">
                                                <Label>{t("Asin")}</Label>
                                                <BusyOverlay busy={retrieveProductMutation.isPending} size="sm">
                                                    <ValidationWrapper validation={validation} field="asin">
                                                        <TextInput placeholder={t("Press Enter to search")} maxLength={10}
                                                            onBlur={e => {
                                                                if (e.value === e.oldValue) {
                                                                    return;
                                                                }

                                                                retrieveProductByAsin(e.value);
                                                            }}
                                                            onKeyDown={inputKeyEventHandler("Enter", e => {
                                                                retrieveProductByAsin(e.value);
                                                            })} />
                                                    </ValidationWrapper>
                                                </BusyOverlay>
                                            </Col>
                                            <Col md={4} className="g-3">
                                                <Label>{t("Barcode")}</Label>
                                                <ValidationWrapper validation={validation} field="upc">
                                                    <TextInput maxLength={12} />
                                                </ValidationWrapper>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="g-3">
                                                <RequiredLabel>{t("Name")}</RequiredLabel>
                                                <ValidatedInput validation={validation} field="name" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="g-3">
                                                <RequiredLabel>{t("SKU")}</RequiredLabel>
                                                <ValidatedInput validation={validation} field="sku" />
                                            </Col>
                                            <Col className="g-3">
                                                <RequiredLabel>{t("Buy Price")}</RequiredLabel>
                                                <ValidationWrapper validation={validation} field="buyPrice">
                                                    <CurrencyInput currency={currency} />
                                                </ValidationWrapper>
                                            </Col>
                                            <Col className="g-3">
                                                <Label>{t("Sell Price")}</Label>
                                                <ValidationWrapper validation={validation} field="sellPrice">
                                                    <CurrencyInput currency={currency} />
                                                </ValidationWrapper>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4} className="g-3">
                                                <Label>{t("FNSKU")}</Label>
                                                <ValidationWrapper validation={validation} field="fnsku">
                                                    <TextInput maxLength={20} />
                                                </ValidationWrapper>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6}>
                                        <Row>
                                            <Col className="g-3">
                                                <Label>{t("Category")}</Label>
                                                <div className="hstack gap-2">
                                                    <ValidationWrapper validation={validation} field="categoryId">
                                                        <ProductCategorySelect ref={categorySelectRef} onSelect={val => {
                                                            setSelectedCategory(val);
                                                        }} className="flex-grow-1" userId={props.product?.owner?.userId} />
                                                    </ValidationWrapper>
                                                    <Button color="ghost-primary" size="sm" onClick={() => toggleCategoryModal()}><i className="ri-add-line fs-18" /></Button>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="g-3">
                                                <Label>{t("Supplier")}</Label>
                                                <div className="hstack gap-2">
                                                    <ValidationWrapper validation={validation} field="supplierId">
                                                        <ProductSupplierSelect ref={supplierSelectRef} className="flex-grow-1" userId={props.product?.owner?.userId} />
                                                    </ValidationWrapper>
                                                    <Button color="ghost-primary" size="sm" onClick={() => toggleSupplierModal()}><i className="ri-add-line fs-18" /></Button>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="g-3">
                                                <Label>{t("Brand")}</Label>
                                                <div className="hstack gap-2">
                                                    <ValidationWrapper validation={validation} field="brandId">
                                                        <ProductBrandSelect ref={brandSelectRef} className="flex-grow-1" userId={props.product?.owner?.userId} />
                                                    </ValidationWrapper>
                                                    <Button color="ghost-primary" size="sm" onClick={() => toggleBrandModal()}><i className="ri-add-line fs-18" /></Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={6}>
                                        <Row>
                                            <Col className="g-3">
                                                <Label>{t("Description")}</Label>
                                                <ValidatedInput validation={validation} field="description" type="textarea" style={{ height: "7.5rem" }} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={6} className="g-3">
                                                <Label>{t("Country of Origin")}</Label>
                                                <ValidationWrapper validation={validation} field="originCountryCode">
                                                    <CountrySelect />
                                                </ValidationWrapper>
                                            </Col>
                                            <Col sm={6} className="g-3">
                                                <Label>{t("HS Code")}</Label>
                                                <ValidatedInput validation={validation} field="hsCode" />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={4} className="g-3">
                                        <RequiredLabel>{t("Dimensions")}</RequiredLabel>
                                        <div className="hstack gap-2">
                                            <InputGroup>
                                                <ValidationWrapper validation={validation} field="width">
                                                    <NumberInput placeholder="Width" />
                                                </ValidationWrapper>
                                                <ValidationWrapper validation={validation} field="height">
                                                    <NumberInput placeholder="Height" />
                                                </ValidationWrapper>
                                                <ValidationWrapper validation={validation} field="length">
                                                    <NumberInput placeholder="Length" />
                                                </ValidationWrapper>
                                                <InputGroupText>{lengthUnit}</InputGroupText>
                                            </InputGroup>
                                        </div>
                                    </Col>
                                    <Col sm={2} className="g-3">
                                        <RequiredLabel>{t("Weight")}</RequiredLabel>
                                        <div className="hstack gap-2">
                                            <ValidationWrapper validation={validation} field="weight">
                                                <WeightInput unit={weightUnit} />
                                            </ValidationWrapper>
                                        </div>
                                    </Col>
                                    <Col sm={4} className="g-3">
                                        <Label>{t("Unit of Measurement")}</Label>
                                        <ValidationWrapper validation={validation} field="unitOfMeasurement">
                                            <UnitOfMeasurementSelect />
                                        </ValidationWrapper>
                                    </Col>
                                    {validation.values.unitOfMeasurement !== "item" && <>
                                        <Col sm={2} className="g-3">
                                            <Label>{t("Units per {{measure}}", { measure: t(validation.values.unitOfMeasurement!.toString()) })}</Label>
                                            <ValidationWrapper validation={validation} field="itemsPerUnit">
                                                <NumberInput />
                                            </ValidationWrapper>
                                        </Col>
                                    </>}
                                </Row>
                                <Row>
                                    <Col sm={3} className="g-3">
                                        <Label>{t("Size")}</Label>
                                        <div className="hstack gap-2">
                                            <ValidationWrapper validation={validation} field="sizeId">
                                                <ProductSizeSelect ref={sizeSelectRef} categoryId={selectedCategory?.categoryId} className="flex-grow-1" userId={props.product?.owner?.userId} />
                                            </ValidationWrapper>
                                            <Button color="ghost-primary" size="sm" onClick={() => toggleSizeModal()}><i className="ri-add-line fs-18" /></Button>
                                        </div>
                                    </Col>
                                    <Col sm={3} className="g-3">
                                        <Label>{t("Color")}</Label>
                                        <div className="hstack gap-2">
                                            <ValidationWrapper validation={validation} field="colorId">
                                                <ProductColorSelect ref={colorSelectRef} className="flex-grow-1" userId={props.product?.owner?.userId} />
                                            </ValidationWrapper>
                                            <Button color="ghost-primary" size="sm" onClick={() => toggleColorModal()}><i className="ri-add-line fs-18" /></Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </TabPane>
                        <TabPane tabId="2">
                            <Container>
                                <Row>
                                    <Col md={6}>
                                        <Row>
                                            <Col xs={12} className="g-5">
                                                <label>{t("Allocation Method")}</label>
                                                <div className="vstack gap-2">
                                                    <ValidationWrapper validation={validation} field="allocationMethod">
                                                        <AllocationMethodSelect />
                                                    </ValidationWrapper>
                                                </div>
                                            </Col>
                                            <Col xs={12} className="g-5">
                                                <h5 className="border-bottom pb-2">{t("Storage Options")}</h5>
                                                <div className="vstack gap-2 ps-2">
                                                    <ValidationWrapper validation={validation} field="roomTemperatureStorage">
                                                        <Checkbox>{t("Room Temperature Storage")}</Checkbox>
                                                    </ValidationWrapper>
                                                    <ValidationWrapper validation={validation} field="refrigeratedStorage">
                                                        <Checkbox>{t("Refrigerated Storage")}</Checkbox>
                                                    </ValidationWrapper>
                                                    <ValidationWrapper validation={validation} field="frozenStorage">
                                                        <Checkbox>{t("Frozen Storage")}</Checkbox>
                                                    </ValidationWrapper>
                                                    <ValidationWrapper validation={validation} field="humidityControlledStorage">
                                                        <Checkbox>{t("Humidity-Controlled Storage")}</Checkbox>
                                                    </ValidationWrapper>
                                                    <ValidationWrapper validation={validation} field="lockedStorage">
                                                        <Checkbox>{t("Secure/Locked Storage")}</Checkbox>
                                                    </ValidationWrapper>
                                                    <ValidationWrapper validation={validation} field="darkStorage">
                                                        <Checkbox>{t("Dark Storage")}</Checkbox>
                                                    </ValidationWrapper>
                                                </div>
                                            </Col>
                                            <Col xs={12} className="g-5">
                                                <h5 className="border-bottom pb-2">{t("Tracking Options")}</h5>
                                                <Row className="ps-2">
                                                    <Col sm={6} className="pb-2">
                                                        <ValidationWrapper validation={validation} field="trackExpireDate">
                                                            <Checkbox>{t("Expire Date Tracking")}</Checkbox>
                                                        </ValidationWrapper>
                                                    </Col>
                                                    {validation.values.trackExpireDate && <Col sm={6} className="">
                                                        <Label>{t("Hold Threshold Days")}</Label>
                                                        <ValidatedInput validation={validation} field="holdThresholdForExpiry" />
                                                    </Col>}
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={6}>
                                        <Row>
                                            <Col xs={12} className="g-5">
                                                <h5 className="border-bottom pb-2">{t("Notification Options")}</h5>
                                                <div className="ps-2">
                                                    <Label>{t("Min Stock Count for Notification")}</Label>
                                                    <ValidationWrapper validation={validation} field="minStockCountForNotify">
                                                        <NumberInput />
                                                    </ValidationWrapper>
                                                </div>
                                            </Col>
                                            <Col xs={12} className="g-5">
                                                <h5 className="border-bottom pb-2">{t("Packing Options")}</h5>
                                                <div className="vstack gap-2 ps-2">
                                                    <ValidationWrapper validation={validation} field="needsPolyBagging">
                                                        <Checkbox>{t("Poly bagging needed")}</Checkbox>
                                                    </ValidationWrapper>
                                                    <ValidationWrapper validation={validation} field="needsBubbleWrap">
                                                        <Checkbox>{t("Bubble wrap needed")}</Checkbox>
                                                    </ValidationWrapper>
                                                </div>
                                            </Col>
                                            <Col xs={12} className="g-5">
                                                <h5 className="border-bottom pb-2">{t("Other Options")}</h5>
                                                <div className="vstack gap-2 ps-2">
                                                    <ValidationWrapper validation={validation} field="isHazardous">
                                                        <Checkbox>{t("Hazardous product")}</Checkbox>
                                                    </ValidationWrapper>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        </TabPane>
                        <TabPane tabId="3">
                            <Container>
                                <Table size="sm" className="my-2">
                                    <thead>
                                        <tr>
                                            <th>{t("Warehouse")}</th>
                                            <th>{t("Available")}</th>
                                            <th>{t("Inbound")}</th>
                                            <th>{t("On Hold")}</th>
                                            <th>{t("Reserved")}</th>
                                            <th>{t("Unfulfillable")}</th>
                                            <th>{t("Researching")}</th>
                                            <th>{t("Total")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {company?.warehouses.map(warehouse => {
                                            const stock = stockOf(props.product, warehouse.warehouseId);
                                            const total = stock.available + stock.inbound + stock.onHold + stock.reserved + stock.unfulfillable + stock.researching;

                                            return <tr key={warehouse.warehouseId}>
                                                <td>{warehouse.name}</td>
                                                <td>{stock.available}</td>
                                                <td>{stock.inbound}</td>
                                                <td>{stock.onHold}</td>
                                                <td>{stock.reserved}</td>
                                                <td>{stock.unfulfillable}</td>
                                                <td>{stock.researching}</td>
                                                <th>{total}</th>
                                            </tr>;
                                        })}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>{t("Total")}</th>
                                            <th>{_.sumBy(props.product?.stocks, "available")}</th>
                                            <th>{_.sumBy(props.product?.stocks, "inbound")}</th>
                                            <th>{_.sumBy(props.product?.stocks, "onHold")}</th>
                                            <th>{_.sumBy(props.product?.stocks, "reserved")}</th>
                                            <th>{_.sumBy(props.product?.stocks, "unfulfillable")}</th>
                                            <th>{_.sumBy(props.product?.stocks, "researching")}</th>
                                            <th>{_.sumBy(props.product?.stocks, s => s.available + s.inbound + s.onHold + s.reserved + s.unfulfillable + s.researching)}</th>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </Container>
                        </TabPane>
                    </TabContent>
                    <div className="d-flex justify-content-end gap-2">
                        {saveProductMutation.isError && <DangerAlert className="mb-0 py-2 fs-13">
                            {saveProductMutation.error.message}
                        </DangerAlert>}
                        <BusyOverlay busy={saveProductMutation.isPending} size="sm">
                            <ValidatorButton disabled={fileUploadBusy} validation={validation} color="primary" type="submit">
                                {t("Save")}
                            </ValidatorButton>
                        </BusyOverlay>
                    </div>
                </CardBody>
            </Card>
        </Form>
        <Modal backdrop="static" isOpen={categoryModal} toggle={() => toggleCategoryModal()} unmountOnClose>
            <ModalBody>
                <ModalCloseButton onClick={() => toggleCategoryModal()} />
                <CategoryForm onSuccess={id => toggleCategoryModal(id)} />
            </ModalBody>
        </Modal>
        <Modal backdrop="static" isOpen={brandModal} toggle={() => toggleBrandModal()} unmountOnClose>
            <ModalBody>
                <ModalCloseButton onClick={() => toggleBrandModal()} />
                <BrandForm onSuccess={id => toggleBrandModal(id)} />
            </ModalBody>
        </Modal>
        <Modal backdrop="static" isOpen={supplierModal} toggle={() => toggleSupplierModal()} unmountOnClose>
            <ModalBody>
                <ModalCloseButton onClick={() => toggleSupplierModal()} />
                <SupplierForm onSuccess={id => toggleSupplierModal(id)} />
            </ModalBody>
        </Modal>
        <Modal backdrop="static" isOpen={colorModal} toggle={() => toggleColorModal()} unmountOnClose>
            <ModalBody>
                <ModalCloseButton onClick={() => toggleColorModal()} />
                <ColorForm onSuccess={id => toggleColorModal(id)} />
            </ModalBody>
        </Modal>
        <Modal backdrop="static" isOpen={sizeModal} toggle={() => toggleSizeModal()} unmountOnClose>
            <ModalBody>
                <ModalCloseButton onClick={() => toggleSizeModal()} />
                <SizeForm onSuccess={id => toggleSizeModal(id)} />
            </ModalBody>
        </Modal>
    </>;
};

export default ProductEditForm;