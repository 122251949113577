import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { ShippingPlanContext } from "./ShippingPlanReceivingContext";
import { useTranslation } from "react-i18next";
import TitleBreadcrumb from "Components/Common/TitleBreadcrumb";
import { Button, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, InputGroup, InputGroupText, Label, Modal, ModalBody, Row, Table, UncontrolledButtonDropdown } from "reactstrap";
import InboundStatusBadge from "Components/Displays/InboundStatusBadge";
import { useProfile } from "Components/Hooks/ProfileHooks";
import { Link } from "react-router-dom";
import ConsolidatedStatus from "./_ConsolidatedStatus";
import { useFormik } from "formik";
import { putSaveAcceptedProducts } from "api/receiving";
import { useMutation } from "@tanstack/react-query";
import * as Yup from "yup";
import type { SaveAcceptedProductsCommand } from "api/types/commands";
import type { TypedShape } from "helpers/types";
import BinPicker, { type BinPickerRef } from "Components/EntitySelects/BinPicker";
import Checkbox from "Components/Form/Checkbox";
import ProductIdentifiersDisplay, { ProductIdentifiersHeader } from "Components/Displays/ProductIdentifiersDisplay";
import ProductImageDisplay from "Components/Displays/ProductImageDisplay";
import TruncatableText from "Components/Common/TruncatableText";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import StepInput from "Components/Form/StepInput";
import classNames from "classnames";
import ReceivingActivityDisplay from "Components/Displays/ReceivingActivityDisplay";
import BusyOverlay from "Components/Common/BusyOverlay";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { useToggle } from "@uidotdev/usehooks";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import EditItemForm from "./_EditItemForm";
import RejectItemForm from "./_RejectItemForm";
import PrintBarcodes from "./_PrintBarcodes";

const allowedKeys = [
    "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", 
    "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z",
    "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "-", ".", ":", ",", "_", "#"];

const ShippingPlanProcess = () => {
    const [{ inbound }, setContext] = useContext(ShippingPlanContext);
    const { t } = useTranslation();
    const { userProfile } = useProfile();
    const [keyReadDisabled, setKeyReadDisabled] = useState(false);
    const [rejectedModal, toggleRejectedModal] = useToggle();
    const [editModal, toggleEditModal] = useToggle();
    const [printBarcodesModal, togglePrintBarcodesModal] = useToggle();
    const [hightlightedIndex, setHightlightedIndex] = useState<number>();
    const [selectedItems, setSelectedItems] = useState<number[]>([]);
    const [processedItemIndex, setProcessedItemIndex] = useState<number>();
    const binPickerRef = useRef<BinPickerRef>(null);
    const itemBinPickerRefs = useRef<(BinPickerRef | null)[]>([]);

    const processedItemUnusables = useMemo(() => {
        if (processedItemIndex !== undefined && inbound.receivingEntry) {
            const item = inbound.items[processedItemIndex];
            const rejectedProducts = inbound.receivingEntry?.productLabels
                .filter(pl => pl.productLabel.product.productId === item.product.productId && !!pl.productLabel.unusableProductReason)
                .map(pl => pl.productLabel);
            return rejectedProducts;
        }
        else {
            return [];
        }
    }, [processedItemIndex, inbound.items, inbound.receivingEntry]);

    useEffect(() => {
        if (editModal) {
            setKeyReadDisabled(true);
        }
        else {
            setKeyReadDisabled(false);
            setProcessedItemIndex(undefined);
        }
    }, [editModal]);

    useEffect(() => {
        if (rejectedModal) {
            setKeyReadDisabled(true);
        }
        else {
            setKeyReadDisabled(false);
            setProcessedItemIndex(undefined);
        }
    }, [rejectedModal]);

    useEffect(() => {
        if (printBarcodesModal) {
            setKeyReadDisabled(true);
        }
        else {
            setKeyReadDisabled(false);
            setProcessedItemIndex(undefined);
        }
    }, [printBarcodesModal]);

    const acceptProductsMutation = useMutation({
        mutationFn: putSaveAcceptedProducts,
        onSuccess: result => {
            setContext({
                inbound: result
            });

            validation.resetForm();
        }
    })

    const validation = useFormik({
        initialValues: {
            inboundId: inbound.inboundId,
            items: inbound.items.map(i => ({
                productId: i.product.productId,
                barcode: i.product.upc || i.product.ean || "",
                width: i.product.width,
                height: i.product.height,
                length: i.product.length,
                weight: i.product.weight,
                quantity: 0,
                binTargetId: ""
            }))
        },
        initialErrors: {
            items: t("You need to receive at least one item")
        },
        validationSchema: Yup.object<SaveAcceptedProductsCommand, TypedShape<SaveAcceptedProductsCommand>>({
            inboundId: Yup.string().required(),
            items: Yup.array().of(Yup.object({
                productId: Yup.string().required(),
                quantity: Yup.number().required(),
                binTargetId: Yup.string().when("quantity", 
                    ([quantity], schema) => quantity > 0 ? schema.required(t("You need to select a location")) : schema.notRequired())
            })).required().test("atLeastOne", t("You need to receive at least one item"), items => items.some(i => i.quantity > 0))
        }),
        onSubmit: values => { 
            acceptProductsMutation.mutate({
                inboundId: values.inboundId,
                items: values.items.filter(i => i.quantity > 0)
            });
        }
    });

    const handleBarcodeInput = useCallback((barcode: string) => {
        const index = validation.values.items.findIndex(i => i.barcode?.replace(/^0+/, '') === barcode);
        console.log("handleBarcodeInput", barcode, index);
        
        if (index < 0) {
            return;
        }

        validation.setFieldValue(`items.${index}.quantity`, validation.values.items[index].quantity + 1);

        setHightlightedIndex(index);
        document.getElementById(`itemrow_${index}`)?.scrollIntoView({ behavior: "smooth", block: "center" });
        setTimeout(() => setHightlightedIndex(undefined), 5000);
    }, [validation]);

    const handleBinInput = useCallback((binCode: string) => {
        for (let i = 0; i < validation.values.items.length; i++) {
            if (validation.values.items[i].quantity > 0) {
                itemBinPickerRefs.current[i]?.setBin(binCode);
            }
        }

        setTimeout(() => validation.submitForm(), 1000);
    }, [validation]);

    useEffect(() => {
        let readResetTimeout: number;
        let input = "";
        const keydownHandler = (e: KeyboardEvent) => {
            if (keyReadDisabled) {
                return;
            }

            if (e.key === "Enter") {
                clearTimeout(readResetTimeout);

                if (input.startsWith("BIN-")) {
                    handleBinInput(input);
                }
                else if (input.length > 0) {
                    handleBarcodeInput(input);
                }

                input = "";
                e.preventDefault();
                e.stopPropagation();
            }
            else if (allowedKeys.includes(e.key)) {
                input += e.key;
                
                clearTimeout(readResetTimeout);
                readResetTimeout = window.setTimeout(() => {
                    input = "";
                }, 150);
            }
        };

        document.addEventListener("keydown", keydownHandler);

        return () => {
            document.removeEventListener("keydown", keydownHandler);
        };
    }, [handleBarcodeInput, handleBinInput, keyReadDisabled]);

    return <>
        <TitleBreadcrumb title={t("Shipping Plans")} active={t("Receiving")} parents={[
            t("Receiving"), {
                title: t("Shipping Plan"),
                to: "/shipping-plans"
            }, {
                title: `# ${inbound.inboundCode}`,
                to: `/shipping-plan/${inbound.inboundId}`
            }]} />
        <div className="vstack gap-3">
            <Card body className="hstack gap-2 mb-0">
                <span>{t("Shipping Plan")} - # {inbound.inboundCode}</span>
                <InboundStatusBadge value={inbound.inboundStatus} />
                <Link to={`/shipping-plan/${inbound.inboundId}`} className="btn btn-secondary ms-auto btn-label">
                    <i className="ri-arrow-go-back-fill label-icon" />
                    {t("Back to Shipping Plan")}
                </Link>
                <BusyOverlay busy={acceptProductsMutation.isPending} size="sm" inline>
                    <ValidatorButton validation={validation} color="success" className="btn-label" onClick={() => validation.submitForm()}>
                        <i className="ri-save-line label-icon" />
                        {t("Save")}
                    </ValidatorButton>
                </BusyOverlay>
            </Card>
            <div className="hstack gap-2">
                <div className="w-25">
                    <ConsolidatedStatus />
                </div>
            </div>
            <div>
                <Card>
                    <CardBody>
                        <div className="table-responsive table-card">
                            <Table className="mb-0 align-middle" borderless>
                                {selectedItems.length > 0 ? <thead>
                                    <tr>
                                        <th>
                                            <Checkbox inline 
                                                value={selectedItems.length === validation.values.items.length}
                                                indeterminate={selectedItems.length > 0 && selectedItems.length < validation.values.items.length} 
                                                onChange={val => {
                                                    if (val) {
                                                        setSelectedItems(validation.values.items.map((_, i) => i));
                                                    }
                                                    else {
                                                        setSelectedItems([]);
                                                    }
                                                }}
                                                />
                                        </th>
                                        <th colSpan={8} className="py-2">
                                            <div className="hstack gap-2">
                                                <Button color="soft-info" size="sm" className="btn-label" onClick={() => togglePrintBarcodesModal()}>
                                                    <i className="ri-printer-line label-icon"></i>
                                                    {t("Print Barcodes")}
                                                </Button>
                                                <Button color="soft-info" size="sm" className="btn-label" onClick={() => binPickerRef.current?.openPicker()}>
                                                    <i className="ri-dashboard-line label-icon"></i>
                                                    {t("Change Location")}
                                                </Button>
                                            </div>
                                        </th>
                                    </tr>
                                </thead> : <thead className="table-tenant-primary table-nowrap">
                                    <tr>
                                        <th>
                                            <Checkbox inline 
                                                value={selectedItems.length === validation.values.items.length}
                                                indeterminate={selectedItems.length > 0 && selectedItems.length < validation.values.items.length} 
                                                onChange={val => {
                                                    if (val) {
                                                        setSelectedItems(validation.values.items.map((_, i) => i));
                                                    }
                                                    else {
                                                        setSelectedItems([]);
                                                    }
                                                }}
                                                />
                                        </th>
                                        <th>{t("Product")}</th>
                                        <th><ProductIdentifiersHeader /></th>
                                        <th>{t("To Location")}</th>
                                        <th>{t("Expected")}</th>
                                        <th>{t("Rejected")}</th>
                                        <th style={{ width: "6rem" }}>{t("To Accept")}</th>
                                        <th>{t("Activity")}</th>
                                        <th></th>
                                    </tr>
                                </thead>}
                                <tbody>
                                    {inbound.items.map((item, i) => <tr key={i} id={`itemrow_${i}`} className={classNames({
                                        "bg-success-subtle": hightlightedIndex === i
                                    })}>
                                        <td>
                                            <Checkbox inline value={selectedItems.includes(i)} onChange={val => {
                                                if (val) {
                                                    setSelectedItems([...selectedItems, i]);
                                                }
                                                else {
                                                    setSelectedItems(selectedItems.filter(si => si !== i));
                                                }
                                            }} />
                                        </td>
                                        <td>
                                            <div className="hstack gap-2">
                                                <ProductImageDisplay product={item.product} className="flex-shrink-0" />
                                                <TruncatableText maxLines={2}>{item.product.name}</TruncatableText>
                                            </div>
                                        </td>
                                        <td><ProductIdentifiersDisplay display={["barcode", "sku"]} sku={item.product.sku} barcode={validation.values.items[i].barcode} /></td>
                                        <td className="text-nowrap">
                                            <ValidationWrapper validation={validation} field={`items.${i}.binTargetId`}>
                                                <BinPicker ref={r => itemBinPickerRefs.current[i] = r} warehouseId={inbound.warehouse.warehouseId} productId={item.product.productId} />
                                            </ValidationWrapper>
                                        </td>
                                        <td>{item.quantity}</td>
                                        <td>
                                            <Button color="link" className={classNames({
                                                    "link-danger fw-semibold": item.rejected > 0,
                                                    "link-secondary": item.rejected === 0
                                                })} 
                                                onClick={() => {
                                                    setProcessedItemIndex(i);
                                                    toggleRejectedModal();
                                                }}>
                                                {item.rejected}
                                            </Button>
                                        </td>
                                        <td>
                                            <ValidationWrapper validation={validation} field={`items.${i}.quantity`}>
                                                <StepInput min={0} size="sm" wrapperClassName={classNames({
                                                    "border border-3 border-success": validation.values.items[i].quantity > 0
                                                })} />
                                            </ValidationWrapper>
                                        </td>
                                        <td>
                                            <ReceivingActivityDisplay value={{
                                                rejected: item.rejected,
                                                accepted: item.accepted,
                                                count: item.quantity
                                            }} />
                                        </td>
                                        <td>
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle color="ghost-primary" size="sm" className="btn-icon rounded-circle">
                                                    <i className="ri-more-2-fill fs-16"></i>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem onClick={() => {
                                                        setProcessedItemIndex(i);
                                                        toggleEditModal();
                                                    }}>
                                                        {t("Edit Item")}
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => {
                                                        setProcessedItemIndex(i);
                                                        togglePrintBarcodesModal();
                                                    }}>
                                                        {t("Print Barcode")}
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => {
                                                        itemBinPickerRefs.current[i]?.openPicker();
                                                    }}>
                                                        {t("Change Location")}
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                        </td>
                                    </tr>)}
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </div>
        <Modal backdrop="static" isOpen={rejectedModal} toggle={() => toggleRejectedModal()} size="lg" unmountOnClose>
            <ModalCloseButton onClick={() => toggleRejectedModal()} />
            <ModalBody className="px-0">
                {processedItemIndex !== undefined && <RejectItemForm 
                    item={inbound.items[processedItemIndex]} 
                    inboundId={inbound.inboundId} 
                    unusables={processedItemUnusables} 
                    onSave={newInbound => {
                        setContext({
                            inbound: newInbound
                        });
                        toggleRejectedModal();
                    }} />}
            </ModalBody>
        </Modal>
        <Modal backdrop="static" isOpen={editModal} toggle={() => toggleEditModal()} unmountOnClose>
            <ModalCloseButton onClick={() => toggleEditModal()} />
            <ModalBody className="px-0">
                {processedItemIndex !== undefined && <EditItemForm 
                    product={inbound.items[processedItemIndex].product} 
                    barcode={validation.values.items[processedItemIndex].barcode} 
                    onSave={values => {
                        validation.setFieldValue(`items.${processedItemIndex}.barcode`, values.barcode);
                        validation.setFieldValue(`items.${processedItemIndex}.width`, values.width);
                        validation.setFieldValue(`items.${processedItemIndex}.height`, values.height);
                        validation.setFieldValue(`items.${processedItemIndex}.length`, values.length);
                        validation.setFieldValue(`items.${processedItemIndex}.weight`, values.weight);
                        toggleEditModal();
                    }} />}
            </ModalBody>
        </Modal>
        <Modal backdrop="static" isOpen={printBarcodesModal} toggle={() => togglePrintBarcodesModal()} unmountOnClose>
            <ModalCloseButton onClick={() => togglePrintBarcodesModal()} />
            <ModalBody>
                <PrintBarcodes barcodes={inbound.items.filter((item, i) => {
                    return processedItemIndex !== undefined ? processedItemIndex === i : selectedItems.includes(i);
                }).map((item, i) => ({
                    barcode: validation.values.items[i].barcode,
                    expectedCount: item.quantity,
                    receivedCount: item.accepted + item.rejected
                }))} />
            </ModalBody>
        </Modal>
        <BinPicker ref={binPickerRef} warehouseId={inbound.warehouse.warehouseId} hidden onSelect={bin => {
            for (const i of selectedItems) {
                validation.setFieldValue(`items.${i}.binTargetId`, bin?.binId ?? "");
            }
        }} />
    </>;
}

export default ShippingPlanProcess;