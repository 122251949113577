import { APIClient } from "../helpers/api_helper";
import { AssignPutawayBinCommand, AssignPutawayItemCommand, type AddBoxImageCommand } from "./types/commands";
import { BinContract } from "./types/contracts/bin";
import type { BoxContract, LocationContract, LocationHistoryContract } from "./types/contracts/inventory";
import { BinLocationContract } from "./types/contracts/locations";
import { SearchBinOrBinLocationQuery } from "./types/queries";

const api = new APIClient();

export const searchBinOrBinLocation = async (params: SearchBinOrBinLocationQuery) => {
    const result = await api.get(`/api/inventory/search-bin/${params.label}`) as BinContract | BinLocationContract | undefined;
    return result;
}

export const listPutawayItemHistory = async () => {
    const result = await api.get("/api/inventory/putaway-items") as LocationHistoryContract[];
    return result;
}

export const listPutawayBinHistory = async () => {
    const result = await api.get("/api/inventory/putaway-bins") as LocationHistoryContract[];
    return result;
}

export const postPutawayItem = async (data: AssignPutawayItemCommand) => {
    const result = await api.post("/api/inventory/putaway-items", { ...data });
    return result.data as LocationContract;
}

export const postPutawayBin = async (data: AssignPutawayBinCommand) => {
    const result = await api.post("/api/inventory/putaway-bins", { ...data });
    return result.data as LocationContract;
}

export const postAddBoxImage = async (data: AddBoxImageCommand) => {
    const { boxId, ...rest } = data;
    const result = await api.post(`/api/inventory/box/${boxId}/image`, rest);

    return result.data as BoxContract;
}