import { Input, InputProps } from "reactstrap";
import { ValidatableInputProps } from "helpers/types";
import { useState } from "react";

type TextInputEventArgs = {
    oldValue: string | undefined,
    value: string
}

export type TextInputProps = Pick<InputProps, "id" | "disabled" | "className" | "style" | "placeholder" | "onInput" | "tabIndex" | "maxLength" | "innerRef"> & ValidatableInputProps<string> & {
    type?: "text" | "email" | "url" | "textarea" | "password",
    size?: InputProps["bsSize"],
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement> & TextInputEventArgs) => void,
    onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement> & TextInputEventArgs) => void,
    onBlur?: (event: React.FocusEvent<HTMLInputElement> & TextInputEventArgs) => void,
    onFocus?: (event: React.FocusEvent<HTMLInputElement> & TextInputEventArgs) => void
}

const TextInput = ({ isValid, className, value, size, onChange, onBlur, onFocus, onKeyDown, onKeyUp, ...rest }: TextInputProps) =>  {
    const [oldValue, setOldValue] = useState(value);

    return <>
        <Input
            //valid={isValid === true}
            invalid={isValid === false}
            className={className}
            onChange={e => {
                setOldValue(value);
                onChange?.(e.target.value);
            }}
            onBlur={e => {
                onBlur?.({ ...e, oldValue: oldValue, value: e.target.value });
            }}
            onFocus={e => {
                setOldValue(value);
                onFocus?.({ ...e, oldValue: oldValue, value: e.target.value });
            }}
            onKeyDown={e => {
                if (e.key === "Enter") {
                    setOldValue(value);
                }

                onKeyDown?.({ 
                    ...e, 
                    preventDefault: e.preventDefault,
                    stopPropagation: e.stopPropagation,
                    oldValue: oldValue, value: e.currentTarget.value 
                });
            }}
            onKeyUp={e => {
                if (e.key === "Enter") {
                    setOldValue(value);
                }
                
                onKeyUp?.({ 
                    ...e, 
                    preventDefault: e.preventDefault,
                    stopPropagation: e.stopPropagation,
                    oldValue: oldValue, value: e.currentTarget.value 
                });
            }}
            value={value ?? ""}
            bsSize={size}
            {...rest} />
    </>;
}

export default TextInput;