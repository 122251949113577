import { useQuery } from "@tanstack/react-query";
import BusyOverlay from "Components/Common/BusyOverlay";
import InlineAddressDisplay from "Components/Displays/InlineAddressDisplay";
import { useProfile } from "Components/Hooks/ProfileHooks";
import { WarehouseContract } from "api/types/contracts/warehouses";
import { getWarehouseListForSelect } from "api/warehouse";
import classNames from "classnames";
import { ValidatableInputProps } from "helpers/types";
import { useEffect, useMemo, useState } from "react";
import { Col, Input, Label, Row } from "reactstrap";

type WarehouseGridSelectProps = ValidatableInputProps<string> & {
    onSelect?: (warehouse: WarehouseContract) => void,
    className?: string
};

const WarehouseGridSelect = (props: WarehouseGridSelectProps) => {
    const [initial, setInitial] = useState(true);
    const { userProfile, customer } = useProfile();
    const { data: warehouses, isFetching: loading } = useQuery({
        queryKey: ["warehouse-grid-select"],
        queryFn: () => getWarehouseListForSelect({})
    });

    const onChange = (warehouse: WarehouseContract) => {
        props.onChange?.(warehouse.warehouseId);
        props.onSelect?.(warehouse);
    };

    const warehouseList = useMemo(() => (warehouses ? warehouses.filter(w => {
        if (customer) {
            return customer.properties.availableWarehouses?.includes(w.warehouseId);
        }
        else {
            return true;
        }
    }) : []), [customer, warehouses]);

    useEffect(() => {
        if (!props.value) {
            return;
        }
        const found = warehouses?.find(w => w.warehouseId === props.value);
        
        if (found){
            onChange(found);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warehouses, props.value]);

    useEffect(() => {
        if (initial && userProfile?.user.warehouse) {
            onChange(userProfile.user.warehouse);
            setInitial(false);
        }
    }, [userProfile?.user.warehouse?.warehouseId, initial]);

    return <>
        <BusyOverlay busy={loading} spinnerColor="tenant-primary" backgroundColor="body-secondary" opaque>
            <Row className={classNames("g-3 m-2", props.className)} style={{
                minHeight: "100px"
            }}>
                {warehouseList.map((warehouse, i) => <Col xs={4} key={i}>
                    <div className="form-check card-radio">
                        <Input id={warehouse.warehouseId} name="warehouses" 
                            type="radio" className="form-check-input" 
                            checked={props.value === warehouse.warehouseId} onChange={() => onChange(warehouse)} />
                        <Label className="form-check-label h-100" htmlFor={warehouse.warehouseId}>
                            <span className="hstack gap-3 align-items-start">
                                <div className="vstack gap-2">
                                    <span className="fs-16 text-nowrap d-block">
                                        {warehouse.address.state}, {warehouse.address.country.name}
                                    </span>
                                    <span className="fs-14 text-wrap d-block">
                                        <strong>{warehouse.name}</strong>
                                    </span>
                                    <span className="fs-12 fw-light text-wrap d-block">
                                        <InlineAddressDisplay address={warehouse.address} />
                                    </span>
                                </div>
                            </span>
                        </Label>
                    </div>
                </Col>)}
            </Row>
        </BusyOverlay>
    </>;
}

export default WarehouseGridSelect;