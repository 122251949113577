import TitleBreadcrumb from "Components/Common/TitleBreadcrumb";
import Restricted from "Components/Common/Restricted";
import { Button, Container } from "reactstrap";
import TopInfo from "./_TopInfo";
import { Await, useLoaderData } from "react-router-dom";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import Loader from "Components/Common/Loader";
import ShipmentProgress from "./_ShipmentProgress";
import { FbaShipmentContract, FbaShipmentStatus } from "api/types/contracts/shipping";
import ProductCardGrid from "./_ProductCardGrid";
import EditPackages from "./_EditPackages";
import ViewPackages from "./_ViewPackages";
import { useProfile } from "Components/Hooks/ProfileHooks";
import ProductList from "./_ProductList";
import DocumentUpload from "./_DocumentUpload";
import DocumentList from "./_DocumentList";
import InvoiceList from "./_Invoices";
import { setProcessingItem } from "slices/shipping/fbaShipping/reducer";
import CostBreakdown from "./_CostBreakdown";
import { updateFbaShipment } from "slices/shipping/fbaShipping/thunk";
import BusyOverlay from "Components/Common/BusyOverlay";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import CarrierInfo from "./_CarrierInfo";
import ShipmentList from "./_Shipments";
import { createAppSelector, useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import { SuccessAlert } from "Components/Alerts";

type LoaderData = {
    fbaShipment: Promise<FbaShipmentContract>
} | undefined;

const ProcessFbaShipmentPage = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { setPageTitle } = useMetadata();
    const loader = useLoaderData() as LoaderData;
    const { userProfile } = useProfile();

    const { stateFbaShipment, loading } = useAppSelector(
        createAppSelector([state => state.FbaShipping], 
            (fbaShipping) => ({
                stateFbaShipment: fbaShipping.processingItem,
                loading: fbaShipping.loading
            })
        )
    );

    setPageTitle(`${t("FBA Shipment Detail")} - ${t("FBA Shipment")}`);

    const checkStatus = (...status: FbaShipmentStatus[]) => status.includes(stateFbaShipment?.status ?? "draft");

    return <>
        <div className="page-content">
            <Container fluid>
                <Restricted require="shipping.fbashipping" throw>
                    <Suspense fallback={<Loader height="500px" />}>
                        <Await resolve={loader?.fbaShipment}>
                            {(resolvedFbaShipment: FbaShipmentContract) => {
                                if (!stateFbaShipment || stateFbaShipment.fbaShipmentId !== resolvedFbaShipment.fbaShipmentId) {
                                    dispatch(setProcessingItem(resolvedFbaShipment));
                                }

                                const fbaShipment = stateFbaShipment ?? resolvedFbaShipment;

                                return <>
                                    <TitleBreadcrumb active={`View #${fbaShipment.shipmentCode}`} parents={[t("FBA Prep & Shipping"), { title: t("FBA Shipments"), to: "/fba-shipments"}]} />
                                    <TopInfo />
                                    <ShipmentProgress />

                                    {fbaShipment.owner.userId === userProfile?.user.userId ? <>
                                        <ProductList />
                                        <ViewPackages />
                                        {checkStatus("boxLabelPending", "boxLabelUploaded", "paymentWaiting", "paymentCompleted") ? <DocumentUpload /> : <DocumentList />}
                                        {checkStatus("productPending", "fnskuPending", "ready") && <CarrierInfo />}
                                    </> : <>
                                        <ProductCardGrid />
                                        <EditPackages />
                                        {checkStatus("boxLabelPending", "boxLabelUploaded", "paymentWaiting", "paymentCompleted", "shipped") && <DocumentList />}
                                        <CarrierInfo />
                                    </>}
                                    {checkStatus("boxLabelPending", "boxLabelUploaded", "paymentWaiting", "paymentCompleted", "shipped") && <>
                                        <ShipmentList />
                                    </>}
                                    {checkStatus("productPending", "fnskuPending", "ready", "processing", "boxLabelPending", "boxLabelUploaded") && <>
                                        <CostBreakdown />
                                    </>}
                                    {checkStatus("processing", "boxLabelPending", "boxLabelUploaded", "paymentWaiting", "paymentCompleted", "shipped") && <InvoiceList />}
                                    {checkStatus("paymentCompleted") && <div className="d-flex justify-content-center my-4">
                                        <Restricted require="shipping.fbashipping" write>
                                            <BusyOverlay busy={loading.save} size="sm" overlayClassName="w-25">
                                                <Button color="primary" block onClick={() => {
                                                    dispatch(updateFbaShipment({
                                                        fbaShipmentId: fbaShipment.fbaShipmentId,
                                                        status: "shipped"
                                                    }));
                                                }}>
                                                    {t("Send To Amazon")}
                                                </Button>
                                            </BusyOverlay>
                                        </Restricted>
                                    </div>}
                                    {checkStatus("shipped") && <SuccessAlert kind="solid">
                                        {t("Shipment has been sent to Amazon")}
                                    </SuccessAlert>}
                                </>;
                            }}
                        </Await>
                    </Suspense>
                </Restricted>
            </Container>
        </div>
    </>;
}

export default ProcessFbaShipmentPage;