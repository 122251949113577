import { ColumnDef } from "@tanstack/react-table";
import Dialog, { DialogRef } from "Components/Common/Dialog";
import TableContainer, { selectRowColumn, TableContainerRef } from "Components/Common/TableContainer";
import { BinLocationContract } from "api/types/contracts/locations";
import { useRef, useMemo, useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, CardHeader, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { reloadLocations, removeBinLocation } from "slices/location/thunk";
import UpdateBinLocationFormView from "./_UpdateBinLocationFormView";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import { createAppSelector, useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import BusyOverlay from "Components/Common/BusyOverlay";
import { useMutation } from "@tanstack/react-query";
import { postGenerateBinLocationLabel } from "api/printing";
import { multiDownload, withCdn } from "helpers/urlHelper";
import { toast } from "react-toastify";
import LengthDisplay from "Components/Displays/LengthDisplay";
import ListSummaryDisplay from "Components/Common/ListSummaryDisplay";

type ListProps = {
    binLocations: BinLocationContract[] | undefined
}

const List = (props: ListProps) => {
    const [deleteMode, setDeleteMode] = useState<"single" | "multi">();
    const [editModal, setEditModal] = useState(false);
    const [selectedItems, setSelectedItems] = useState<BinLocationContract[]>([]);
    const binLocation = useMemo(() => selectedItems[0], [selectedItems]);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const tableRef = useRef<TableContainerRef>(null);
    const dialogRef = useRef<DialogRef>(null);

    const { warehouse, loading } = useAppSelector(
        createAppSelector([state => state.Location],
            (location) => ({
                warehouse: location.warehouse,
                loading: location.loading
            })
        )
    );

    const reload = () => dispatch(reloadLocations({ warehouseId: warehouse!.warehouseId }));

    const deleteBinLocation = useCallback(async () => {
        if (deleteMode === "multi" && selectedItems.length > 0) {
            let success = false;
            for (const item of selectedItems) {
                success &&= await dispatch(removeBinLocation({
                    binLocationId: item.binLocationId
                }));
            }

            return success;
        }
        else if (deleteMode === "single" && binLocation) {
            return await dispatch(removeBinLocation({
                binLocationId: binLocation.binLocationId
            }));
        }

        return false;
    }, [binLocation, deleteMode, selectedItems, dispatch]);

    const printLabelMutation = useMutation({
        mutationFn: postGenerateBinLocationLabel,
        mutationKey: ["generate-bin-location-label"],
        onSuccess: async (result) => {
            if (result.printQueued) {
                toast.success(t("Label sent to printer"));
            }
            else {
                await multiDownload([withCdn(result.filePath)]);
            }
        }
    });

    const printSelectedLabelsMutation = useMutation({
        mutationFn: async (params: { binLocationId: string }[]) => {
            const results = await Promise.all(params.map(postGenerateBinLocationLabel));
        
            const urls: string[] = [];
            for (const result of results) {
                if (!result.printQueued) {
                    urls.push(withCdn(result.filePath));
                }
            }

            return urls;
        },
        mutationKey: ["batch-generate-bin-location-label"],
        onSuccess: async (urls) => {
            await multiDownload(urls);

            toast.success(t("{{count}} label sent to printer", { count: urls.length }));
        }
    });

    const handleEditClick = (arg: BinLocationContract) => {
        setSelectedItems([arg]);
        setEditModal(true);
    };

    const handleDeleteClick = (arg: BinLocationContract) => {
        setSelectedItems([arg]);
        setDeleteMode("single");
    };

    const handleMultiDeleteClick = () => {
        setDeleteMode("multi");
    };

    const handlePrintClick = (arg: BinLocationContract) => {
        printLabelMutation.mutate({ binLocationId: arg.binLocationId });
    }

    const handleMultiPrintClick = () => {
        printSelectedLabelsMutation.mutate(selectedItems.map(item => ({
            binLocationId: item.binLocationId
        })));
    }

    // Column
    const columns = useMemo<ColumnDef<BinLocationContract, any>[]>(() => [
        selectRowColumn(), {
        header: t("ACTIONS"),
        enableHiding: false,
        cell: (cell) => {
            const printPending = printLabelMutation.isPending && printLabelMutation.variables.binLocationId === cell.row.original.binLocationId;

            return <div className="hstack gap-1">
                <Button size="sm" color="ghost-dark" className="btn-icon" onClick={() => handleEditClick(cell.row.original)}>
                    <i className="ri-pencil-fill fs-16"></i>
                </Button>
                <BusyOverlay busy={printPending} size="sm" backgroundColor="body-secondary" spinnerColor="tenant-primary" opaque>
                    <Button size="sm" color="ghost-info" className="btn-icon" onClick={() => handlePrintClick(cell.row.original)}>
                        <i className="ri-printer-line fs-16"></i>
                    </Button>
                </BusyOverlay>
                <Button size="sm" color="ghost-danger" className="btn-icon" onClick={() => handleDeleteClick(cell.row.original)}>
                    <i className="ri-delete-bin-5-fill fs-16"></i>
                </Button>
            </div>;
        },
    }, {
        header: t("BIN LOCATION NAME"),
        accessorFn: item => item.name,
        enableHiding: false,
        enableColumnFilter: false,
        cell: (cell) => {
            return <span># {cell.row.original.name}</span>;
        },
    }, {
        header: t("WIDTH"),
        accessorFn: item => item.properties.width,
        enableColumnFilter: false,
        cell: (cell) => <LengthDisplay value={cell.row.original.properties.width} unit={warehouse?.settings.unitOfLength ?? "inch"} />,
    }, {
        header: t("HEIGHT"),
        accessorFn: item => item.properties.height,
        enableColumnFilter: false,
        cell: (cell) => <LengthDisplay value={cell.row.original.properties.height} unit={warehouse?.settings.unitOfLength ?? "inch"} />,
    }, {
        header: t("DEPTH"),
        accessorFn: item => item.properties.depth,
        enableColumnFilter: false,
        cell: (cell) => <LengthDisplay value={cell.row.original.properties.depth} unit={warehouse?.settings.unitOfLength ?? "inch"} />,
    }, {
        header: t("PRODUCTS"),
        enableColumnFilter: false,
        cell: (cell) => <ListSummaryDisplay items={[]} displayFn={i => i} />
    }], [t, printLabelMutation]);

    useEffect(() => {
        if (deleteMode) {
            dialogRef.current?.show();
        }
        else {
            dialogRef.current?.hide();
        }
    }, [deleteMode])

    return <>
        <Card>
            <CardHeader className="border-0 pb-0">
                <Row className="align-items-center gy-3">
                    <div className="col-sm">
                        <h5 className="card-title mb-0">{t("Bin Location List")}</h5>
                    </div>
                    <div className="col-sm-auto">
                        <div className="d-flex gap-1 flex-wrap">
                            {selectedItems.length > 0 && <BusyOverlay busy={printSelectedLabelsMutation.isPending} size="sm">
                                <Button color="soft-info" onClick={() => handleMultiPrintClick()}>
                                    <i className="ri-printer-line"></i>
                                </Button>
                            </BusyOverlay>}
                            {selectedItems.length > 0 && deleteMode !== "single" && <Button color="soft-danger" onClick={() => handleMultiDeleteClick()}>
                                <i className="ri-delete-bin-2-line"></i>
                            </Button>}
                        </div>
                    </div>
                </Row>
            </CardHeader>
            <CardBody>
                <TableContainer
                    ref={tableRef}
                    columns={columns}
                    nowrap
                    data={props.binLocations || []}
                    totalDataLength={props.binLocations?.length || 0}
                    onSelectionChanged={selection => {
                        setSelectedItems(selection);
                    }}
                    enableRowSelection
                    divClass="mb-1"
                    tableClass="align-middle"
                />
            </CardBody>
        </Card>
        {binLocation && <Modal backdrop="static" isOpen={editModal} toggle={() => setEditModal(prev => !prev)} unmountOnClose>
            <ModalCloseButton onClick={() => setEditModal(false)} />
            <ModalHeader>
                {t("Bin Location Information")}
            </ModalHeader>
            <ModalBody>
                <UpdateBinLocationFormView binLocation={binLocation} onSuccess={() => { 
                    setEditModal(false); 
                    reload(); 
                    }} />
            </ModalBody>
        </Modal>}
        {deleteMode && <Dialog ref={dialogRef} color="warning" buttons={["yes", "no"]} busy={loading.delete} iconClass="ri-delete-bin-line"  
            message={t(`Do you want to continue?`)} title={t("Deleting bin location '{{name}}'", { name: binLocation?.name, count: deleteMode === "multi" ? selectedItems.length : 1 })}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    deleteBinLocation().then(success => {
                        if (success) {
                            reload();
                        }
                    }).finally(() => {
                        setDeleteMode(undefined);
                        setSelectedItems([]);
                    });
                }
                else {
                    setDeleteMode(undefined);
                }
            }} />}
    </>;
}

export default List;