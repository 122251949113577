import { useMutation } from "@tanstack/react-query";
import { useToggle } from "@uidotdev/usehooks";
import type { InboundContract, InboundFormItem } from "api/types/contracts/inbound";
import type { ProductContract } from "api/types/contracts/products";
import BusyOverlay from "Components/Common/BusyOverlay";
import InfoBubble from "Components/Common/InfoBubble";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import TruncatableText from "Components/Common/TruncatableText";
import ProductIdentifiersDisplay, { ProductIdentifiersHeader } from "Components/Displays/ProductIdentifiersDisplay";
import ProductImageDisplay from "Components/Displays/ProductImageDisplay";
import ProductPickList from "Components/EntitySelects/ProductPickList";
import SearchBox from "Components/Form/SearchBox";
import StepInput from "Components/Form/StepInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { useFormik } from "formik";
import { stockOf } from "helpers/stockOf";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, CardHeader, Modal, ModalBody, Table } from "reactstrap";
import * as Yup from "yup";
import letsAddYourFirstProductImage from "assets/images/lets_add_your_first_product.png";

type SelectProductsStepProps = {
    editable: boolean,
    collapsed: boolean,
    inbound: InboundContract | undefined,
    onSaved: (products: InboundFormItem[]) => Promise<void>,
    onEdit: () => void
};

const SelectProductsStep = (props: SelectProductsStepProps) => {
    const { t } = useTranslation();
    const [productSelectModal, toggleProductSelectModal] = useToggle();
    const [selectedProducts, setSelectedProducts] = useState<ProductContract[]>([]);

    const saveMutation = useMutation({
        mutationFn: props.onSaved
    });

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            items: Array<InboundFormItem>()
        },
        validationSchema: Yup.object({ 
            items: Yup.array<InboundFormItem>().of(Yup.object({
                productId: Yup.string().required(t("Product is required")),
                quantity: Yup.number().required(t("Quantity is required"))
            })).required(t("Items is required")).min(1, t("At least one product is required")),
        }),
        onSubmit: values => {
            saveMutation.mutate(values.items);
        },
    });

    useEffect(() => {
        console.log("props.inbound", props.inbound);
        if (props.inbound) {
            
            validation.setFieldValue("items", props.inbound.items.map(i => ({
                productId: i.product.productId,
                quantity: i.quantity
            })));

            setSelectedProducts(props.inbound.items.map(i => i.product));
        }
    }, [props.inbound]);

    const items = useMemo(() => validation.values.items.map(i => {
        const product = selectedProducts.find(p => p.productId === i.productId)!;
        const stock = stockOf(product, "");

        return {
            ...i,
            product,
            stock
        };
    }).filter(i => i.product), [validation.values, selectedProducts]);

    useEffect(() => {
        const newItems = selectedProducts.map<InboundFormItem>(p => ({
            productId: p.productId,
            quantity: 1
        }));
        const uniqueItems = _.uniqBy([...items.map(i => ({
            productId: i.product.productId,
            quantity: i.quantity
        })), ...newItems], i => i.productId);

        validation.setFieldValue("items", uniqueItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProducts]);

    const summary = useMemo(() => {
        if (items.length === 0) {
            return undefined;
        }

        const skuCount = items.length;
        const totalQuantity = _.sumBy(items, i => i.quantity);

        return {
            skuCount,
            totalQuantity
        };
    }, [items]);

    return <>
        <Card>
            <CardHeader className="py-1">
                <div className="hstack gap-3">
                    {props.collapsed ? <i className="ri-checkbox-circle-fill text-success ri-3x"></i>
                    : <i className="ri-checkbox-circle-line text-light-emphasis ri-3x"></i>}
                    <div>
                        <h4 className="card-title">{t("Select Products")}</h4>
                        <p className="card-subtitle">{t("paragraphs:ShippingPlanSelectProductsHeader")}</p>
                    </div>
                    {props.collapsed ? <>
                        <div className="ms-auto text-muted">
                            {summary && (t("{{count}} SKU", { count: summary.skuCount }) + ", " + t("{{count}} item", { count: summary.totalQuantity}))}
                        </div>
                        <Button type="button" disabled={!props.editable} color="outline-info" className="btn-label" onClick={props.onEdit}>
                            <i className="label-icon ri-pencil-line"></i>
                            {t("Edit")}
                        </Button>
                    </> : <>
                        <BusyOverlay busy={saveMutation.isPending} size="sm" overlayClassName="ms-auto">
                            <ValidatorButton validation={validation} color="info" className="btn-label" onClick={() => {
                                validation.submitForm();
                            }}>
                                <i className="label-icon ri-save-3-line"></i>
                                {t("Save & Continue")}
                            </ValidatorButton>                   
                        </BusyOverlay>
                    </>}
                </div>
            </CardHeader>
            {!props.collapsed && <CardBody>
                <div className="p-4">
                    <SearchBox value="" onChange={val => {}} onClick={() => toggleProductSelectModal()} placeholder={t("Search for items by name, SKU, ASIN or barcode")} />
                    {items.length === 0 ? <div style={{ height: "300px" }} className="vstack gap-1 justify-content-center align-items-center">
                        <img src={letsAddYourFirstProductImage} alt={t("Let's Add Your First Product")} style={{ width: "8rem" }} className="mb-2" />
                        <h5 className="fw-bold">{t("Let's Add Your First Product")}</h5>
                        <p className="small">{t("paragraphs:ShippingPlanSelectProductsDescription")}</p>
                    </div> : <div style={{ minHeight: "300px" }}>
                        <Table borderless className="table-centered align-middle mb-0 table-nowrap">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>{t("Product")}</th>
                                    <th><ProductIdentifiersHeader /> </th>
                                    <th className="text-center">{t("Quantity to Send")}</th>
                                    <th className="text-center">{t("Available")} <InfoBubble text={t("paragraphs:ShippingPlanSelectProductsAvailableColumnInfo")} /></th>
                                    <th className="text-center">{t("In Transit")} <InfoBubble text={t("paragraphs:ShippingPlanSelectProductsInTransitColumnInfo")} /></th>
                                    <th className="text-center">{t("After")} <InfoBubble text={t("paragraphs:ShippingPlanSelectProductsAfterColumnInfo")} /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((item, index) => <tr key={index}>
                                    <td>
                                        <Button color="ghost-danger" className="btn-icon" size="sm" onClick={() => {
                                            setSelectedProducts(selectedProducts.filter(p => p.productId !== item.product.productId));
                                        }}>
                                            <i className="ri-delete-bin-line fs-16"></i>
                                        </Button>
                                    </td>
                                    <td>
                                        <div className="hstack gap-2">
                                            <ProductImageDisplay product={item.product} className="flex-shrink-0" />
                                            <div>
                                                <span className="fw-semibold"><TruncatableText maxLines={1} noUnderline>{item.product.name}</TruncatableText></span>
                                                <span className="text-muted small">SKU: {item.product.sku}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td><ProductIdentifiersDisplay product={item.product} /></td>
                                    <td>
                                        <ValidationWrapper validation={validation} field={`items.${index}.quantity`}>
                                            <StepInput min={1} size="sm" />
                                        </ValidationWrapper>
                                    </td>
                                    <td className="text-center">{item.stock.available}</td>
                                    <td className="text-center">{item.stock.inbound}</td>
                                    <td className="text-center">{item.stock.available + item.stock.inbound + item.quantity}</td>
                                </tr>)}
                            </tbody>
                        </Table>
                    </div>}
                </div>
            </CardBody>}
        </Card>
        <Modal backdrop="static" isOpen={productSelectModal} toggle={() => toggleProductSelectModal()} size="xl">
            <ModalBody>
                <ModalCloseButton onClick={() => toggleProductSelectModal()} />
                <ProductPickList disabledProducts={selectedProducts} onSelectComplete={selected => {
                    toggleProductSelectModal();

                    if (selected.length === 0) {
                        return;
                    }

                    setSelectedProducts(_.uniqBy([...selectedProducts, ...selected], p => p.productId));
                }} />
            </ModalBody>
        </Modal>
    </>;
}

export default SelectProductsStep;