import React, { ReactNode, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, PopoverBody, UncontrolledPopover } from "reactstrap";

type ListSummaryDisplayProps<T> = {
    items: T[],
    displayFn: (item: T) => ReactNode,
    listFn?: (items: T) => ReactNode,
    displayCount?: number,
    seperator?: "comma" | "newline" | "space" | "none" | string
}

const ListSummaryDisplay = <T,>(props: ListSummaryDisplayProps<T>) => {
    const { t } = useTranslation();
    const remainingDisplayRef = useRef<HTMLButtonElement>(null);
    
    if (props.items.length === 0) {
        return <span>-</span>;
    }

    let seperator: string;

    switch (props.seperator) {
        case "comma":
            seperator = ", ";
            break;
        case "newline":
            seperator = "<br />";
            break;
        case "space":
            seperator = " ";
            break;
        default:
            seperator = props.seperator || "";
            break;
    }

    const listFn = props.listFn ?? props.displayFn;

    const displayCount = props.displayCount ?? 1;
    const displayItems = props.items.slice(0, displayCount).map(props.displayFn).filter(item => item);
    const listItems = props.items.map(listFn).filter(item => item);
    const remainingCount = listItems.length - displayCount;

    return <>
        {displayItems.length > 0 ? <span>{displayItems.map((d, i) => <span key={i}>{i !== 0 && seperator}{d}</span>)}</span> : <span>-</span>}
        {remainingCount > 0 && <>
            <Button color="link" className="link-secondary ms-1" innerRef={remainingDisplayRef}>
                {displayItems.length > 0 && "+"}{" "}{remainingCount}{" "}
                {displayItems.length > 0 ? t("more") : t("item", { count: remainingCount })}
            </Button>
            <UncontrolledPopover target={remainingDisplayRef} trigger="legacy">
                <PopoverBody>
                    <div className="vstack gap-1">
                        {listItems.map((item, i) => <span key={i}>{item}</span>)}
                    </div>
                </PopoverBody>
            </UncontrolledPopover>
        </>}
    </>;
}

export default ListSummaryDisplay;