import TableContainer, { TableContainerRef, selectRowColumn } from "Components/Common/TableContainer";
import { Ref, RefAttributes, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Badge, Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { ColumnDef } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import SearchBox from "Components/Form/SearchBox";
import { useDebounce } from "Components/Hooks/HelperHooks";
import { ListCustomersQuery } from "api/types/queries";
import Dialog, { DialogRef } from "Components/Common/Dialog";
import { useImmer } from "use-immer";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import Restricted from "Components/Common/Restricted";
import { CustomerContract } from "api/types/contracts/customers";
import { loadList, removeCustomer, removeMultipleCustomers } from "slices/customer/thunk";
import { UserStatus } from "api/types/contracts/users";
import CompanySelect from "Components/EntitySelects/CompanySelect";
import CountrySelect from "Components/EntitySelects/CountrySelect";
import UserStatusBadge from "Components/Displays/UserStatusBadge";
import DateDisplay from "Components/Displays/DateDisplay";
import UserStatusSelect from "Components/EnumSelects/UserStatusSelect";
import CountryDisplay from "Components/Displays/CountryDisplay";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";

export type CustomerListRef = {
    reload: VoidFunction
}

const List = (props: {}, ref: Ref<CustomerListRef>) => {
    const [deleteMode, setDeleteMode] = useState<"single" | "multi">();
    const [selectedItems, setSelectedItems] = useState<CustomerContract[]>([]);
    const customer = useMemo(() => selectedItems[0], [selectedItems]);
    const params = new URLSearchParams(window.location.search);
    
    const [query, updateQuery] = useImmer<ListCustomersQuery>({
        page: 1,
        pageSize: 10,
        companyId: params.get("company") ?? undefined,
    })

    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { setPageTitle } = useMetadata();
    const tableRef = useRef<TableContainerRef>(null);
    const dialogRef = useRef<DialogRef>(null);

    const { list, loading } = useAppSelector(
        (state) => ({
            loading: state.Customer.loading,
            list: state.Customer.list,
            error: state.Customer.error,
        })
    );

    setPageTitle(`${t("Customer List")} - ${t("Management")}`);
    
    const debouncedLoadList = useDebounce(() => {
        dispatch(loadList(query)).then(() => {
            tableRef.current?.resetSelection();
        });
    }, 500);

    useEffect(() => {
        debouncedLoadList();
    }, [debouncedLoadList, query]);

    useImperativeHandle(ref, () => {
        return {
            reload: () => {
                debouncedLoadList();
            }
        };
    }, [debouncedLoadList]);

    const deleteCustomer = useCallback(async () => {
        if (deleteMode === "multi" && selectedItems.length > 0) {
            const success = await dispatch(removeMultipleCustomers(selectedItems.map(c => ({
                customerId: c.customerId
            }))));

            return success;
        }
        else if (deleteMode === "single" && customer) {
            return await dispatch(removeCustomer({
                customerId: customer.customerId
            }));
        }

        return false;
    }, [customer, deleteMode, selectedItems, dispatch]);

    const handleDeleteClick = (arg: CustomerContract) => {
        setSelectedItems([arg]);
        setDeleteMode("single");
    };

    const handleMultiDeleteClick = () => {
        setDeleteMode("multi");
    };

    useEffect(() => {
        if (deleteMode) {
            dialogRef.current?.show();
        }
        else {
            dialogRef.current?.hide();
        }
    }, [deleteMode])

    // Column
    const columns = useMemo<ColumnDef<CustomerContract, any>[]>(() => [
        selectRowColumn<CustomerContract>(),
        {
            header: t("ACTIONS"),
            enableHiding: false,
            cell: (cell) => {
                return <>
                    <div className="hstack gap-1">
                        <Restricted require="management.customer" write>
                            <Link to={`/customers/edit/${cell.row.original.customerId}`} className="btn btn-ghost-dark btn-icon btn-sm">
                                <i className="ri-pencil-fill fs-16"></i>
                            </Link>
                        </Restricted>
                        <Restricted require="management.customer" delete>
                            <Button color="ghost-danger" size="sm" className="btn-icon"
                                onClick={() => handleDeleteClick(cell.row.original)}>
                                <i className="ri-delete-bin-5-fill fs-16"></i>
                            </Button>
                        </Restricted>
                    </div>
                </>;
            },
        },
        {
            header: t("COMPANY"),
            accessorFn: item => item.name,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => {
                return <Restricted require="management.company" write fallback={() => <>{cell.row.original.company.name}</>}>
                    <Link to={`/companies/edit/${cell.row.original.company.companyId}`} className="fw-medium link-primary">{cell.row.original.company.name}</Link>
                </Restricted>
            },
        },
        {
            header: t("SUIT"),
            accessorFn: item => item.suitNumber,
            enableColumnFilter: false,
        },
        {
            header: t("CREATE DATE"),
            accessorFn: item => item.createdAt,
            enableColumnFilter: false,
            cell: (cell) => <DateDisplay date={cell.row.original.createdAt} />,
        },
        {
            header: t("NAME"),
            accessorFn: item => item.name,
            enableColumnFilter: false,
        },
        {
            header: t("SURNAME"),
            accessorFn: item => item.surname,
            enableColumnFilter: false,
        },
        {
            header: t("EMAIL"),
            accessorFn: item => item.user.email,
            enableColumnFilter: false,
            cell: (cell) => (
                <>
                    {cell.row.original.user.userStatus === "pending" && <Badge color="warning"></Badge>}
                    {cell.row.original.user.userStatus !== "pending" && <Badge color="success"></Badge>}
                    <span>{cell.getValue()}</span>
                </>
            ),
        },
        {
            header: t("PHONE"),
            accessorFn: item => item.phone,
            enableColumnFilter: false,
        },
        {
            header: t("COMPANY NAME"),
            accessorFn: item => item.firmName,
            enableColumnFilter: false,
        },
        {
            header: t("COUNTRY"),
            accessorFn: item => item.country.name,
            enableColumnFilter: false,
            cell: (cell) => <CountryDisplay country={cell.row.original.country} />
        },
        {
            header: t("STATUS"),
            accessorFn: item => item.user.userStatus,
            enableColumnFilter: false,
            cell: (cell) => <UserStatusBadge value={cell.getValue<UserStatus>()} />
        }],
        [t]
    );

    return <>
        <Row>
            <Col lg={12}>
                <Card id="customerList">
                    <CardHeader className="border-0">
                        <Row className="align-items-center gy-3">
                            <div className="col-sm">
                                <h5 className="card-title mb-0">{t("Customer List")}</h5>
                            </div>
                            <div className="col-sm-auto">
                                <div className="d-flex gap-1 flex-wrap">
                                    {selectedItems.length > 0 && deleteMode !== "single" && <>
                                        <Restricted require="management.customer" delete>
                                            <Button color="soft-danger" onClick={handleMultiDeleteClick}><i
                                                className="ri-delete-bin-2-line"></i>
                                            </Button>
                                        </Restricted>
                                    </>}
                                </div>
                            </div>
                        </Row>
                    </CardHeader>
                    <CardBody className="pt-0">
                        <div>
                            <Row>
                                <Restricted require={"management.company"} read>
                                    <Col sm={6} md={4} lg={2}>
                                        <CompanySelect value={query.companyId} onSelect={(val) => updateQuery(q => {
                                            q.companyId = val?.companyId;
                                        })} />
                                    </Col>
                                </Restricted>
                                <Col sm={6} md={4} lg={2}>
                                    <CountrySelect value={query.countryCode} onSelect={(val) => updateQuery(q => {
                                        q.countryCode = val?.code;
                                    })} />
                                </Col>
                                <Col sm={6} md={4} lg={2}>
                                    <UserStatusSelect value={query.userStatus} showClear onChange={val => updateQuery(q => {
                                        q.userStatus = val;
                                    })} />
                                </Col>
                                <Col sm={6} md={4} lg={6}>
                                    <SearchBox value={query.search || ""} placeholder="Search name, surname, email, phone or suit (with C- prefix)"
                                        onChange={val => updateQuery(q => {
                                            q.search = val;
                                        })} />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col md={6}>
                                </Col>
                            </Row>
                            <TableContainer
                                ref={tableRef}
                                busy={loading.list}
                                columns={columns}
                                nowrap
                                data={(list?.items || [])}
                                totalDataLength={list?.totalCount}
                                pagination={{
                                    pageIndex: query.page - 1,
                                    pageSize: query.pageSize
                                }}
                                onPaginationChanged={pagination => updateQuery(q => {
                                    q.page = pagination.pageIndex + 1;
                                    q.pageSize = pagination.pageSize;
                                })}
                                onSelectionChanged={selection => {
                                    setSelectedItems(selection);
                                }}
                                enableRowSelection
                                divClass="mb-1"
                                tableClass="align-middle"
                            />
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
        {deleteMode && <Dialog ref={dialogRef} color="warning" buttons={["yes", "no"]} busy={loading.delete} iconClass="ri-delete-bin-line"  
            message={`Do you want to continue?`} title={t("Deleting customer '{{name}}'", { name: `${customer?.name} ${customer?.surname}`, count: deleteMode === "multi" ? selectedItems.length : 1 })}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    deleteCustomer().then(success => {
                        if (success) {
                            debouncedLoadList();
                        }
                    }).finally(() => {
                        setDeleteMode(undefined);
                        setSelectedItems([]);
                    });
                }
                else {
                    setDeleteMode(undefined);
                }
            }} />}
    </>;
};

export default forwardRef(List) as (
    props: RefAttributes<CustomerListRef>
) => ReturnType<typeof List>;