import Dimensions from "Components/Displays/UnitDisplay/Dimensions";
import ProductImageDisplay from "Components/Displays/ProductImageDisplay";
import { FbaShipmentContract, FbaShipmentItemContract, FbaShipmentItemStatus, FbaShipmentUpdateFormItem, type PrepServiceContract } from "api/types/contracts/shipping";
import { Link } from "react-router-dom";
import { Badge, Button, Card, CardBody, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, Progress, Row, UncontrolledDropdown, UncontrolledPopover } from "reactstrap";
import Weight from "Components/Displays/UnitDisplay/Weight";
import Currency from "Components/Displays/UnitDisplay/Currency";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { commaSeperated } from "helpers/string";
import classNames from "classnames";
import DateInput from "Components/Form/DateInput";
import { useThrottle } from "Components/Hooks/HelperHooks";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TypedShape } from "helpers/types";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import { updateFbaShipmentItems, updateFbaShipmentItemPrepService } from "slices/shipping/fbaShipping/thunk";
import TruncatableText from "Components/Common/TruncatableText";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import ProductEditForm from "Components/Shared/ProductEditForm";
import { useAppDispatch } from "Components/Hooks/StoreHooks";
import BusyOverlay from "Components/Common/BusyOverlay";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getPrepServiceList } from "api/prepService";
import { postGenerateFnskuLabel } from "api/printing";
import { toast } from "react-toastify";
import { multiDownload, withCdn } from "helpers/urlHelper";
import Checkbox from "Components/Form/Checkbox";
import StepInput from "Components/Form/StepInput";
import ClipboardCopy from "Components/Common/ClipboardCopy";
import ReceivingActivityDisplay from "Components/Displays/ReceivingActivityDisplay";
import { patchFbaShipmentItemPrepService, patchFbaShipmentItems, patchProcessFbaShipmentItem } from "api/fbaShipping";
import { setProcessingItem } from "slices/shipping/fbaShipping/reducer";
import Radiobox from "Components/Form/Radiobox";
import PrepServiceSelect from "Components/EntitySelects/PrepServiceSelect";
import _ from "lodash";
import { useToggle } from "@uidotdev/usehooks";

type ProductCardProps = {
    fbaShipment: FbaShipmentContract,
    fbaShipmentItem: FbaShipmentItemContract,
    canEdit?: boolean
}

const createUpdateForm = (fbaShipmentItem: FbaShipmentItemContract): FbaShipmentUpdateFormItem => {
    return {
        productId: fbaShipmentItem.product.productId,
        count: fbaShipmentItem.count,
        fnsku: fbaShipmentItem.fnsku,
        expireDate: fbaShipmentItem.expireDate,
        status: fbaShipmentItem.status,
        prepServices: fbaShipmentItem.prepServices.map(p => p.prepService.prepServiceId)
    };
}

const ProductCard = ({ fbaShipment, fbaShipmentItem, canEdit }: ProductCardProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const bundleItemsRef = useRef<HTMLAnchorElement>(null);
    const splitItemRef = useRef<HTMLAnchorElement>(null);
    const [processCount, setProcessCount] = useState(1);
    const [productModal, toggleProductModal] = useToggle();
    const [printFnskuLabelModal, togglePrintFnskuLabelModal] = useToggle();
    const [prepServiceAddModal, togglePrepServiceAddModal] = useToggle();
    const [fnskuLabelPrintCount, setFnskuLabelPrintCount] = useState(fbaShipmentItem.count);
    const [fnskuLabelPrintCountOption, setFnskuLabelPrintCountOption] = useState<"expected" | "received" | "custom">("expected");
    const [prepServiceToAdd, setPrepServiceToAdd] = useState<PrepServiceContract>();

    const { data: prepServices } = useQuery({
        queryKey: ["prep-services-select"],
        queryFn: () => getPrepServiceList({
            page: 1,
            pageSize: 99
        }),
        select: data => data?.items
    });
    
    const printFnskuLabelMutation = useMutation({
        mutationFn: postGenerateFnskuLabel,
        onSuccess: result => {
            if (result.printQueued) {
                toast.success(t("Label sent to printer"));
            }
            else {
                multiDownload([withCdn(result.filePath)]);
            }
        }
    });

    const updateFbaShipmentItemMutation = useMutation({
        mutationFn: patchFbaShipmentItems,
        onSuccess: shipment => {
            debugger;
            dispatch(setProcessingItem(shipment));
        }
    });

    const validation = useFormik({
        initialValues: createUpdateForm(fbaShipmentItem),
        validationSchema: Yup.object<FbaShipmentUpdateFormItem, TypedShape<FbaShipmentUpdateFormItem>>({
            productId: Yup.string().required(),
            count: Yup.number().required(t("Count is required")),
            fnsku: Yup.string().notRequired(),
            expireDate: Yup.date().notRequired(),
            status: Yup.string<FbaShipmentItemStatus>().required(),
            prepServices: Yup.array(Yup.string().required()).notRequired()
        }),
        onSubmit: values => {
            updateFbaShipmentItemMutation.mutate({
                fbaShipmentId: fbaShipment.fbaShipmentId,
                items: [values]
            });
        }
    });

    const processItemMutation = useMutation({
        mutationFn: patchProcessFbaShipmentItem,
        onSuccess: item => {
            dispatch(setProcessingItem({
                ...fbaShipment,
                items: fbaShipment.items.map(i => i.fbaShipmentItemId === item.fbaShipmentItemId ? item : i)
            }))
        }
    });

    const processItemPrepServiceMutation = useMutation({
        mutationFn: patchFbaShipmentItemPrepService,
        onSuccess: (result, params) => {
            dispatch(setProcessingItem({
                ...fbaShipment,
                items: fbaShipment.items.map(item => item.fbaShipmentItemId === params.fbaShipmentItemId ? {
                    ...item,
                    prepServices: item.prepServices.map(p => p.prepService.prepServiceId === params.prepServiceId ? {
                        ...p,
                        completed: params.completed
                    } : p)
                } : item)
            }));
        }
    });

    const throttledSave = useThrottle(() => {
        validation.submitForm();
    }, 5000);

    useEffect(() => {
        validation.setValues(createUpdateForm(fbaShipmentItem));
    }, [fbaShipmentItem]);

    const marketplaceUrl = useMemo(() => fbaShipment.shipmentTarget.amazonMarketplaceUrlTemplate && fbaShipmentItem.product.asin ? 
        _.template(fbaShipment.shipmentTarget.amazonMarketplaceUrlTemplate)({
            ASIN: fbaShipmentItem.product.asin
        }) : "#", [fbaShipment.shipmentTarget.amazonMarketplaceUrlTemplate, fbaShipmentItem.product.asin]);

    const warehousePrepServices = useMemo(() => prepServices?.filter(p => p.warehouse.warehouseId === fbaShipment.warehouse.warehouseId && (p.showInChecklist 
        || fbaShipmentItem.prepServices.some(ps => ps.prepService.prepServiceId === p.prepServiceId)
        /*|| fbaShipment.prepServices.some(ps => ps.prepService.prepServiceId)*/)) ?? [], 
        [prepServices, fbaShipment.warehouse.warehouseId, fbaShipmentItem.prepServices]);

    const printFnskuLabel = () => {
        let processCount = 1;

        if (fnskuLabelPrintCountOption === "expected") {
            processCount = fbaShipmentItem.count;
        }
        else if (fnskuLabelPrintCountOption === "received") {
            processCount = fbaShipmentItem.accepted;
        }
        else {
            processCount = fnskuLabelPrintCount;
        }

        printFnskuLabelMutation.mutate({
            fbaShipmentItemId: fbaShipmentItem.fbaShipmentItemId,
            count: processCount
        });
    };

    return <>
        <BusyOverlay busy={updateFbaShipmentItemMutation.isPending} spinnerColor="tenant-primary" backgroundColor="light">
            <Card className={classNames("ribbon-box right pt-4 pb-2", {
                "border border-success": validation.values.status === "prepared"
            })}>
                <div className="position-absolute left-0 top-0 p-2">
                    {canEdit && <Button color="link" className="p-0" onClick={() => {
                        if (fbaShipmentItem.status === "prepared") {
                            if (fbaShipmentItem.accepted + fbaShipmentItem.rejected >= fbaShipmentItem.count) {
                                validation.setFieldValue("status", "ready");
                            }
                            else {
                                validation.setFieldValue("status", "waiting");
                            }
                        }
                        else {
                            validation.setFieldValue("status", "prepared");
                        }
                        throttledSave();
                    }}><i className={classNames("ri-checkbox-circle-fill lh-1 fs-2", {
                        "text-success": validation.values.status === "prepared",
                        "text-muted": validation.values.status !== "prepared"
                    })}></i></Button>}
                </div>
                <CardBody>
                    <div className={classNames("ribbon shadow-none top-0 ", {
                        "ribbon-success": fbaShipmentItem.status === "prepared",
                        "ribbon-info": fbaShipmentItem.status === "ready",
                        "ribbon-warning": fbaShipmentItem.status === "waiting"
                    })}>{t(`enums:FbaShipmentItemStatus.${fbaShipmentItem.status}`)}</div>
                    <div className="hstack align-items-start gap-2">
                        <div className="flex-shrink-0">
                            <ProductImageDisplay product={fbaShipmentItem.product} size="md" />
                        </div>
                        <div className="flex-grow-1">
                            <div className="vstack gap-1">
                                <Button color="link" onClick={() => toggleProductModal()} className="link-dark text-start p-0">
                                    <TruncatableText maxLines={2} noUnderline>
                                        {fbaShipmentItem.product.name}
                                    </TruncatableText>
                                </Button>
                                <div className="hstack fs-11">
                                    <a href={marketplaceUrl} target="_blank" rel="noreferrer" className="text-info">
                                        {fbaShipmentItem.product.asin}
                                    </a>
                                    <ClipboardCopy text={fbaShipmentItem.product.asin ?? ""} className="me-2" />
                                    <div className="text-nowrap">
                                        <span>{t("FNSKU")}: </span> 
                                        <span>{fbaShipmentItem.fnsku || "-"}</span> 
                                    </div>
                                    <Button color="ghost-info" size="sm" className="btn-icon" disabled={!fbaShipmentItem.fnsku} onClick={() => togglePrintFnskuLabelModal()}>
                                        <i className="ri-printer-fill"></i>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardBody>
                <CardBody className="border-top py-3">
                    <div className="hstack gap-3">
                        <div className="">
                            <Dimensions 
                                value={fbaShipmentItem.product} 
                                unit={fbaShipmentItem.product.options.unitOfLength}
                                showAlternative />
                        </div>
                        <div className="">
                            <Weight 
                                value={fbaShipmentItem.product.weight} 
                                unit={fbaShipmentItem.product.options.unitOfWeight}
                                showAlternative />
                        </div>
                        <div className="vstack text-end">
                            <div>
                                <span className="text-muted">{t("Buy Price")}: </span>
                                <Currency 
                                    value={fbaShipmentItem.product.buyPrice} 
                                    currency={fbaShipmentItem.product.options.unitOfCurrency} />
                            </div>
                            <div>
                                <span className="text-muted">{t("Ordered Qty")}: </span>
                                <span>{fbaShipmentItem.count}</span>
                            </div>
                        </div>
                    </div>
                </CardBody>
                {(fbaShipmentItem.isBundle || fbaShipmentItem.isSplit) && <CardBody className="border-top py-3">
                    <Row>
                        <Col className="d-flex align-items-center gap-1">
                            {fbaShipmentItem.isBundle && <>
                                <i className="ri-spam-fill text-warning"></i>
                                <Link to="#" className="fs-10" ref={bundleItemsRef}>{t("Bundling from {{products}}", {
                                    products: commaSeperated(fbaShipmentItem.bundleItems.map(p => p.product.asin ?? "???"))
                                })}</Link> 
                                <UncontrolledPopover target={bundleItemsRef} trigger="legacy" style={{ minWidth: "30rem" }}>
                                    <ListGroup>
                                        {fbaShipmentItem.bundleItems.map(p => <ListGroupItem key={p.product.productId}>
                                            <div className="hstack gap-2">
                                                <ProductImageDisplay style={{ flex: 1 }} product={p.product} />
                                                <div style={{ flex: 8 }}><Badge color="primary" className="fs-13">{p.totalNeeded}</Badge> x <strong>{p.product.asin}</strong> ({p.product.name})</div>
                                            </div>
                                        </ListGroupItem>)}
                                    </ListGroup>
                                </UncontrolledPopover>
                            </>}
                            {fbaShipmentItem.isSplit && fbaShipmentItem.splitItem && <>
                                <i className="ri-spam-fill text-warning"></i>
                                <Link to="#" className="fs-10" ref={splitItemRef}>{t("Splitting from {{product}}", {
                                    product: fbaShipmentItem.splitItem.product.asin ?? "???"
                                })}</Link> 
                                <UncontrolledPopover target={splitItemRef} trigger="legacy" style={{ minWidth: "30rem" }}>
                                    <div className="hstack gap-2">
                                        <ProductImageDisplay style={{ flex: 1 }} product={fbaShipmentItem.splitItem.product} />
                                        <div style={{ flex: 8 }}><Badge color="primary" className="fs-13">{fbaShipmentItem.splitItem.splittedItemCount}</Badge> x <strong>{fbaShipmentItem.splitItem.product.asin}</strong> ({fbaShipmentItem.splitItem.product.name})</div>
                                    </div>
                                </UncontrolledPopover>
                            </>}
                        </Col>
                    </Row>
                </CardBody>}
                <CardBody className="border-top py-3">
                    <Row className="g-3">
                        <Col xs={5}>
                            <div className="hstack gap-2">
                                <strong>{t("Checklist")}</strong>
                                <Button color="ghost-dark" size="sm" disabled={!canEdit} className="btn-icon" onClick={() => togglePrepServiceAddModal()}>
                                    <i className="ri-add-circle-fill fs-18"></i>
                                </Button>
                            </div>
                            <div className="vstack mb-0 gap-1">
                                {warehousePrepServices.map(p => {
                                    const itemPrepService = fbaShipmentItem.prepServices.find(fp => fp.prepService.prepServiceId === p.prepServiceId);
                                    const shipmentPrepService = fbaShipment.prepServices.find(fp => fp.prepService.prepServiceId === p.prepServiceId);
                                    const prepService = itemPrepService ?? shipmentPrepService;

                                    return <div key={p.prepServiceId}>
                                        <div className="align-items-center justify-content-between d-flex gap-2">
                                            <Checkbox disabled={!canEdit || updateFbaShipmentItemMutation.isPending || !!shipmentPrepService} value={!!prepService} onChange={val => {
                                                if (val) {
                                                    validation.setFieldValue("prepServices", [...(validation.values.prepServices ?? []), p.prepServiceId]);
                                                }
                                                else {
                                                    validation.setFieldValue("prepServices", (validation.values.prepServices ?? []).filter(ps => ps !== p.prepServiceId));
                                                }
                                                throttledSave();
                                            }}>
                                                <span>{p.name}</span>
                                            </Checkbox>
                                            {prepService && <BusyOverlay 
                                                busy={processItemPrepServiceMutation.isPending && processItemPrepServiceMutation.variables?.prepServiceId === p.prepServiceId} 
                                                size="sm"
                                                backgroundColor="body-secondary"
                                                spinnerColor="tenant-primary"
                                                overlayClassName="flex-shrink-0"
                                                inline
                                                opaque>
                                                <Button color="link" disabled={!canEdit} className="lh-1 p-0" onClick={() => {
                                                    if (!canEdit) {
                                                        return;
                                                    }

                                                    processItemPrepServiceMutation.mutate({
                                                        fbaShipmentId: fbaShipment.fbaShipmentId,
                                                        fbaShipmentItemId: fbaShipmentItem.fbaShipmentItemId,
                                                        prepServiceId: p.prepServiceId,
                                                        completed: !prepService.completed
                                                    });
                                                }}>
                                                    <i className={classNames("ri-checkbox-circle-fill lh-1 fs-4", {
                                                        "text-success": prepService.completed,
                                                        "text-muted": !prepService.completed
                                                    })}></i>
                                                </Button>
                                            </BusyOverlay>}
                                        </div>
                                    </div>;
                                })}
                            </div>
                        </Col>
                        <Col xs={7}>
                            <div className="vstack gap-2">
                                <ReceivingActivityDisplay value={fbaShipmentItem} />
                                <div className="hstack gap-2">
                                    <StepInput min={-1 * (fbaShipmentItem.accepted + fbaShipmentItem.rejected)} value={processCount} onChange={val => setProcessCount(val ?? 1)} />
                                    <div>
                                        <BusyOverlay busy={processItemMutation.isPending} size="sm">
                                            <UncontrolledDropdown group>
                                                <Button color="primary" disabled={processCount === 0} onClick={() => {
                                                    processItemMutation.mutate({
                                                        fbaShipmentId: fbaShipment.fbaShipmentId,
                                                        fbaShipmentItemId: fbaShipmentItem.fbaShipmentItemId,
                                                        type: "accept",
                                                        count: processCount
                                                    });
                                                }}>
                                                    {t("Accept")}
                                                </Button>
                                                <DropdownToggle caret color="primary" disabled={processCount === 0} className="border-start px-2" />
                                                <DropdownMenu className="bg-danger">
                                                    <DropdownItem className="text-bg-danger" onClick={() => {
                                                        processItemMutation.mutate({
                                                            fbaShipmentId: fbaShipment.fbaShipmentId,
                                                            fbaShipmentItemId: fbaShipmentItem.fbaShipmentItemId,
                                                            type: "reject",
                                                            count: processCount
                                                        });
                                                    }}>
                                                        {t("Reject")}
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </BusyOverlay>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
                {(fbaShipmentItem.prepServices.find(p => p.prepService.serviceType === "expireDate") 
                    || fbaShipment.prepServices.find(p => p.prepService.serviceType === "expireDate")) && <>
                    <CardBody className="border-top py-3">
                        <Row>
                            <Col xs={6}>
                                <Label>{t("Expire Date")}</Label>
                                <ValidationWrapper validation={validation} field="expireDate">
                                    <DateInput disabled={!canEdit} size="sm" placeholder={t("Pick a date")} onChange={val => {
                                        if (fbaShipmentItem.expireDate !== val) {
                                            validation.setFieldValue("expireDate", val);
                                            throttledSave();
                                        }
                                    }} />
                                </ValidationWrapper>
                            </Col>
                        </Row>
                    </CardBody>
                </>}
            </Card>
        </BusyOverlay>
        <Modal backdrop="static" isOpen={productModal} toggle={() => toggleProductModal()} size="xl" unmountOnClose>
            <ModalCloseButton onClick={() => toggleProductModal()} />
            <ModalBody className="p-0">
                <ProductEditForm product={fbaShipmentItem.product} onSuccess={toggleProductModal} />
            </ModalBody>
        </Modal>
        <Modal backdrop="static" isOpen={printFnskuLabelModal} toggle={() => togglePrintFnskuLabelModal()} unmountOnClose>
            <ModalCloseButton onClick={() => togglePrintFnskuLabelModal()} />
            <ModalBody>
                <h5 className="mb-1">
                    <span>{t("Print Barcode Options")}</span>
                    <Badge color="fnsku" pill className="ms-2 bg-info-subtle text-info fw-normal">{fbaShipmentItem.fnsku}</Badge>
                </h5>
                <p className="small text-muted">{t("paragraphs:PrintFnskuLabelModalDescription")}</p>
                <div className="fs-14">
                    <ListGroup flush>
                        <ListGroupItem>
                            <Radiobox name="printCount" id="printCount-expected" value={fnskuLabelPrintCountOption === "expected"} onChange={val => setFnskuLabelPrintCountOption("expected")}>
                                <div className="vstack">
                                    <span>{t("Print Barcode Per Expected Product: {{printCount}}", { printCount: fbaShipmentItem.count })}</span>
                                    <span className="text-muted small fw-normal">{t("paragraphs:PrintFnskuLabelExpectedQuantityDescription")}</span>
                                </div>
                            </Radiobox>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Radiobox name="printCount" id="printCount-received" value={fnskuLabelPrintCountOption === "received"} onChange={val => setFnskuLabelPrintCountOption("received")}>
                                <div className="vstack">
                                    <span>{t("Print Barcode Per Received Product: {{printCount}}", { printCount: fbaShipmentItem.accepted })}</span>
                                    <span className="text-muted small fw-normal">{t("paragraphs:PrintFnskuLabelReceivedQuantityDescription")}</span>
                                </div>
                            </Radiobox>
                        </ListGroupItem>
                        <ListGroupItem>
                            <Radiobox name="printCount" id="printCount-custom" value={fnskuLabelPrintCountOption === "custom"} onChange={val => setFnskuLabelPrintCountOption("custom")} className="mt-2">
                                <div className="hstack gap-3">
                                    <span>{t("Enter Barcode Quantity")}:</span>
                                    <div className="w-25">
                                        <StepInput min={1} value={fnskuLabelPrintCount} onChange={val => {
                                            setFnskuLabelPrintCount(val ?? 1);
                                            setFnskuLabelPrintCountOption("custom");
                                        }} size="sm" />
                                    </div>
                                </div>
                            </Radiobox>
                        </ListGroupItem>
                    </ListGroup>
                </div>
                <div className="hstack justify-content-end">
                    <BusyOverlay busy={printFnskuLabelMutation.isPending} size="sm">
                        <Button color="info" onClick={printFnskuLabel} className="btn-label">
                            <i className="ri-printer-fill label-icon"></i>
                            {t("Print")}
                        </Button>
                    </BusyOverlay>
                </div>
            </ModalBody>
        </Modal>
        <Modal backdrop="static" isOpen={prepServiceAddModal} toggle={() => togglePrepServiceAddModal()} size="sm">
            <ModalBody>
                <ModalCloseButton onClick={() => togglePrepServiceAddModal()} />
                <div className="vstack gap-3">
                    <h5 className="mb-0">{t("Add Checklist Item")}</h5>
                    <PrepServiceSelect channel="fba" value={prepServiceToAdd?.prepServiceId} warehouseId={fbaShipment.warehouse.warehouseId} onSelect={setPrepServiceToAdd} />
                    <BusyOverlay busy={updateFbaShipmentItemMutation.isPending} size="sm">
                        <Button color="primary" type="button" disabled={!prepServiceToAdd} className="w-100" onClick={() => {
                            if (prepServiceToAdd) {
                                if (fbaShipmentItem.prepServices?.find(i => i.prepService.prepServiceId === prepServiceToAdd.prepServiceId) 
                                    || fbaShipment.prepServices?.find(i => i.prepService.prepServiceId === prepServiceToAdd.prepServiceId)) {
                                        togglePrepServiceAddModal();
                                        return;
                                }

                                updateFbaShipmentItemMutation.mutate({
                                    fbaShipmentId: fbaShipment.fbaShipmentId,
                                    items: [{
                                        ...validation.values,
                                        prepServices: [...(validation.values.prepServices ?? []), prepServiceToAdd.prepServiceId]
                                    }]
                                }, {
                                    onSuccess: shipment => {
                                        setPrepServiceToAdd(undefined);
                                        togglePrepServiceAddModal();
                                    }
                                });
                            }
                        }}>{t("Add Service")}</Button>
                    </BusyOverlay>
                </div>
            </ModalBody>
        </Modal>
    </>;
}

export default ProductCard;
