import { Maybe } from "helpers/types";
import { CarrierContract } from "./carrier";
import { CustomerContract } from "./customers";
import { ProductContract, ProductSupplierContract } from "./products";
import { UserContract } from "./users";
import { WarehouseContract } from "./warehouses";
import type { ReceivingEntryContract } from "./receivings";

export const InboundStatusList = ["draft", "confirmed", "receiving", "closed", "cancelled"] as const;
export const ShippingPurposeList = ["storage", "fba", "dropship"] as const;
export const TrackingStatusList = ["waiting", "completed"] as const;
export const InboundPackingMethodList = ["unknown", "oneBox", "multipleBoxes"] as const;
export const InboundItemStatusList = ["unreceived", "partiallyReceived", "underReceived", "received", "overReceived"] as const;

export type InboundStatus = typeof InboundStatusList[number];
export type ShippingPurpose = typeof ShippingPurposeList[number];
export type TrackingStatus = typeof TrackingStatusList[number];
export type InboundPackingMethod = typeof InboundPackingMethodList[number];
export type InboundItemStatus = typeof InboundItemStatusList[number];

export type InboundContract = {
    inboundId: string,
    inboundCode: string,
    packingMethod: InboundPackingMethod,
    boxCount: number | undefined,
    notes: string | undefined,
    internalNotes: string | undefined,
    estimatedArrivalDate: Date | undefined,
    inboundStatus: InboundStatus,
    createdAt: Date,
    updatedAt: Date | undefined,
    owner: UserContract,
    warehouse: WarehouseContract,
    trackings: InboundTrackingContract[],
    items: InboundItemContract[],
    suppliers: ProductSupplierContract[],
    customer: CustomerContract,
    receivingEntry: ReceivingEntryContract | undefined
}

export type InboundTrackingContract = {
    inboundTrackingId: string,
    trackingNumber: string,
    status: TrackingStatus,
    createdAt: Date,
    carrier: CarrierContract
}

export type InboundItemContract = {
    quantity: number,
    accepted: number,
    rejected: number,
    product: ProductContract,
    status: InboundItemStatus
}

export type InboundForm = {
    internalNotes?: Maybe<string>,
    notes?: Maybe<string>,
    status?: InboundStatus,
    estimatedArrivalDate?: Maybe<Date>,
    warehouseId?: string,
    items?: InboundFormItem[],
    trackings?: InboundFormTracking[],
    packingMethod?: InboundPackingMethod,
    boxCount?: Maybe<number>,
    suppliers?: Maybe<string[]>,
}

export type InboundFormItem = {
    productId: string,
    quantity: number
}

export type InboundFormTracking = {
    trackingNumber: string,
    carrierId: string
}