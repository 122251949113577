import { useContext, useMemo, useState } from "react";
import { Button, Card, CardBody, Modal, ModalBody, Table } from "reactstrap";
import { InboundContext } from "./InboundContext";
import { useTranslation } from "react-i18next";
import ProductIdentifiersDisplay, { ProductIdentifiersHeader } from "Components/Displays/ProductIdentifiersDisplay";
import ProductImageDisplay from "Components/Displays/ProductImageDisplay";
import TruncatableText from "Components/Common/TruncatableText";
import InboundItemStatusDisplay from "Components/Displays/InboundItemStatusDisplay";
import { useToggle } from "@uidotdev/usehooks";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import type { InboundItemContract } from "api/types/contracts/inbound";
import RejectedItemView from "./_RejectedItemView";
import classNames from "classnames";

type ShippingPlanItemsListProps = {

}

const ShippingPlanItemsList = (props: ShippingPlanItemsListProps) => {
    const [inbound] = useContext(InboundContext);
    const { t } = useTranslation();
    const [rejectedViewModal, toggleRejectedViewModal] = useToggle();
    const [selectedItem, setSelectedItem] = useState<InboundItemContract>();
    const selectedItemUnusables = useMemo(() => {
        if (selectedItem && inbound.receivingEntry) {
            const rejectedProducts = inbound.receivingEntry?.productLabels
                .filter(pl => pl.productLabel.product.productId === selectedItem.product.productId && !!pl.productLabel.unusableProductReason)
                .map(pl => pl.productLabel);
            return rejectedProducts;
        }
        else {
            return [];
        }
    }, [selectedItem, inbound.receivingEntry]);

    return <>
        <Card className="border border-dark-subtle">
            <CardBody>
                <div className="table-responsive table-card">
                    <Table className="mb-0 align-middle" borderless>
                        <thead className="table-tenant-primary table-nowrap">
                            <tr>
                                <th>{t("Product")}</th>
                                <th><ProductIdentifiersHeader /></th>
                                <th>{t("SKU")}</th>
                                <th>{t("Inventory Status")}</th>
                                <th>{t("Expected")}</th>
                                <th>{t("Rejected")}</th>
                                <th>{t("Accepted")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {inbound.items.map((item, i) => <tr key={i}>
                                <td>
                                    <div className="hstack gap-2">
                                        <ProductImageDisplay product={item.product} className="flex-shrink-0" />
                                        <TruncatableText maxLines={2}>{item.product.name}</TruncatableText>
                                    </div>
                                </td>
                                <td><ProductIdentifiersDisplay product={item.product} /></td>
                                <td>{item.product.sku}</td>
                                <td><InboundItemStatusDisplay value={item.status} /></td>
                                <td>{item.quantity}</td>
                                <td>
                                    <Button color="link" className={classNames({
                                        "link-danger fw-semibold": item.rejected > 0,
                                        "link-secondary": item.rejected === 0
                                    })} onClick={() => {
                                        setSelectedItem(item);
                                        toggleRejectedViewModal();
                                    }}>
                                        {item.rejected}
                                    </Button>
                                </td>
                                <td>{item.accepted}</td>
                            </tr>)}
                        </tbody>
                    </Table>
                </div>
            </CardBody>
        </Card>
        <Modal backdrop="static" isOpen={rejectedViewModal} toggle={() => toggleRejectedViewModal()} unmountOnClose>
            <ModalCloseButton onClick={() => toggleRejectedViewModal()} />
            <ModalBody className="px-0">
                {selectedItem && <RejectedItemView unusables={selectedItemUnusables} />}
            </ModalBody>
        </Modal>
    </>;
}

export default ShippingPlanItemsList;