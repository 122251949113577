import { useQuery } from "@tanstack/react-query";
import SelectInput from "Components/Form/SelectInput";
import { getCustomerListForSelect } from "api/customer";
import { CustomerContract } from "api/types/contracts/customers";
import { ListCustomersQueryForSelect } from "api/types/queries";
import { EntitySelectInputProps } from "helpers/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDebounce } from "@uidotdev/usehooks";

type CustomerSelectProps = EntitySelectInputProps<CustomerContract, "customerId"> & {
    companyId?: string,
    detailed?: boolean
}

const CustomerSelect = ({ isMulti, onChange, onSelect, isValid, companyId, detailed, value, ...rest }: CustomerSelectProps) => {
    const { t } = useTranslation();
    const [query, setQuery] = useState<ListCustomersQueryForSelect>({
        companyId: "",
        search: ""
    });
    const queryDebounced = useDebounce(query, 500);
    const [lastData, setLastData] = useState<CustomerContract[]>([]);

    const { data: customers, isFetching: loading } = useQuery({
        queryKey: ["customers", queryDebounced],
        queryFn: () => getCustomerListForSelect(query),
        enabled: !!query.search
    });

    useEffect(() => {
        if (customers) {
            setLastData(customers);
        }
    }, [customers]);

    const customerList = useMemo(() => (customers ?? lastData)?.map(c => ({ 
        value: c.customerId, 
        label: detailed ? <span><span className="font-monospace fs-16">#{c.suitNumber}</span> - {c.name} {c.surname} ({c.user.email})</span> : `${c.name} ${c.surname}`
    })) ?? [], [customers]);

    const onChangedMulti = (val: string[]) => {
        if (!isMulti) {
            return;
        }

        const selected = (customers ?? lastData)?.filter(c => val.includes(c.customerId));

        if (selected) {
            onSelect?.(selected);
            onChange?.(val);
            return;
        }

        onChange?.([]);
        onSelect?.([]);
    };

    const onChangedSingle = (val?: string) => {
        if (isMulti) {
            return;
        }

        if (val) {
            const selected = (customers ?? lastData)?.find(c => c.customerId === val);

            if (selected) {
                onSelect?.(selected);
                onChange?.(selected.customerId);
                return;
            }
        }

        onChange?.(undefined);
        onSelect?.(undefined);
    };

    useEffect(() => {
        setQuery({
            ...query,
            companyId: companyId
        });
    }, [companyId]);

    const onSearch = useCallback((val?: string) => {
        setQuery({
            ...query,
            search: val
        });
    }, []);

    useEffect(() => {
        if (!value) {
            return;
        }

        if (!(customers ?? lastData) || (customers ?? lastData).length === 0) {
            return;
        }

        if (isMulti) {
            onChangedMulti(value);
        }
        else {
            onChangedSingle(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customers]);

    const selectProps = {
        ...rest,
        placeholder: rest.placeholder ?? t("Customer"),
        busy: loading,
        options: customerList,
        noOptionsMessage: t("Start typing to search customers"),
        onSearch: onSearch,
        showClear: true,
        isValid: isValid
    }

    return <>
        {isMulti && <SelectInput<string> isMulti value={value} onChange={onChangedMulti} {...selectProps} />}
        {!isMulti && <SelectInput value={value} onChange={onChangedSingle} {...selectProps} />}
    </>;
}

export default CustomerSelect;