import { useId, type PropsWithChildren } from "react"
import { UncontrolledTooltip } from "reactstrap";

type InfoBubbleProps = PropsWithChildren & {
    text: string
};

const InfoBubble = (props: InfoBubbleProps) => {
    const id = `infobubble${useId().replaceAll(":", "_")}`;

    return <span>
        <span id={id}>{props.children ?? <i className="ri-information-line text-info"></i>}</span>
        <UncontrolledTooltip target={id}>{props.text}</UncontrolledTooltip>
    </span>
}

export default InfoBubble;