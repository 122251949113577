import { useMutation, useQuery } from "@tanstack/react-query";
import { useDebounce, useToggle } from "@uidotdev/usehooks";
import { getBin, getBinByLabel, getBinContainingProductList, getBinList } from "api/bin";
import type { BinContract } from "api/types/contracts/bin";
import type { ListBinsQuery } from "api/types/queries";
import BusyOverlay from "Components/Common/BusyOverlay";
import Loader from "Components/Common/Loader";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import SelectInput from "Components/Form/SelectInput";
import { locationOf } from "helpers/locationOf";
import { trimLabel } from "helpers/string";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState, type ForwardedRef, type RefAttributes } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, Table } from "reactstrap";

type BinPickerProps = {
    value?: string,
    onChange?: (binId: string | undefined) => void,
    onSelect?: (bin: BinContract | undefined) => void,
    warehouseId: string,
    productId?: string,
    hidden?: boolean
}

export type BinPickerRef = {
    setBin: (binCode: string) => void,
    openPicker: () => void,
    reset: () => void
}

const BinPicker = (props: BinPickerProps, ref: ForwardedRef<BinPickerRef>) => {
    const { t } = useTranslation();
    const [pickerModal, togglePickerModal] = useToggle();
    const [query, setQuery] = useState<ListBinsQuery>({
        page: 1,
        pageSize: 10,
        search: "",
        warehouseId: props.warehouseId
    });
    const queryDebounced = useDebounce(query, 500);
    const [selected, setSelected] = useState<BinContract>();
    const [lastData, setLastData] = useState<BinContract[]>([]);

    const { data: bins, isFetching: loading } = useQuery({
        queryKey: ["bins", queryDebounced],
        queryFn: () => getBinList(query),
        select: data => data.items,
        enabled: !!query.search
    });

    const { data: productBins, isFetching: productBinsLoading } = useQuery({
        queryKey: ["productsInBins", props.productId, props.warehouseId],
        queryFn: () => getBinContainingProductList({ productId: props.productId!, warehouseId: props.warehouseId }),
        enabled: !!props.productId
    });

    const loadBin = useMutation({
        mutationFn: async (label: string) => {
            const trimmedLabel = trimLabel(label);

            if (label.startsWith("BIN-")) {
                return await getBinByLabel({ label: trimmedLabel, warehouseId: props.warehouseId });
            }
            else {
                return await getBin({ id: trimmedLabel });
            }
        },
        onSuccess: bin => {
            if (bin) {
                setSelected(bin);
                props.onChange?.(bin.binId);
                props.onSelect?.(bin);
            }
            else {
                setSelected(undefined);
                props.onChange?.(undefined);
                props.onSelect?.(undefined);
            }
        }
    })

    useEffect(() => {
        if (bins && bins.length > 0) {
            setLastData(bins);
        }
    }, [bins]);

    const binList = useMemo(() => (bins ?? lastData)?.map(c => ({ 
        value: c.binId, 
        label: c.binCode
    })) ?? [], [bins]);

    useImperativeHandle(ref, () => {
        return {
            openPicker: () => togglePickerModal(true),
            reset: () => {
                setSelected(undefined);
                props.onChange?.(undefined);
                props.onSelect?.(undefined);
            },
            setBin: (binCode: string) => {
                loadBin.mutate(binCode);
            }
        };
    }, []);

    useEffect(() => {
        if (props.value) {
            if (selected?.binId !== props.value) {
                loadBin.mutate(props.value);
            }
        }
        else {
            setSelected(undefined);
        }
    }, [props.value]);

    const onChange = (val?: string) => {
        if (val) {
            const selected = (bins ?? lastData)?.find(c => c.binId === val);

            if (selected) {
                setSelected(selected);
                return;
            }
        }

        setSelected(undefined);
    };

    useEffect(() => {
        setQuery({
            ...query,
            warehouseId: props.warehouseId
        });
    }, [props.warehouseId]);

    const onSearch = (val?: string) => {
        setQuery({
            ...query,
            search: val
        });
    };

    useEffect(() => {
        if (!props.value) {
            return;
        }

        if (!(bins ?? lastData) || (bins ?? lastData).length === 0) {
            return;
        }

        onChange(props.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bins]);

    const selectProps = {
        placeholder: t("Find BINs"),
        busy: loading,
        options: binList,
        noOptionsMessage: t("Start typing to search BINs"),
        onSearch: onSearch,
        showClear: true
    }

    return <>
        {!props.hidden && <BusyOverlay busy={loadBin.isPending} size="sm" inline backgroundColor="body-secondary" spinnerColor="tenant-primary" opaque>
            <Button color="link" className="link-secondary" size="sm" onClick={() => togglePickerModal()}>
                {selected ? selected.binCode : t("Pick Location")}
            </Button>
        </BusyOverlay>}
        <Modal backdrop="static" isOpen={pickerModal} toggle={() => togglePickerModal()} unmountOnClose>
            <ModalCloseButton onClick={() => {
                togglePickerModal();
                setSelected(undefined);
            }} />
            <ModalBody>
                <h4>{t("Change Location")}</h4>
                <p className="text-muted small">{t("paragraphs:BinPickerDescription")}</p>
                <SelectInput value={selected?.binId} onChange={onChange} {...selectProps} />
                {props.productId && <div className="mt-3">
                    <h5>{t("This product's current locations")}</h5>
                    <p className="text-muted small">{t("paragraphs:BinPickerCurrentLocationDescription")}</p>
                    {productBinsLoading ? <div>
                        <Loader height="100px" />
                    </div> : <>
                        <Table>
                            <thead className="table-light">
                                <tr>
                                    <th>{t("Bin")}</th>
                                    <th>{t("Quantity")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {productBins && productBins.length > 0 ? productBins.map((b, i) => <tr key={i}>
                                    <td>{b.bin.binCode} / <span className="text-muted">{locationOf(b.bin)}</span></td>
                                    <td>{b.quantity}</td>
                                </tr>) : <tr>
                                    <td colSpan={2} className="text-muted text-center">{t("Product not found in any location")}</td>
                                </tr>}
                            </tbody>
                        </Table>
                    </>}
                </div>}
                <div className="hstack gap-2 mt-3">
                    <div>
                        {selected && <>
                            <span className="text-muted">{t("Selected")}: </span>
                            <span>{selected.binCode}</span>
                            <span className="text-muted"> / {locationOf(selected)}</span>
                        </>}
                    </div>
                    <Button color="secondary" className="btn-label ms-auto" onClick={() => {
                        props.onChange?.(selected?.binId);
                        props.onSelect?.(selected);
                        togglePickerModal();
                    }}>
                        <i className="ri-checkbox-circle-fill label-icon"></i>
                        {t("Select")}
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    </>;
}

export default forwardRef(BinPicker) as (
    props: BinPickerProps & RefAttributes<BinPickerRef>
) => ReturnType<typeof BinPicker>;