import type { InboundContract } from "api/types/contracts/inbound";
import { Card, CardBody } from "reactstrap";
import shippingPlanCreated from "assets/images/shipping_plan_created.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type CompletedStepProps = {
    inbound: InboundContract
};

const CompletedStep = (props: CompletedStepProps) => {
    const { t } = useTranslation();

    return <>
        <Card>
            <CardBody>
                <div style={{ height: "400px" }} className="vstack gap-2 justify-content-center align-items-center text-center p-4">
                    <img src={shippingPlanCreated} alt={t("Shipping Plan Created")} style={{ width: "12rem" }} className="mb-2" />
                    <h4 className="fw-bold">{t("Shipping Plan Created")}</h4>
                    <p className="w-75">{t("Your shipping plan with # {{inboundCode}} has been successfully created.", { inboundCode: props.inbound.inboundCode })}</p>
                    <p className="w-75">{t("paragraphs:ShippingPlanCompletedDescription")}</p>
                    <Link to="/shipping-plans" className="btn btn-secondary">
                        {t("Go to Shipping Plans")}
                    </Link>
                </div>
            </CardBody>
        </Card>
    </>;
}

export default CompletedStep;