import ModalCloseButton from "Components/Common/ModalCloseButton";
import TrackingStatusBadge from "Components/Displays/TrackingStatusBadge";
import React, { useRef, useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, Col, Modal, ModalBody, ModalHeader, Row, Table } from "reactstrap";
import CreateBoxForm from "./_CreateBoxForm";
import { ReceivingEntryBoxForm, ReceivingBoxContract } from "api/types/contracts/receivings";
import { addReceivingEntryBox, addReceivingEntryDocument, createReceivingEntry, loadReceivingEntry, loadReceivingForGateEntry, removeReceivingEntryBox, selectReceivingForGateEntry } from "slices/receivings/thunk";
import { toast } from "react-toastify";
import BusyOverlay from "Components/Common/BusyOverlay";
import CustomerSuitDisplay from "Components/Displays/CustomerSuitDisplay";
import DocumentUpload, { DocumentUploadRef } from "Components/Form/DocumentUpload";
import { DocumentForm } from "api/types/contracts/common";
import { InfoAlert, SecondaryAlert } from "Components/Alerts";
import InboundCodeDisplay from "Components/Displays/InboundCodeDisplay";
import ListSummaryDisplay from "Components/Common/ListSummaryDisplay";
import InlineAddressDisplay from "Components/Displays/InlineAddressDisplay";
import _ from "lodash";
import ProductImageDisplay from "Components/Displays/ProductImageDisplay";
import Dialog, { DialogRef } from "Components/Common/Dialog";
import DateDisplay from "Components/Displays/DateDisplay";
import Dimensions from "Components/Displays/UnitDisplay/Dimensions";
import { Link, useSearchParams } from "react-router-dom";
import PrivateDownload from "Components/Common/PrivateDownload";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";

type Step2Props = {
    
};

const Step2 = (props: Step2Props) => {
    const [params, setParams] = useSearchParams();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [createBoxModal, setCreateBoxModal] = useState(params.get("newbox") === "true");
    const [boxBusyState, setBoxBusyState] = useState(false);
    const [documentBusyState, setDocumentBusyState] = useState(false);
    const documentUploadRef = useRef<DocumentUploadRef>(null);
    const dialogRef = useRef<DialogRef>(null);
    const [deletingBox, setDeletingBox] = useState<ReceivingBoxContract>();

    const toggleCreateBoxModal = () => setCreateBoxModal(prev => !prev);

    const { loading, inbound, customer, receivingEntry, warehouse, shippingPurpose } = useAppSelector(
        (state) => ({
            loading: state.Receiving.loading,
            inbound: state.Receiving.gateEntry.inbound,
            customer: state.Receiving.gateEntry.customer,
            receivingEntry: state.Receiving.gateEntry.receiving,
            warehouse: state.Receiving.gateEntry.warehouse,
            shippingPurpose: state.Receiving.gateEntry.shippingPurpose,
            error: state.Receiving.error,
        })
    );

    const selectedWarehouse = receivingEntry?.warehouse || inbound?.warehouse || warehouse;

    const ensureReceivingEntry = useCallback(async () => {
        if (!receivingEntry) {
            const newEntry = await dispatch(createReceivingEntry({
                inboundId: inbound?.inboundId,
                customerId: customer?.customerId,
                warehouseId: selectedWarehouse!.warehouseId,
                shippingPurpose: shippingPurpose
            }));

            if (newEntry) {
                await dispatch(selectReceivingForGateEntry(newEntry));
            }

            return newEntry;
        }
        else {
            return receivingEntry;
        }
    }, [dispatch, customer?.customerId, inbound?.inboundId, receivingEntry, shippingPurpose, selectedWarehouse]);

    const addBox = useCallback(async (quantity: number, box: ReceivingEntryBoxForm) => {
        const receiving = await ensureReceivingEntry();

        if (receiving) {
            try {
                setBoxBusyState(true);
                await dispatch(addReceivingEntryBox({
                    receivingEntryId: receiving.receivingEntryId,
                    boxQuantity: quantity,
                    box: box
                }));
    
                await dispatch(loadReceivingForGateEntry({ 
                    receivingEntryId: receiving.receivingEntryId 
                }));
            } 
            finally {
                setBoxBusyState(false);
            }
        }
        else {
            toast.error(t("Adding box failed. Receiving entry not found"));
        }

        toggleCreateBoxModal();
    }, [dispatch, ensureReceivingEntry, t]);

    const removeBox = useCallback(async (boxId: string) => {
        const receiving = await ensureReceivingEntry();

        if (receiving) {
            try {
                setBoxBusyState(true);
                await dispatch(removeReceivingEntryBox(receiving.receivingEntryId, boxId));
                await dispatch(loadReceivingEntry({ 
                    receivingEntryId: receiving.receivingEntryId 
                }));
            } 
            finally {
                setBoxBusyState(false);
            }
        }
        else {
            toast.error(t("Removing box failed. Receiving entry not found"));
        }
    }, [dispatch, ensureReceivingEntry, t]);

    const addDocument = useCallback(async (document: DocumentForm) => {
        const receiving = await ensureReceivingEntry();

        if (receiving) {
            try {
                setDocumentBusyState(true);
                await dispatch(addReceivingEntryDocument({
                    receivingEntryId: receiving.receivingEntryId,
                    document
                }));
    
                await dispatch(loadReceivingEntry({ 
                    receivingEntryId: receiving.receivingEntryId 
                }));

                documentUploadRef.current?.reset();
            } 
            finally {
                setDocumentBusyState(false);
            }
        }
        else {
            toast.error(t("Adding document failed. Receiving entry not found"));
        }
    }, [dispatch, ensureReceivingEntry, documentUploadRef, t]);

    useEffect(() => {
        if (deletingBox) {
            dialogRef.current?.show();
        }
        else {
            dialogRef.current?.hide();
        }
    }, [deletingBox]);

    //const currentInbound = receivingEntry?.inbound || inbound;
    const currentCustomer = receivingEntry?.customer || customer;

    return <>
        
    </>;
}

export default Step2;