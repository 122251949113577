import type { ProductContract } from "api/types/contracts/products";
import TruncatableText from "Components/Common/TruncatableText";
import ProductImageDisplay from "Components/Displays/ProductImageDisplay";
import NumberInput from "Components/Form/NumberInput";
import TextInput from "Components/Form/TextInput";
import WeightInput from "Components/Form/UnitInputs/WeightInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import { useFormik } from "formik";
import type { TypedShape } from "helpers/types";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, FormGroup, InputGroup, InputGroupText, Label, Row } from "reactstrap";
import * as Yup from "yup";

type FormType = {
    barcode: string, 
    length: number, 
    width: number, 
    height: number, 
    weight: number
}

type EditItemFormProps = {
    product: ProductContract,
    barcode: string,
    onSave: (values: FormType) => void
}

const EditItemForm = (props: EditItemFormProps) => {
    const { t } = useTranslation();

    const validation = useFormik({
        initialValues: {
            barcode: props.barcode,
            length: props.product.length,
            width: props.product.width,
            height: props.product.height,
            weight: props.product.weight
        },
        validationSchema: Yup.object<FormType, TypedShape<FormType>>({
            barcode: Yup.string().required(),
            length: Yup.number().required(),
            width: Yup.number().required(),
            height: Yup.number().required(),
            weight: Yup.number().required()
        }),
        onSubmit: values => {
            props.onSave(values);
        }
    })

    return <div className="mx-4">
        <Form onSubmit={validation.handleSubmit}>
            <h4>{t("Edit Item")}</h4>
            <p className="text-muted small">
                {t("paragraphs:ShippingPlanReceivingEditItemModalDescription")}
            </p>
            <div className="bg-light hstack gap-2 px-4 py-3 mx-n4 mb-2">
                <ProductImageDisplay product={props.product} className="flex-shrink-0" />
                <div className="p-2">
                    <TruncatableText maxLines={2}>{props.product.name}</TruncatableText>
                    <span className="text-muted">{props.product.sku}</span>
                </div>
            </div>
            <h5>{t("Information")}</h5>
            <p className="text-muted small">
                {t("paragraphs:ShippingPlanReceivingEditItemModalInformationHelpText")}
            </p>
            <FormGroup>
                <Label>{t("Barcode")}</Label>
                <div className="form-icon">
                    <ValidationWrapper validation={validation} field="barcode">
                        <TextInput className="form-control-icon" />
                    </ValidationWrapper>
                    <i className="ri-barcode-line fs-18"></i>
                </div>
            </FormGroup>
            <hr />
            <h5>{t("Dimensions & Weight")}</h5>
            <p className="text-muted small">
                {t("paragraphs:ShippingPlanReceivingEditItemModalDimensionsHelpText")}
            </p>
            <Row>
                <Col xs={8}>
                    <FormGroup>
                        <Label>{t("Length")}</Label>
                        <div className="hstack gap-2">
                            <InputGroup>
                                <ValidationWrapper validation={validation} field="length">
                                    <NumberInput placeholder="Width" />
                                </ValidationWrapper>
                                <ValidationWrapper validation={validation} field="width">
                                    <NumberInput placeholder="Height" />
                                </ValidationWrapper>
                                <ValidationWrapper validation={validation} field="height">
                                    <NumberInput placeholder="Length" />
                                </ValidationWrapper>
                                <InputGroupText>{props.product.options.unitOfLength}</InputGroupText>
                            </InputGroup>
                        </div>
                    </FormGroup>
                </Col>
                <Col xs={4}>
                    <FormGroup>
                        <Label>{t("Weight")}</Label>
                        <ValidationWrapper validation={validation} field="weight">
                            <WeightInput unit={props.product.options.unitOfWeight} />
                        </ValidationWrapper>
                    </FormGroup>
                </Col>
            </Row>
            <div className="hstack justify-content-end">
                <Button type="submit" color="secondary" className="btn-label">
                    <i className="ri-save-line label-icon"></i>
                    {t("Save")}
                </Button>
            </div>
        </Form>
    </div>;
}

export default EditItemForm;