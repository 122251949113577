import { useMutation } from "@tanstack/react-query";
import { patchReviewCreditRequest } from "api/payment";
import type { ReviewCreditRequestCommand } from "api/types/commands";
import type { CreditRequestContract } from "api/types/contracts/payment";
import BusyOverlay from "Components/Common/BusyOverlay";
import PrivateDownload from "Components/Common/PrivateDownload";
import CreditRequestStatusBadge from "Components/Displays/CreditLoadRequestBadge";
import DateDisplay from "Components/Displays/DateDisplay";
import Currency from "Components/Displays/UnitDisplay/Currency";
import CurrencyInput from "Components/Form/UnitInputs/CurrencyInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import { useFormik } from "formik";
import type { TypedShape } from "helpers/types";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Form, FormGroup, Label } from "reactstrap";
import * as Yup from "yup";

type FormProps = {
    creditRequest: CreditRequestContract,
    onSuccess: VoidFunction
}

const ApproveForm = (props: FormProps) => {
    const { setPageTitle } = useMetadata();
    const { t } = useTranslation();

    setPageTitle(`${t("Review Credit Load Request")} - ${t("Billing")}`);

    const reviewMutation = useMutation({
        mutationFn: patchReviewCreditRequest,
        onSuccess: (data, variables, context) => {
            if (variables.approved) {
                toast.success(t("Credit request approved"));
            }
            else {
                toast.success(t("Credit request rejected"));
            }
            props.onSuccess();
        }
    });
    
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            amount: props.creditRequest.requestedAmount,
            creditRequestId: props.creditRequest.creditRequestId,
        } as ReviewCreditRequestCommand,
        validationSchema: Yup.object<ReviewCreditRequestCommand, TypedShape<ReviewCreditRequestCommand>>({
            creditRequestId: Yup.string().required(),
            approved: Yup.boolean().required(),
            amount: Yup.number().required(),
        }),
        onSubmit: (values) => {
            reviewMutation.mutate(values);
        },
    });

    const feePercentage = _.round(props.creditRequest.fee * 100 / props.creditRequest.requestedAmount, 2);

    const reviewCreditRequest = (approved: boolean) => {
        validation.setFieldValue("approved", approved);
    }

    return <>
        <Form className="needs-validation" onSubmit={validation.handleSubmit}>
            <div>
                <div className="mb-4">
                    <div>
                        <h5 className="mb-1">{t("Credit Load Request Details")}</h5>
                    </div>
                </div>
                <FormGroup row className="align-items-center">
                    <Label className="col-6">{t("Request ID")}</Label>
                    <div className="col-6">{props.creditRequest.requestCode}</div>
                </FormGroup>
                <FormGroup row className="align-items-center">
                    <Label className="col-6">{t("Date Submitted")}</Label>
                    <div className="col-6"><DateDisplay date={props.creditRequest.createdAt} /></div>
                </FormGroup>
                <hr />
                <FormGroup row className="align-items-center">
                    <Label className="col-6">{t("Customer")}</Label>
                    <div className="col-6">
                        <span>{props.creditRequest.customer.user.name}</span>
                        <br />
                        <span>{props.creditRequest.customer.user.email}</span>
                    </div>
                </FormGroup>
                <FormGroup row className="align-items-center">
                    <Label className="col-6">{t("Payment Method")}</Label>
                    <div className="col-6">{t(`enums:PaymentMethod.${props.creditRequest.paymentMethod}`)}</div>
                </FormGroup>
                <FormGroup row className="align-items-center">
                    <Label className="col-6">{t("Status")}</Label>
                    <div className="col-6"><CreditRequestStatusBadge value={props.creditRequest.status} /></div>
                </FormGroup>
                <hr />
                <FormGroup row className="align-items-center">
                    <Label className="col-6">{t("Amount")}</Label>
                    <div className="col-6">
                        <ValidationWrapper validation={validation} field="amount">
                            <CurrencyInput disabled={props.creditRequest.status !== "pending"} size="sm" currency={props.creditRequest.unitOfCurrency} />
                        </ValidationWrapper>
                    </div>
                </FormGroup>
                <FormGroup row className="align-items-center">
                    <Label className="col-6">{t("Processing Fee ({{fee}}%)", { fee: feePercentage })}</Label>
                    <div className="col-6"><Currency value={props.creditRequest.fee} currency={props.creditRequest.unitOfCurrency} /></div>
                </FormGroup>
                <hr />
                <FormGroup row className="align-items-center">
                    <Label className="col-6">{t("Proof of Payment")}</Label>
                    <div className="col-6">
                        {props.creditRequest.receiptPath ? <PrivateDownload href={props.creditRequest.receiptPath}>
                            {url => <a href={url} target="_blank" rel="noreferrer">{t("View")}</a>}
                        </PrivateDownload> : <span>-</span>}
                    </div>
                </FormGroup>
            </div>
            {props.creditRequest.status === "pending" && <div className="d-flex justify-content-end gap-2">
                <BusyOverlay size="sm" busy={reviewMutation.isPending} inline>
                    <ValidatorButton validation={validation} color="danger" type="submit" className="btn-label" onClick={() => reviewCreditRequest(false)}>
                        <i className="ri-forbid-2-line label-icon"></i>
                        <span>{t("Reject")}</span>
                    </ValidatorButton>
                </BusyOverlay>
                <BusyOverlay size="sm" busy={reviewMutation.isPending} inline>
                    <ValidatorButton validation={validation} color="success" type="submit" className="btn-label" onClick={() => reviewCreditRequest(true)}>
                        <i className="ri-check-line label-icon"></i>
                        <span>{t("Approve")}</span>
                    </ValidatorButton>
                </BusyOverlay>
            </div>}
        </Form>
    </>;
}

export default ApproveForm;