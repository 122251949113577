import { useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { useDebounce } from "@uidotdev/usehooks";
import TableContainer, { selectRowColumn, TableContainerRef } from "Components/Common/TableContainer";
import TruncatableText from "Components/Common/TruncatableText";
import ProductIdentifiersDisplay, { ProductIdentifiersHeader } from "Components/Displays/ProductIdentifiersDisplay";
import ProductImageDisplay from "Components/Displays/ProductImageDisplay";
import ProductStockDisplay from "Components/Displays/ProductStockDisplay";
import Currency from "Components/Displays/UnitDisplay/Currency";
import Dimensions from "Components/Displays/UnitDisplay/Dimensions";
import Weight from "Components/Displays/UnitDisplay/Weight";
import ProductBrandSelect from "Components/EntitySelects/ProductBrandSelect";
import ProductCategorySelect from "Components/EntitySelects/ProductCategorySelect";
import ProductSupplierSelect from "Components/EntitySelects/ProductSupplierSelect";
import SearchBox from "Components/Form/SearchBox";
import { getProductList } from "api/product";
import { ProductContract } from "api/types/contracts/products";
import { ListProductsQuery } from "api/types/queries";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "reactstrap";

type ProductPickListProps = {
    disabledProducts?: (string | ProductContract)[],
    onSelectComplete: (products: ProductContract[]) => void,
    customerId?: string
};

/**
 * Component for displaying a product pick list with various filters and selection options.
 * 
 * @component
 * @example
 * <ProductPickList
 *   customerId="123"
 *   disabledProducts={["product1", "product2"]}
 *   onSelectComplete={(selectedItems) => console.log(selectedItems)}
 * />
 */
const ProductPickList = (props: ProductPickListProps) => {
    const [selectedItems, setSelectedItems] = useState<ProductContract[]>([]);
    
    const [query, setQuery] = useState<ListProductsQuery>({
        page: 1,
        pageSize: 5,
        customerId: props.customerId
    });
    const debouncedValue = useDebounce(query, 500);

    const { data: list, isFetching: loading, refetch: reload } = useQuery({
        queryKey: ["product-list", debouncedValue],
        queryFn: () => getProductList(query),
        staleTime: 1 * 60 * 1000
    });

    const { t } = useTranslation();
    const tableRef = useRef<TableContainerRef>(null);
    
    useEffect(() => {
        tableRef.current?.resetSelection();
    }, [list]);

    // Column
    const columns = useMemo<ColumnDef<ProductContract, any>[]>(() => [
        selectRowColumn(),
        {
            header: t("IMAGE"),
            accessorFn: item => item.options.image,
            enableHiding: true,
            enableColumnFilter: false,
            enableSorting: false,
            cell: (cell) => <ProductImageDisplay product={cell.row.original} />,
        }, {
            header: t("NAME"),
            accessorFn: item => item.name,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => 
                <TruncatableText maxLines={3}>
                    {cell.getValue()}
                </TruncatableText>
        }, {
            id: "ASIN/Barcode",
            header: (props) => <ProductIdentifiersHeader />,
            accessorFn: item => `${item.asin || "-"} / ${item.upc || item.ean || "-"}`,
            enableColumnFilter: false,
            enableSorting: false,
            cell: (cell) => <ProductIdentifiersDisplay product={cell.row.original} />,
        }, {
            header: t("STOCK"),
            accessorFn: item => "-",
            enableColumnFilter: false,
            cell: (cell) => <ProductStockDisplay product={cell.row.original} />
        }, {
            header: t("SKU"),
            accessorFn: item => item.sku,
            enableColumnFilter: false,
        }, {
            header: t("SELL PRICE"),
            accessorFn: item => item.sellPrice,
            enableColumnFilter: false,
            cell: (cell) => <Currency value={cell.row.original.sellPrice} currency={cell.row.original.options.unitOfCurrency} />
        }, {
            header: t("DIMENSIONS"),
            cell: (cell) => {
                const item = cell.row.original;

                return <>
                    <Dimensions value={item} unit={item.options.unitOfLength} />
                    <br />
                    <Weight value={item.weight} unit={item.options.unitOfWeight}  />
                </>;
            }
        }, {
            header: t("BRAND"),
            accessorFn: item => item.brand?.name || "-",
            enableColumnFilter: false,
            cell: (cell) => <TruncatableText maxLines={2}>{cell.getValue()}</TruncatableText>
        }],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [t]
    );

    return <>
        <Row className="g-2">
            <Col lg={12}>
                <Row className="mb-2 g-2">
                    <Col sm={6} md={4} lg={3}>
                        <SearchBox value={query.search || ""} placeholder="Search"
                            onChange={val => setQuery({
                                ...query,
                                search: val
                            })}></SearchBox>
                    </Col>
                    <Col sm={6} md={4} lg={3}>
                        <ProductCategorySelect value={query.categoryId} onSelect={category => {
                            setQuery({
                                ...query,
                                categoryId: category?.categoryId
                            });
                        }} />
                    </Col>
                    <Col sm={6} md={4} lg={3}>
                        <ProductSupplierSelect value={query.supplierId} onSelect={supplier => {
                            setQuery({
                                ...query,
                                supplierId: supplier?.supplierId
                            });
                        }} />
                    </Col>
                    <Col sm={6} md={4} lg={3}>
                        <ProductBrandSelect value={query.brandId} onSelect={brand => {
                            setQuery({
                                ...query,
                                brandId: brand?.brandId
                            });
                        }} />
                    </Col>
                </Row>
                <TableContainer
                    ref={tableRef}
                    busy={loading}
                    columns={columns}
                    data={(list?.items || [])}
                    totalDataLength={list?.totalCount}
                    pagination={{
                        pageIndex: query.page - 1,
                        pageSize: query.pageSize
                    }}
                    onPaginationChanged={pagination => setQuery({
                        ...query,
                        page: pagination.pageIndex + 1,
                        pageSize: pagination.pageSize
                    })}
                    onSelectionChanged={selection => {
                        setSelectedItems(selection);
                    }}
                    enableRowSelection={row => {
                        return !props.disabledProducts?.find(p => row.original.productId === (typeof p === "string" ? p : p.productId))
                    }}
                    divClass="mb-1"
                    tableClass="align-middle"
                />
            </Col>
            <Col className="text-end">
                <Button color="primary" className="" onClick={() => {
                    props.onSelectComplete(selectedItems);
                }}>
                    {selectedItems.length > 0 ? t("Select {{count}} Product", { count: selectedItems.length }) : t("Back")}
                </Button>
            </Col>
        </Row>
    </>;
}

export default ProductPickList;