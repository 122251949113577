import type { ProductLabelContract } from "api/types/contracts/inventory";
import TruncatableText from "Components/Common/TruncatableText";
import ProductImageDisplay from "Components/Displays/ProductImageDisplay";
import _ from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "reactstrap";

type RejectedItemViewProps = {
    unusables: ProductLabelContract[]
}

const RejectedItemView = (props: RejectedItemViewProps) => {
    const { t } = useTranslation();
    const items = useMemo(() => _.chain(props.unusables).groupBy(u => u.unusableProductReason!.unusableProductReasonId).map((group, unusableProductReasonId) => ({
        unusableProductReason: group[0].unusableProductReason,
        quantity: group.length,
        notes: group[0].notes
    })).value(), [props.unusables]);
    const product = props.unusables[0].product;

    return <div className="mx-4">
            <h4>{t("Rejected")}</h4>
            <p className="text-muted small">
                {t("paragraphs:ShippingPlanRejectedItemViewModalDescription")}
            </p>
            <div className="bg-light hstack gap-2 px-4 py-3 mx-n4 mb-2">
                <ProductImageDisplay product={product} className="flex-shrink-0" />
                <div className="p-2">
                    <TruncatableText maxLines={2}>{product.name}</TruncatableText>
                    <span className="text-muted">{product.sku}</span>
                </div>
                <div className="vstack justify-content-center w-50">
                    <span className="text-nowrap">{t("Total Rejected")}</span>
                    <span className="text-secondary fw-bold">{props.unusables.length}</span>
                </div>
            </div>
            <div>
                <Table className="table-nowrap" bordered>
                    <thead className="table-light">
                        <tr>
                            <th style={{ width: "20%" }}>{t("Quantity")}</th>
                            <th style={{ width: "40%" }}>{t("Reason")}</th>
                            <th style={{ width: "35%" }}>{t("Notes")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.length === 0 ? <tr>
                            <td colSpan={4} className="text-center text-muted">{t("No items")}</td>
                        </tr> : <>
                            {items.map((item, i) => <tr key={i}>
                                <td>
                                    {item.quantity}
                                </td>
                                <td>
                                    {item.unusableProductReason?.name}
                                </td>
                                <td>
                                    {item.notes}
                                </td>
                            </tr>)}
                        </>}
                    </tbody>
                </Table>
            </div>
    </div>;
}

export default RejectedItemView;