import { ColumnDef } from "@tanstack/react-table";
import TitleBreadcrumb from "Components/Common/TitleBreadcrumb";
import Dialog, { DialogRef } from "Components/Common/Dialog";
import ListSummaryDisplay from "Components/Common/ListSummaryDisplay";
import Restricted from "Components/Common/Restricted";
import TableContainer, { TableContainerRef } from "Components/Common/TableContainer";
import DateDisplay from "Components/Displays/DateDisplay";
import InboundStatusBadge from "Components/Displays/InboundStatusBadge";
import CompanySelect from "Components/EntitySelects/CompanySelect";
import CustomerSelect from "Components/EntitySelects/CustomerSelect";
import ProductSupplierSelect from "Components/EntitySelects/ProductSupplierSelect";
import WarehouseSelect from "Components/EntitySelects/WarehouseSelect";
import InboundStatusSelect from "Components/EnumSelects/InboundStatusSelect";
import ShippingPurposeSelect from "Components/EnumSelects/ShippingPurposeSelect";
import { useDebounce } from "Components/Hooks/HelperHooks";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import { CompanyContract } from "api/types/contracts/companies";
import { InboundContract, InboundStatus } from "api/types/contracts/inbound";
import { ListInboundsQuery } from "api/types/queries";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Badge, Button, Card, CardBody, CardHeader, CardImg, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, Row, UncontrolledButtonDropdown } from "reactstrap";
import { cancelInbound, loadList } from "slices/inbound/thunk";
import { useImmer } from "use-immer";
import SearchBox from "Components/Form/SearchBox";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import ReceivingActivityDisplay from "Components/Displays/ReceivingActivityDisplay";
import { useToggle } from "@uidotdev/usehooks";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import SimpleBar from "simplebar-react";
import { withCdn } from "helpers/urlHelper";
import TruncatableText from "Components/Common/TruncatableText";
import { useProfile } from "Components/Hooks/ProfileHooks";
import CustomerDisplay from "Components/Displays/CustomerDisplay";
import { useMutation } from "@tanstack/react-query";
import { postDuplicateInbound } from "api/inbound";
import BusyOverlay from "Components/Common/BusyOverlay";

import placeholder from 'assets/images/placeholder.jpg';

const ShippingListPage = () => {
    const [company, setCompany] = useState<CompanyContract>();
    const [selectedItems, setSelectedItems] = useState<InboundContract[]>([]);
    const inbound = useMemo(() => selectedItems[0], [selectedItems]);
    const [productsViewModal, toggleProductsViewModal] = useToggle();
    const { setPageTitle } = useMetadata();
    const { canRead } = useProfile();
    const navigate = useNavigate();
    
    const [query, updateQuery] = useImmer<ListInboundsQuery>({
        page: 1,
        pageSize: 10,
        search: ""
    });

    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const tableRef = useRef<TableContainerRef>(null);
    const dialogRef = useRef<DialogRef>(null);

    const { list, loading } = useAppSelector(
        (state) => ({
            loading: state.Inbound.loading,
            list: state.Inbound.list,
            error: state.Inbound.error,
        })
    );
    
    const debouncedLoadList = useDebounce(() => {
        dispatch(loadList(query)).then(() => {
            tableRef.current?.resetSelection();
        });
    }, 200);

    useEffect(() => {
        debouncedLoadList();
    }, [debouncedLoadList, dispatch, query]);

    setPageTitle(`${t("Shipping Plan List")} - ${t("Receiving")}`);

    const cancel = useCallback(async () => {
        if (inbound) {
            return await dispatch(cancelInbound({
                inboundId: inbound.inboundId
            }));
        }
        
        return false;
    }, [inbound, dispatch]);

    const handleCancelClick = (arg: InboundContract) => {
        setSelectedItems([arg]);
        dialogRef.current?.show();
    };

    const duplicateInboundMutation = useMutation({
        mutationFn: postDuplicateInbound,
        onSuccess: result => {
            if (result) {
                navigate(`/shipping-plan/create/${result.inboundId}`);
            }
        }
    })

    // Column
    const columns = useMemo(() => {
        const columnDefinitions: ColumnDef<InboundContract, any>[] = [{
            header: t("# ID"),
            accessorFn: item => item.inboundCode,
            enableHiding: true,
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cell) => {
                if (cell.row.original.inboundStatus === "draft") {
                    return <Restricted require="receiving.inbound" create fallback={() => <span># {cell.getValue()}</span>}>
                        <Link className="link-secondary" to={`/shipping-plan/create/${cell.row.original.inboundId}`}># {cell.getValue()}</Link>
                    </Restricted>;
                } 
                else {
                    return <Link className="link-secondary" to={`/shipping-plan/${cell.row.original.inboundId}`}># {cell.getValue()}</Link>;
                }
            },
        }, {
            header: t("CREATION DATE"),
            accessorFn: item => item.createdAt,
            enableHiding: true,
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cell) => <DateDisplay date={cell.getValue()} />,
        }, {
            header: t("WAREHOUSE"),
            accessorFn: item => item.warehouse.name,
            enableHiding: true,
            enableColumnFilter: false,
            enableSorting: true
        }, {
            header: t("STATUS"),
            accessorFn: item => item.inboundStatus,
            enableColumnFilter: false,
            cell: (cell) => <InboundStatusBadge value={cell.getValue<InboundStatus>()}></InboundStatusBadge>
        }, {
            header: t("TOTAL SKUs"),
            accessorFn: item => item.items.length,
            enableColumnFilter: false,
            enableSorting: false,
            cell: (cell) => <div>
                {cell.getValue()}
                <Restricted require="receiving.inbound" read>
                    <Button color="link" className="btn-icon link-secondary" size="sm" onClick={() => {
                        setSelectedItems([cell.row.original]);
                        toggleProductsViewModal();
                    }}
                    >
                        <i className="ri-file-search-line fs-16"></i>
                    </Button>
                </Restricted>
            </div>,
        }, {
            header: t("TOTAL ITEMS"),
            accessorFn: item => _.sumBy(item.items, i => i.quantity),
            enableColumnFilter: false,
            enableSorting: false
        },  {
            header: t("INBOUND STATUS"),
            accessorFn: item => item.suppliers,
            enableColumnFilter: false,
            cell: (cell) => <>
                <ReceivingActivityDisplay compact value={{
                    count: _.sumBy(cell.row.original.items, i => i.quantity),
                    accepted: _.sumBy(cell.row.original.items, i => i.accepted),
                    rejected: _.sumBy(cell.row.original.items, i => i.rejected),
                }} />
            </>
        }, {
            header: t("SUPPLIERS"),
            accessorFn: item => item.suppliers,
            enableColumnFilter: false,
            cell: (cell) => <>
                <ListSummaryDisplay items={cell.row.original.suppliers} displayFn={item => item.name} />
            </>
        }, {
            header: t("INITIAL ENTRY"),
            accessorFn: item => item.receivingEntry?.createdAt,
            enableHiding: true,
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cell) => <DateDisplay date={cell.getValue()} />,
        }, {
            id: t("ACTIONS"),
            enableHiding: false,
            cell: (cell) => {
                return <>
                    <td>
                        <BusyOverlay busy={duplicateInboundMutation.isPending && duplicateInboundMutation.variables.inboundId === cell.row.original.inboundId} size="sm" backgroundColor="body-secondary" spinnerColor="tenant-primary" inline opaque>
                            <UncontrolledButtonDropdown>
                                <DropdownToggle color="ghost-primary" size="sm" className="btn-icon rounded-circle">
                                    <i className="ri-more-2-fill fs-16"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <Restricted require="receiving.inbound" create>
                                        <DropdownItem onClick={() => {
                                            duplicateInboundMutation.mutate({ 
                                                inboundId: cell.row.original.inboundId 
                                            });
                                        }}>
                                            <div className="hstack gap-2 align-items-center">
                                                <i className="ri-file-copy-2-line fs-16"></i>
                                                <span>{t("Duplicate")}</span>
                                            </div>
                                        </DropdownItem>
                                    </Restricted>
                                    <DropdownItem disabled={!["draft", "confirmed"].includes(cell.row.original.inboundStatus)} 
                                        onClick={() => handleCancelClick(cell.row.original)}>
                                        <div className="hstack gap-2 align-items-center">
                                            <i className="ri-close-circle-line fs-16"></i>
                                            {t("Cancel Shipping Plan")}
                                        </div>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </BusyOverlay>
                    </td>
                </>;
            },
        }];

        if (canRead("receiving.inbound")) {
            columnDefinitions.splice(2, 0, {
                header: t("CUSTOMER"),
                accessorFn: item => item.customer?.name,
                enableHiding: true,
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell) => <>
                    <CustomerDisplay customer={cell.row.original.customer} />
                </>,
            });
        }

        return columnDefinitions;
    }, [t, duplicateInboundMutation] );

    return <>
        <div className="page-content">
            <Container fluid>
                <TitleBreadcrumb active={t("Shipping Plans")} parents={[t("Receving")]} />
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader className="border-0">
                                <Row className="align-items-center gy-3">
                                    <div className="col-sm">
                                        <h5 className="card-title mb-0">{t("Shipping Plans")}</h5>
                                    </div>
                                    <div className="col-sm-auto">
                                        <div className="d-flex gap-1 flex-wrap">
                                            <Restricted require="receiving.inbound" create>
                                                <Link to="/inbounds/new" className="btn btn-info add-btn">
                                                    <i className="ri-add-line align-bottom me-1"></i> {t("Create Shipping Plan")}
                                                </Link>
                                            </Restricted>
                                        </div>
                                    </div>
                                </Row>
                            </CardHeader>
                            <CardBody className="pt-0">
                                <Row className="g-3 mb-3">
                                    <Restricted require="management.company" read>
                                        <Col xs={3}>
                                            <CompanySelect onSelect={company => {
                                                setCompany(company);
                                                updateQuery(q => {
                                                    q.companyId = company?.companyId;
                                                });
                                            }} />
                                        </Col>
                                    </Restricted>
                                    <Restricted require="management.customer" read>
                                        <Col xs={3}>
                                            <CustomerSelect value={query.customers} isMulti companyId={company?.companyId} onSelect={customers => {
                                                updateQuery(q => {
                                                    q.customers = customers.map(c => c.customerId);
                                                });
                                            }} />
                                        </Col>
                                    </Restricted>
                                </Row>
                                <Row className="g-3">
                                    <Col sm={6} md={6} lg={4} className="">
                                        <SearchBox value={query.search} placeholder="Search for tracking number or Shipping Plan (SP- prefix) or suite number" onChange={trackingNumber => {
                                            updateQuery(q => {
                                                q.search = trackingNumber;
                                            });
                                        }} />
                                    </Col>
                                    <Col>
                                        <WarehouseSelect value={query.destinationWarehouseId} companyId={company?.companyId} onSelect={warehouse => {
                                            updateQuery(q => {
                                                q.destinationWarehouseId = warehouse?.warehouseId;
                                            });
                                        }} />
                                    </Col>
                                    <Col>
                                        <InboundStatusSelect value={query.statuses} isMulti onChange={statuses => {
                                            updateQuery(q => {
                                                q.statuses = statuses;
                                            });
                                        }} />
                                    </Col>
                                    <Col>
                                        <ProductSupplierSelect value={query.suppliers} isMulti onSelect={suppliers => {
                                            updateQuery(q => {
                                                q.suppliers = suppliers.map(s => s.supplierId);
                                            });
                                        }} />
                                    </Col>
                                    <Col>
                                        <ShippingPurposeSelect value={query.shippingPurposes} isMulti onChange={purposes => {
                                            updateQuery(q => {
                                                q.shippingPurposes = purposes;
                                            });
                                        }} />
                                    </Col>
                                </Row>
                                <TableContainer
                                    ref={tableRef}
                                    busy={loading.list}
                                    columns={columns}
                                    data={(list?.items || [])}
                                    totalDataLength={list?.totalCount}
                                    nowrap
                                    pagination={{
                                        pageIndex: query.page - 1,
                                        pageSize: query.pageSize
                                    }}
                                    onPaginationChanged={pagination => updateQuery(q => {
                                        q.page = pagination.pageIndex + 1;
                                        q.pageSize = pagination.pageSize;
                                    })}
                                    onSelectionChanged={selection => {
                                        setSelectedItems(selection);
                                    }}
                                    enableRowSelection
                                    divClass="mb-1"
                                    tableClass="align-middle"
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {<Dialog ref={dialogRef} color="warning" buttons={["yes", "no"]} busy={loading.delete} iconClass="ri-close-circle-line"  
                    message={`Do you want to continue?`} title={t("Cancelling Shipping Plan")}
                    onButtonClick={(button, hide) => {
                        if (button === "yes") {
                            cancel().then(success => {
                                if (success) {
                                    debouncedLoadList();
                                }
                            }).finally(() => {
                                hide();
                                setSelectedItems([]);
                            });
                        }
                        else {
                            hide();
                        }
                    }} />}
            </Container>
            {inbound && <Modal backdrop="static" isOpen={productsViewModal} toggle={() => toggleProductsViewModal()} size="lg" unmountOnClose>
                <ModalCloseButton onClick={() => toggleProductsViewModal()} />
                <ModalBody>
                    <div className="mb-3 hstack gap-2">
                        <h5 className="text-primary mb-0 d-flex">
                            <i className="ri-inbox-line me-1"></i>
                            {t("Product List")}
                        </h5>
                        <Badge color="light" className="text-body">{t("{{count}} SKU", { count: inbound.items.length })}</Badge>
                        <Badge color="light" className="text-body">{t("{{count}} item", { count: _.sumBy(inbound.items, i => i.quantity )})}</Badge>
                    </div>
                    <SimpleBar style={{ maxHeight: "600px" }}>
                        <div className="d-grid gap-3" style={{ gridTemplateColumns: "1fr 1fr 1fr 1fr" }}>
                            {inbound.items.map((item, i) => <Card key={i} className="border border-dark-subtle mb-0">
                                {item.product.options.image ? <CardImg top onError={e => {
                                    e.currentTarget.src = placeholder;
                                }} className="aspect-1x1 object-fit-contain p-2" src={withCdn(item.product.options.image)} /> : <CardImg top src={placeholder} className="aspect-1x1 object-fit-contain p-2" />}
                                <CardBody className="p-2">
                                    <Badge color="light" className="text-body p-3 fs-14 w-100 fw-normal">SKU: {item.product.sku ?? "-"}</Badge>
                                    <div className="p-2">
                                        <TruncatableText maxLines={2}>{item.product.name}</TruncatableText>
                                    </div>
                                </CardBody>
                            </Card>)}
                        </div>
                    </SimpleBar>
                    <div className="d-flex justify-content-end mt-3">
                        <Link to={`/shipping-plan/${inbound.inboundId}`} className="btn btn-secondary btn-label">
                            <i className=" ri-arrow-right-up-line label-icon"></i>
                            {t("Go to Shipping Plan")}
                        </Link>
                    </div>
                </ModalBody>
            </Modal>}
        </div>
    </>;
}

export default ShippingListPage;