import { APIClient, PagedList } from "helpers/api_helper";
import { GetFbaShippingQuery, ListFbaShipmentsQuery, ListShipmentTargetsQuery } from "./types/queries";
import { AddFbaShipmentDocumentsCommand, AddFbaShipmentItemsCommand, ClearFbaShipmentDocumentsCommand, CreateFbaShipmentCommand, CreateFbaShipmentTransportsCommand, CancelFbaShipmentCommand, DeleteFbaShipmentItemCommand, DeleteFbaShipmentTransportCommand, ProcessFbaShipmentItemCommand, UpdateFbaShipmentCommand, UpdateFbaShipmentItemPrepServiceCommand, UpdateFbaShipmentItemsCommand, UpdateFbaShipmentPackagesCommand, type StartProcessFbaShipmentCommand, type DuplicateFbaShipmentCommand } from "./types/commands";
import { FbaShipmentContract, ShipmentTargetContract, type FbaShipmentItemContract } from "./types/contracts/shipping";

const api = new APIClient();

export const getFbaShipment = async (data: GetFbaShippingQuery) => {
    const result = await api.get<FbaShipmentContract>(`/api/fbaShipping/${data.fbaShipmentId}`);
    return result;
}

export const getFbaShipmentsList = async (data: ListFbaShipmentsQuery) => {
    const result = await api.get<PagedList<FbaShipmentContract>>(`/api/fbaShipping`, data);
    return result;
}

export const postFbaShipment = async (data: CreateFbaShipmentCommand): Promise<FbaShipmentContract | undefined> => {
    const response = await api.post(`/api/fbaShipping`, data);
    const locationHeader = response.headers.location as string;
    
    if (locationHeader) {
        return await api.get<FbaShipmentContract>(locationHeader);
    }
    else {
        return undefined;
    }
}

export const patchFbaShipmentItems = async (data: UpdateFbaShipmentItemsCommand) => {
    const result = await api.patch(`/api/fbaShipping/${data.fbaShipmentId}/items`, [...data.items]);
    return result.data as FbaShipmentContract;
}

export const postStartProcessingFbaShipment = async (data: StartProcessFbaShipmentCommand) => {
    const result = await api.post(`/api/fbaShipping/${data.fbaShipmentId}/start`, {});
    return result.data as FbaShipmentContract;
}

export const postFbaShipmentItems = async (data: AddFbaShipmentItemsCommand) => {
    const result = await api.post(`/api/fbaShipping/${data.fbaShipmentId}/items`, [...data.items]);
    return result.data as FbaShipmentContract;
}

export const deleteFbaShipmentItem = async (data: DeleteFbaShipmentItemCommand) => {
    const result = await api.delete(`/api/fbaShipping/${data.fbaShipmentId}/items/${data.fbaShipmentItemId}`);
    return result.data as FbaShipmentContract;
}

export const patchFbaShipment = async (data: UpdateFbaShipmentCommand) => {
    const { fbaShipmentId, ...rest} = data;
    const result = await api.patch(`/api/fbaShipping/${fbaShipmentId}`, { ...rest });

    return result.data as FbaShipmentContract;
}

export const putFbaShipmentPackages = async (data: UpdateFbaShipmentPackagesCommand) => {
    const result = await api.put(`/api/fbaShipping/${data.fbaShipmentId}/packages`, [...data.packages]);

    return result.data as FbaShipmentContract;
}

export const patchProcessFbaShipmentItem = async (data: ProcessFbaShipmentItemCommand) => {
    const { fbaShipmentId, fbaShipmentItemId, type, ...processData } = data;

    const result = await api.patch(`/api/fbaShipping/${fbaShipmentId}/items/${fbaShipmentItemId}/${type}`, processData);
    return result.data as FbaShipmentItemContract;
}

export const patchCancelFbaShipping = async (data: CancelFbaShipmentCommand) => {
    const result = await api.patch(`/api/fbaShipping/${data.fbaShipmentId}/cancel`, {});
    return result;
}

export const putFbaShipmentDocuments = async (data: AddFbaShipmentDocumentsCommand) => {
    const result = await api.put(`/api/fbaShipping/${data.fbaShipmentId}/documents`, [...data.documents]);
    return result.data as FbaShipmentContract;
}

export const deleteFbaShipmentDocuments = async (data: ClearFbaShipmentDocumentsCommand) => {
    const result = await api.delete(`/api/fbaShipping/${data.fbaShipmentId}/documents`);
    return result.data as FbaShipmentContract;
}

export const patchFbaShipmentItemPrepService = async (data: UpdateFbaShipmentItemPrepServiceCommand) => {
    const { fbaShipmentId, fbaShipmentItemId, prepServiceId, ...rest } = data;
    const result = await api.patch(`/api/fbaShipping/${fbaShipmentId}/items/${fbaShipmentItemId}/prepServices/${prepServiceId}`, rest);
    return result;
}

export const getShipmentTargetList = async (data: ListShipmentTargetsQuery) => {
    const result = await api.get<ShipmentTargetContract[]>(`/api/fbaShipping/targets`, data);
    return result;
}

export const postFbaShipmentTransports = async (data: CreateFbaShipmentTransportsCommand) => {
    const result = await api.post(`/api/fbaShipping/${data.fbaShipmentId}/transport`, [...data.fbaShipmentTransports]);
    return result.data as FbaShipmentContract;
}

export const deleteFbaShipmentTransport = async (data: DeleteFbaShipmentTransportCommand) => {
    const result = await api.delete(`/api/fbaShipping/${data.fbaShipmentId}/transport/${data.fbaShipmentTransportId}`);
    return result.data as FbaShipmentContract;
}

export const postDuplicateFbaShipment = async (data: DuplicateFbaShipmentCommand) => {
    await api.post(`/api/fbaShipping/${data.fbaShipmentId}/duplicate`, {});
}