import { APIClient, PagedList } from "helpers/api_helper";
import { GetInboundQuery, ListInboundReceivingsQuery, ListInboundsQuery, SearchInboundsQuery, type DownloadShippingBoxLabelQuery } from "./types/queries";
import { CreateInboundCommand, CancelInboundCommand, SetInboundCompletedCommand, UpdateInboundCommand, type DuplicateInboundCommand } from "./types/commands";
import { InboundContract } from "./types/contracts/inbound";
import { ReceivingEntryContract } from "./types/contracts/receivings";

const api = new APIClient();

export const generateInboundCode = async () => {
    const result = await api.get<string>(`/api/inbound/code`);
    return result;
}

export const getInbound = async (data: GetInboundQuery) => {
    const result = await api.get<InboundContract>(`/api/inbound/${data.inboundId}`);
    return result;
}

export const getInboundList = async (data: ListInboundsQuery) => {
    const result = await api.get<PagedList<InboundContract>>(`/api/inbound`, data);
    return result;
}

export const getInboundReceivingsList = async (data: ListInboundReceivingsQuery) => {
    const result = await api.get<ReceivingEntryContract[]>(`/api/inbound/${data.inboundId}/receivings`);
    return result;
}

export const searchInbounds = async (data: SearchInboundsQuery) => {
    const result = await api.get<InboundContract[]>(`/api/inbound/search`, data);
    return result;
}

export const postInbound = async (data: CreateInboundCommand) => {
    const result = await api.post(`/api/inbound`, data);
    return result.data as InboundContract;
}

export const patchInbound = async (data: UpdateInboundCommand) => {
    if (data.confirm) {
        const result = await api.patch(`/api/inbound/${data.inboundId}/confirm`, { ...data.inbound });
        return result.data as InboundContract;
    }
    else {
        const result = await api.patch(`/api/inbound/${data.inboundId}`, { ...data.inbound });
        return result.data as InboundContract;
    }
}

export const patchCancelInbound = async (data: CancelInboundCommand) => {
    const result = await api.patch(`/api/inbound/${data.inboundId}/cancel`, {});
    return result.data as InboundContract;
}

export const patchCompleteInbound = async (data: SetInboundCompletedCommand) => {
    await api.patch(`/api/inbound/${data.inboundId}/complete`, {});
}

export const postDuplicateInbound = async (data: DuplicateInboundCommand) => {
    const result = await api.post(`/api/inbound/${data.inboundId}/duplicate`, {});
    return result.data as InboundContract;
}

export const downloadShippingBoxLabel = async (data: DownloadShippingBoxLabelQuery) => {
    const { inboundId, ...rest } = data;
    const result = await api.getFile(`/api/inbound/${inboundId}/boxLabel`);
    return result;
}