import { ProductContract } from "api/types/contracts/products";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Badge } from "reactstrap";

type ProductIdentifier = "barcode" | "asin" | "sku";

type ProductIdentifiersDisplayProps = {
    product: ProductContract,
    display?: ProductIdentifier[],
} | {
    barcode?: string,
    asin?: string,
    sku?: string,
    display?: ProductIdentifier[]
};

type ProductIdentifiersHeaderProps = {
    display?: ProductIdentifier[],
};

export const ProductIdentifiersHeader = (props: ProductIdentifiersHeaderProps) => {
    const { t } = useTranslation();
    const display = props.display || ["barcode", "asin"];

    return <div className="hstack">
        {display.includes("barcode") && <Badge color="dark-subtle" className={classNames("text-body")}>{t("Barcode")}</Badge>}
        {display.includes("asin") && <Badge color="info-subtle" className={classNames("text-info")}>ASIN</Badge>}
        {display.includes("sku") && <Badge color="primary-subtle" className={classNames("text-primary")}>SKU</Badge>}
    </div>;
};

const ProductIdentifiersDisplay = ({ display, ...rest } : ProductIdentifiersDisplayProps) => {
    display = display || ["barcode", "asin"];
    const barcode = "product" in rest ? rest.product.upc || rest.product.ean : rest.barcode;
    const asin = "product" in rest ? rest.product.asin : rest.asin;
    const sku = "product" in rest ? rest.product.sku : rest.sku;

    return <div className="vstack gap-1">
        {display.includes("barcode") && barcode && <Badge color="dark-subtle" className="text-body user-select-all">{barcode}</Badge>}
        {display.includes("asin") && asin && <Badge color="info-subtle" className="text-info user-select-all">{asin}</Badge>}
        {display.includes("sku") && sku && <Badge color="primary-subtle" className="text-primary user-select-all">{sku}</Badge>}
    </div>;
}

export default ProductIdentifiersDisplay;