import { useMutation, useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { InfoAlert } from "Components/Alerts";
import BusyOverlay from "Components/Common/BusyOverlay";
import Dialog, { DialogRef } from "Components/Common/Dialog";
import Restricted from "Components/Common/Restricted";
import TableContainer, { TableContainerRef, selectRowColumn } from "Components/Common/TableContainer";
import Dimensions from "Components/Displays/UnitDisplay/Dimensions";
import CompanySelect from "Components/EntitySelects/CompanySelect";
import WarehouseSelect from "Components/EntitySelects/WarehouseSelect";
import BinTypeSelect from "Components/EnumSelects/BinTypeSelect";
import SearchBox from "Components/Form/SearchBox";
import SelectInput from "Components/Form/SelectInput";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import { deleteBin, getBinList } from "api/bin";
import { postGenerateBinLabel } from "api/printing";
import type { GenerateBinOrBoxLabelCommand } from "api/types/commands";
import { BinContract, BinLabelStatus, BinType } from "api/types/contracts/bin";
import { CompanyContract } from "api/types/contracts/companies";
import { ListBinsQuery } from "api/types/queries";
import { locationOf } from "helpers/locationOf";
import { SelectOption } from "helpers/types";
import { multiDownload, withCdn } from "helpers/urlHelper";
import _ from "lodash";
import { Ref, RefAttributes, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { useImmer } from "use-immer";

export type BinListRef = {
    reload: (params: Partial<ListBinsQuery>) => void;
}

const labelStatusOptions: SelectOption<BinLabelStatus>[] = [{
    label: "Printed",
    value: "printed"
}, {
    label: "Not Printed",
    value: "notPrinted"
}];

const List = (props: {}, ref: Ref<BinListRef>) => {
    const [company, setCompany] = useState<CompanyContract>();
    const [deleteMode, setDeleteMode] = useState<"single" | "multi">();
    const [selectedItems, setSelectedItems] = useState<BinContract[]>([]);
    const bin = useMemo(() => selectedItems[0], [selectedItems]);
    
    const [query, updateQuery] = useImmer<ListBinsQuery>({
        page: 1,
        pageSize: 10
    });

    const { setPageTitle } = useMetadata();
    const { t } = useTranslation();
    const tableRef = useRef<TableContainerRef>(null);
    const dialogRef = useRef<DialogRef>(null);

    const { data: list, isFetching: loading, refetch: reload } = useQuery({
        queryKey: ["bins", query],
        queryFn: () => getBinList(query)
    });

    setPageTitle(`${t("Bin List")} - ${t("Management")}`);
    
    useEffect(() => {
        tableRef.current?.resetSelection();
    }, [list]);

    useImperativeHandle(ref, () => {
        return {
            reload: params => {
                const newQuery = {
                    ...query,
                    ...params
                };

                updateQuery(newQuery);
                reload();
            }
        };
    }, [updateQuery, reload, query]);

    const generateLabelsMutation = useMutation({
        mutationFn: async (params: GenerateBinOrBoxLabelCommand[]) => {
            return await Promise.all(params.map(postGenerateBinLabel));
        },
        mutationKey: ["generate-bin-labels"],
        onSuccess: result => {
            if (result[0].printQueued) {
                toast.success(t("Bin labels sent to printing"));
            }
            else {
                multiDownload(result.map(r => withCdn(r.filePath)));
            }

            reload();
        }
    });

    const deleteBinMutation = useMutation({
        mutationFn: deleteBin,
        mutationKey: ["delete-bin"],
        onSuccess: () => {
            setSelectedItems([]);
            setDeleteMode(undefined);
            dialogRef.current?.hide();
            reload();
        }
    });

    const batchDeleteBinMutation = useMutation({
        mutationFn: async () => {
            for (const element of selectedItems) {
                await deleteBin({
                    binId: element.binId
                });
            }
        },
        mutationKey: ["batch-delete-warehouse"],
        onSuccess: () => {
            setSelectedItems([]);
            setDeleteMode(undefined);
            dialogRef.current?.hide();
            reload();
        }
    });

    const handleDeleteClick = (arg: BinContract) => {
        setSelectedItems([arg]);
        setDeleteMode("single");
        dialogRef.current?.show();
    };

    const handleMultiDeleteClick = () => {
        setDeleteMode("multi");
        dialogRef.current?.show();
    };

    useEffect(() => {
        if (deleteMode) {
            dialogRef.current?.show();
        }
        else {
            dialogRef.current?.hide();
        }
    }, [deleteMode]);

    const generateLabels = (arg?: BinContract) => {
        generateLabelsMutation.mutate(arg ? [{
            binId: arg.binId
        }] : selectedItems.map(item => ({
            binId: item.binId
        })));
    };

    // Column
    const columns = useMemo<ColumnDef<BinContract, any>[]>(() => [
        selectRowColumn(),
        {
            header: t("ACTIONS"),
            enableHiding: false,
            cell: (cell) => {
                return <>
                    <div className="hstack gap-1">
                        <Link to={`/bins/edit/${cell.row.original.binId}`} className="btn btn-ghost-dark btn-sm btn-icon">
                            <i className="ri-pencil-fill fs-16"></i>
                        </Link>
                        <BusyOverlay 
                            busy={generateLabelsMutation.isPending && !!generateLabelsMutation.variables.find(v => v.binId === cell.row.original.binId)} 
                            size="sm"
                            backgroundColor="body-secondary"
                            spinnerColor="tenant-primary"
                            opaque
                            inline>
                            <Button color="ghost-success" size="sm" className="btn-icon"
                                onClick={() => {
                                    generateLabels(cell.row.original);
                                }}>
                                <i className="ri-printer-line fs-16"></i>
                            </Button>
                        </BusyOverlay>
                        <Button color="ghost-danger" size="sm" className="btn-icon"
                            onClick={() => handleDeleteClick(cell.row.original)}>
                            <i className="ri-delete-bin-5-fill fs-16"></i>
                        </Button>
                    </div>
                </>;
            },
        },
        {
            header: t("# ID"),
            accessorFn: item => item.binCode,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => {
                return <Link to={`/bins/edit/${cell.row.original.binId}`} className="fw-medium link-secondary">{cell.row.original.binCode}</Link>;
            },
        },
        {
            header: t("WAREHOUSE"),
            accessorFn: item => item.warehouse.name,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => {
                return <Link to={`/warehouses/edit/${cell.row.original.warehouse.warehouseId}`} className="fw-medium link-secondary">{cell.row.original.warehouse.name}</Link>;
            },
        },
        {
            header: t("LABEL STATUS"),
            accessorFn: item => item.labelStatus,
            enableColumnFilter: false,
            cell: (cell) => {
                switch (cell.getValue<BinLabelStatus>()) {
                    case "printed":
                        return <span className="badge text-uppercase bg-success-subtle text-success">{t("Printed")}</span>;
                    case "notPrinted":
                        return <span className="badge text-uppercase bg-warning-subtle text-warning">{t("Not Printed")}</span>;
                }
            }
        },
        {
            header: t("TYPE"),
            accessorFn: item => item.type,
            enableColumnFilter: false,
            cell: (cell) => <span>{t(`enums:BinType.${cell.getValue<BinType>()}`)}</span>
        },
        {
            header: t("LOCATION"),
            accessorFn: item => "-",
            enableColumnFilter: false,
            cell: (cell) => <span>{locationOf(cell.row.original)}</span>
        },
        {
            header: t("DIMENSIONS (W/H/L)"),
            enableColumnFilter: false,
            cell: (cell) => <Dimensions value={cell.row.original} unit={cell.row.original.warehouse.settings.unitOfLength} />
        },
        {
            header: t("PRODUCTS"),
            accessorFn: item => item.hasProducts,
            cell: (cell) => {
                return <>
                    {cell.row.original.hasProducts ? 
                        <Link to={`/products?bin=${cell.row.original.binId}`} className="fw-medium link-primary">{t("Show")}</Link> 
                    : 
                        t("Empty")}
                </>;
            }
        }],
        [t, generateLabelsMutation]
    );

    return <>
        <Row>
            <Col lg={12}>
                <Card>
                    <CardHeader className="border-0">
                        <Row className="align-items-center gy-3">
                            <div className="col-sm">
                                <h5 className="card-title mb-0">{t("Bin List")}</h5>
                            </div>
                            <div className="col-sm-auto">
                                <div className="d-flex gap-1 flex-wrap">
                                    <Link to="/bins/new" className="btn btn-info add-btn">
                                        <i className="ri-add-line align-bottom me-1"></i> {t("Add Bins")}
                                    </Link>
                                    <BusyOverlay busy={generateLabelsMutation.isPending && generateLabelsMutation.variables.length >= 2} size="sm">
                                        <Button type="button" color="success" onClick={() => generateLabels()}>
                                            <i className="ri-printer-line align-bottom me-1"></i> {t("Print Labels")}
                                        </Button>
                                    </BusyOverlay>
                                    {selectedItems.length > 0 && deleteMode !== "single" && <Button color="soft-danger" onClick={handleMultiDeleteClick}>
                                        <i className="ri-delete-bin-2-line"></i>
                                    </Button>}
                                </div>
                            </div>
                        </Row>
                    </CardHeader>
                    <CardBody className="pt-0">
                        <div>
                            <Row>
                                <Restricted require="management.company" read>
                                    <Col sm={6} md={6} lg={2}>
                                        <CompanySelect onSelect={company => {
                                            setCompany(company);
                                            updateQuery(q => {
                                                q.warehouseId = undefined;
                                            });
                                        }} />
                                    </Col>
                                </Restricted>
                                <Col sm={6} md={6} lg={2}>
                                    <WarehouseSelect companyId={company?.companyId} value={query.warehouseId} onSelect={warehouse => updateQuery(q => {
                                        q.warehouseId = warehouse?.warehouseId;
                                    })} />
                                </Col>
                                <Col sm={6} md={4} lg={2}>
                                    <SelectInput value={query.labelStatus} options={labelStatusOptions} placeholder="Select label status" showClear
                                        onChange={val => updateQuery(q => {
                                            q.labelStatus = val;
                                        })} ></SelectInput>
                                </Col>
                                <Col sm={6} md={6} lg={2}>
                                    <BinTypeSelect value={query.type} placeholder="Select type" showClear
                                        onChange={(val) => updateQuery(q => {
                                            q.type = val;
                                        })} ></BinTypeSelect>
                                </Col>
                                <Col sm={6} lg={3} className="ms-auto">
                                    <SearchBox value={query.search || ""} placeholder="Search ID or location"
                                        onChange={val => updateQuery(q => {
                                            q.search = val;
                                        })}></SearchBox>
                                </Col>
                            </Row>
                            {query.warehouseId &&
                                <TableContainer
                                    ref={tableRef}
                                    busy={loading}
                                    columns={columns}
                                    nowrap
                                    data={(list?.items || [])}
                                    totalDataLength={list?.totalCount}
                                    pagination={{
                                        pageIndex: query.page - 1,
                                        pageSize: query.pageSize
                                    }}
                                    onPaginationChanged={pagination => updateQuery(q => {
                                        q.page = pagination.pageIndex + 1;
                                        q.pageSize = pagination.pageSize;
                                    })}
                                    onSelectionChanged={selection => {
                                        setSelectedItems(selection);
                                    }}
                                    enableRowSelection
                                    divClass="mb-1"
                                    tableClass="align-middle"
                                />
                            }
                        </div>
                    </CardBody>
                </Card>
                {!query.warehouseId && <InfoAlert>{t("Please select a warehouse to view bins")}</InfoAlert>}
            </Col>
        </Row>
        {<Dialog ref={dialogRef} color="warning" buttons={["yes", "no"]} busy={batchDeleteBinMutation.isPending || deleteBinMutation.isPending} iconClass="ri-delete-bin-line"  
            message={`Do you want to continue?`} title={t("Deleting bin '{{code}}'", { code: bin?.binCode, count: selectedItems.length })}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    if (deleteMode === "multi") {
                        batchDeleteBinMutation.mutate();
                    }
                    else if (deleteMode === "single" && bin) {
                        deleteBinMutation.mutate({
                            binId: bin.binId
                        });
                    }
                    else {
                        hide();
                        setSelectedItems([]);
                    }
                }
                else {
                    hide();
                    if (deleteMode === "single") {
                        setSelectedItems([]);
                    }
                }
            }} />}
    </>;
};

export default forwardRef(List) as (
    props: RefAttributes<BinListRef>
) => ReturnType<typeof List>;