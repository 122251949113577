import BusyOverlay from "Components/Common/BusyOverlay";
import DateDisplay from "Components/Displays/DateDisplay";
import InboundCodeDisplay from "Components/Displays/InboundCodeDisplay";
import InboundStatusBadge from "Components/Displays/InboundStatusBadge";
import Radiobox from "Components/Form/Radiobox";
import SearchBox from "Components/Form/SearchBox";
import { useDebounce } from "Components/Hooks/HelperHooks";
import { useAppDispatch } from "Components/Hooks/StoreHooks";
import { InboundContract } from "api/types/contracts/inbound";
import { SearchInboundsQuery } from "api/types/queries";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Row, Table } from "reactstrap";
import { searchList } from "slices/inbound/thunk";
import { selectInboundForGateEntry } from "slices/receivings/thunk";
import { useImmer } from "use-immer";

type InboundSearchProps = {
    onCustomerSearchRequested: VoidFunction
};

const InboundSearch = (props: InboundSearchProps) => {
    const [inbounds, setInbounds] = useState<InboundContract[]>([]);
    const [selected, setSelected] = useState<InboundContract>();
    const [busy, setBusy] = useState(false);
    const [query, updateQuery] = useImmer<SearchInboundsQuery>({
        search: ""
    });
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    
    // Inside your component
    const debouncedLoadList = useDebounce(() => {
        if (query.search) {
            setBusy(true);
            dispatch(searchList(query)).then(list => {
                setInbounds(list || []);
                setBusy(false);
            });
        }
        else {
            setInbounds([]);
        }
    }, 500);

    useEffect(() => {
        debouncedLoadList();
    }, [debouncedLoadList, dispatch, query]);

    const list = useMemo<(InboundContract | undefined)[]>(() => Array.from({ ...inbounds, length: 5 }), [inbounds]);

    useEffect(() => {
        if (inbounds.length === 1) {
            setSelected(inbounds[0]);
        }
    }, [inbounds]);

    useEffect(() => {
        if (selected) {
            dispatch(selectInboundForGateEntry(selected));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected])
    

    return <>
        <Row>
            <Col xs={8} >
                <SearchBox value={query.search} placeholder={t("#$t(Inbound Number), #$t(Tracking Number), #$t(Suit Number)")} onChange={val => {
                    updateQuery(draft => {
                        draft.search = val;
                    });
                }} />
            </Col>
            <Col xs={4}>
                <Button color="secondary" block onClick={props.onCustomerSearchRequested}>{t("Inbound not found, search for Customer")}</Button>
            </Col>
        </Row>
        <div className="table-responsive table-card mt-2">
            <BusyOverlay busy={busy} backgroundColor="body-secondary" spinnerColor="tenant-primary">
                    <Table striped className="mb-0">
                        <thead>
                            <tr>
                                <th style={{ width: "5%" }}></th>
                                <th>{t("ID")}</th>
                                <th>{t("Total Products")}</th>
                                <th>{t("Status")}</th>
                                <th>{t("Create Date")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.map((inbound, i) => inbound ? <tr key={i}>
                                <td className="text-center">
                                    <Radiobox value={selected?.inboundId === inbound.inboundId} name="inboundSearchItem" onChange={val => val && setSelected(inbound)} />
                                </td>
                                <td><InboundCodeDisplay inboundCode={inbound.inboundCode} /></td>
                                <td>{_.sumBy(inbound.items, 'quantity')}</td>
                                <td><InboundStatusBadge value={inbound.inboundStatus}></InboundStatusBadge></td>
                                <td><DateDisplay date={inbound.createdAt} /></td>
                            </tr> : <tr key={i}><td colSpan={5}>&nbsp;</td></tr>)}
                        </tbody>
                    </Table>
            </BusyOverlay>
        </div>
    </>;
}

export default InboundSearch;