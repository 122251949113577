import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Progress } from "reactstrap";

type ReceivingActivityDisplayProps = {
    value: {
        count: number,
        accepted: number,
        rejected: number
    },
    mini?: boolean,
    compact?: boolean
}

const ReceivingActivityDisplay = (props: ReceivingActivityDisplayProps) => {
    const { t } = useTranslation();
    const receivedTotal = props.value.accepted + props.value.rejected;
    const unreceivedTotal = Math.max(props.value.count - receivedTotal, 0);

    return <div className={classNames("vstack", {
        "gap-2": !props.compact
    })}>
        <div className={classNames("hstack", {
            "gap-2": !props.compact,
            "gap-3": props.compact
        })}>
            <div className={classNames({
                "text-start": !props.mini,
                "text-center": props.mini
            })} style={{ flex: 1 }}>
                {props.compact && <span className="fs-13 fw-semibold">{props.value.rejected}{" "}</span>}
                {!props.mini && <>
                    <span className="fs-11 text-danger">{t("Rejected")}</span>
                    <br />
                </>}
                {!props.compact && <span className="text-danger">{props.value.rejected}</span>}
            </div>
            <div className={classNames({
                "text-start": !props.mini,
                "text-center": props.mini
            })} style={{ flex: 1 }}>
                {props.compact && <span className="fs-13 fw-semibold">{props.value.accepted}{" "}</span>}
                {!props.mini && <>
                    <span className="fs-11 text-success">{t("Accepted")}</span>
                    <br />
                </>}
                {!props.compact && <span className="text-success">{props.value.accepted}</span>}
            </div>
            <div className={classNames({
                "text-start": !props.mini,
                "text-center": props.mini
            })} style={{ flex: 1 }}>
                {props.compact && <span className="fs-13 fw-semibold">{unreceivedTotal}{" "}</span>}
                {!props.mini && <>
                    <span className="fs-11 text-warning">{t("Unreceived")}</span>
                    <br />
                </>}
                {!props.compact && <span className="text-warning">{unreceivedTotal}</span>}
            </div>
        </div>
        <Progress multi style={{ height: "0.3rem" }}>
            <Progress bar color="danger" value={props.value.rejected} max={props.value.count} />
            <Progress bar color="success" value={props.value.accepted} max={props.value.count} />
            <Progress bar color="warning" value={unreceivedTotal} max={props.value.count} />
        </Progress>
    </div>;
}

export default ReceivingActivityDisplay;