import { useTenant } from "Components/Hooks/TenantHooks";
import { toQueryString } from "helpers/api_helper";
import { withCdn } from "helpers/urlHelper";
import { useEffect, useMemo } from "react";
import BusyOverlay from "./BusyOverlay";

type PrivateDownloadProps = {
    href: string | undefined,
    children: (privateLink: string | undefined) => JSX.Element
};

const PrivateDownload = (props: PrivateDownloadProps) => {
    const { href } = props;
    const { storageAuthorization, refreshStorageAuthorization } = useTenant();

    useEffect(() => {
        refreshStorageAuthorization();
    }, []);
    
    const signedUrl = useMemo(() => {
        if (!href) {
            return undefined;
        }

        if (storageAuthorization.mode === "queryString") {
            const query = toQueryString(storageAuthorization.signatureValues);
            return withCdn(`${href}?${query}`);
        }
        else {
            return withCdn(href);
        }
    }, [storageAuthorization.mode, storageAuthorization.signatureValues, href]);

    return <>
        {storageAuthorization.ready ? <>
            {props.children(signedUrl)}
        </> : 
        <BusyOverlay busy={storageAuthorization.loading} size="sm" inline backgroundColor="body-secondary" spinnerColor="tenant-primary">
            {props.children(href)}
        </BusyOverlay>}
    </>;
}

export default PrivateDownload;