import type { TextInputProps } from "Components/Form/TextInput";

export const inputKeyEventHandler = (key: string, callback: NonNullable<TextInputProps["onKeyDown"]>) => {
    return (e => {
        if (e.key === key) {
            callback(e);
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
        else {
            return true;
        }
    }) as TextInputProps["onKeyDown"];
};