import classNames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, UncontrolledButtonDropdown, type InputProps } from "reactstrap";

type SearchBoxProps<TMode extends string = string> = {
    value: string,
    onChange: (val: string, mode?: TMode) => void,
    onFocus?: InputProps["onFocus"],
    onClick?: InputProps["onClick"],
    placeholder?: string,
    modes?: { key: TMode, label: string }[],
    className?: string
}

const SearchBox = <T extends string,>({ className, onChange, modes, value, ...rest }: SearchBoxProps<T>) => {
    const { t } = useTranslation();
    const [searchMode, setSearchMode] = useState<T>();

    return <div className={classNames("search-box d-inline-block w-100", className)}>
        <InputGroup>
            <Input 
                type="text" 
                value={value} onChange={e => {
                    onChange(e.target.value, searchMode);
                }} 
                className="bg-light border-light search" {...rest} />
            {modes && <UncontrolledButtonDropdown>
                <DropdownToggle caret color="light">
                    {searchMode ? modes.find(m => m.key === searchMode)?.label : t("All")}
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={() => setSearchMode(prev => {
                        if (value) {
                            onChange(value);
                        }
                        return undefined;
                    })}>
                        {t("All")}
                    </DropdownItem>
                    {modes.map(mode => <DropdownItem key={mode.key} onClick={() => setSearchMode(prev => {
                        if (value) {
                            onChange(value, mode.key);
                        }
                        return mode.key;
                    })}>
                        {(mode.label)}
                    </DropdownItem>)}
                </DropdownMenu>
            </UncontrolledButtonDropdown>}
        </InputGroup>
        <i className="bx bx-search-alt search-icon"></i>
    </div>;
}

export default SearchBox;