import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { InboundContext } from "./InboundContext";
import { Badge, Button, Card, CardBody } from "reactstrap";
import BusyOverlay from "Components/Common/BusyOverlay";
import { useMutation } from "@tanstack/react-query";
import { downloadShippingBoxLabel } from "api/inbound";
import { multiDownload } from "helpers/urlHelper";

const PackingInfo = () => {
    const { t } = useTranslation();
    const [inbound] = useContext(InboundContext);

    const downloadMutation = useMutation({
        mutationFn: downloadShippingBoxLabel,
        onSuccess: result => {
            multiDownload([result]);
        }
    });
    
    return <Card className="border border-dark-subtle">
        <CardBody>
            <img src={`/inboundPackingMethods/${inbound.packingMethod}.svg`} className="float-end w-25" alt={inbound.packingMethod} />
            <h6 className="fw-semibold text-body">{t("Packing Details")}</h6>
            <div>{t(`enums:InboundPackingMethod.${inbound.packingMethod}`)}</div>
            {inbound.packingMethod === "multipleBoxes" && <Badge color="outline" className="rounded-pill border border-success text-success">{t("{{count}} boxes", { count: inbound.boxCount })}</Badge>}
            {inbound.packingMethod !== "unknown" && <div className="mt-3">
                <h6 className="fw-bold mb-1">{t("Download Box Labels")}</h6>
                <div className="small text-muted my-2">
                    {t("paragraphs:ShippingPlanShippingInfoDownloadBoxLabelsDescription")}
                </div>
                <BusyOverlay busy={downloadMutation.isPending} size="sm" inline>
                    <Button color="secondary" className="btn-label" onClick={() => {
                        downloadMutation.mutate({ inboundId: inbound.inboundId });
                    }}>
                        <i className="label-icon ri-download-2-line"></i>
                        {t("Download")}
                    </Button>
                </BusyOverlay>
            </div>}
        </CardBody>
    </Card>;
}

export default PackingInfo;