import TitleBreadcrumb from "Components/Common/TitleBreadcrumb";
import InboundStatusBadge from "Components/Displays/InboundStatusBadge";
import { useTranslation } from "react-i18next";
import { Badge, Button, Card, CardBody, Col, Row } from "reactstrap";
import ShippingPlanItemsList from "./_ItemsList";
import { useContext, useMemo, useRef } from "react";
import { InboundContext } from "./InboundContext";
import InformationSidebar from "./_InformationSidebar";
import ConsolidatedStatus from "./_ConsolidatedStatus";
import PackingInfo from "./_PackingInfo";
import Trackings from "./_Trackings";
import Note from "./_Note";
import _ from "lodash";
import { useProfile } from "Components/Hooks/ProfileHooks";
import Restricted from "Components/Common/Restricted";
import { Link } from "react-router-dom";
import type { DialogRef } from "Components/Common/Dialog";
import { useMutation } from "@tanstack/react-query";
import { patchCancelInbound } from "api/inbound";
import Dialog from "Components/Common/Dialog";
import { toast } from "react-toastify";

type ShippingPlanViewProps = {
    
}

const ShippingPlanView = (props: ShippingPlanViewProps) => {
    const { t } = useTranslation();
    const [inbound, setContext] = useContext(InboundContext);
    const { userProfile } = useProfile();
    const dialogRef = useRef<DialogRef>(null);

    const summary = useMemo(() => {
        const skuCount = inbound.items.length;
        const totalQuantity = _.sumBy(inbound.items, i => i.quantity);

        return {
            skuCount,
            totalQuantity
        };
    }, [inbound.items]);

    const cancelInboundMutation = useMutation({
        mutationFn: patchCancelInbound,
        onError: () => {
            dialogRef.current?.hide();
        },
        onSuccess: inbound => {
            setContext(inbound);
            dialogRef.current?.hide();
            toast.success(t("Shipping Plan has been cancelled"));
        }
    });

    return <>
        <TitleBreadcrumb title={t("Shipping Plans")} active={`# ${inbound.inboundCode}`} parents={[
            t("Receiving"), {
                title: t("Shipping Plan"),
                to: "/shipping-plans"
            }]} />
        <Card body className="mb-4 hstack gap-2">
            <span>{t("Shipping Plan")} - # {inbound.inboundCode}</span>
            <InboundStatusBadge value={inbound.inboundStatus} />
            {userProfile?.user.userId === inbound.owner.userId && <Button color="danger" className="ms-auto btn-label" onClick={() => {
                dialogRef.current?.show();
            }}>
                <i className="ri-close-circle-line label-icon" />
                {t("Cancel Shipment")}
            </Button>}
            <Restricted require="receiving.grn" write>
                <Link to={`/shipping-plan/${inbound.inboundId}/receiving`} className="btn btn-secondary ms-auto btn-label">
                    <i className=" ri-arrow-right-up-line label-icon"></i>
                    {t("Go to Receiving")}
                </Link>
            </Restricted>
        </Card>
        <Card>
            <CardBody>
                <Row>
                    <Col xs={9}>
                        <div className="mb-3 hstack gap-2">
                            <h5 className="text-primary mb-0 d-flex">
                                <i className="ri-inbox-line me-1"></i>
                                {t("Product List")}
                            </h5>
                            <Badge color="light" className="text-body">{t("{{count}} SKU", { count: summary.skuCount })}</Badge>
                            <Badge color="light" className="text-body">{t("{{count}} item", { count: summary.totalQuantity})}</Badge>
                        </div>
                        <ShippingPlanItemsList />
                    </Col>
                    <Col xs={3}>
                        <h5 className="text-primary d-flex mb-3">
                            <i className=" ri-information-line me-1"></i>
                            {t("Shipping Plan Information")}
                        </h5>
                        <InformationSidebar />
                        <ConsolidatedStatus />
                        <PackingInfo />
                        <Trackings />
                        <Note />
                    </Col>
                </Row>
            </CardBody>
        </Card>
        {<Dialog ref={dialogRef} color="warning" buttons={["yes", "no"]} busy={cancelInboundMutation.isPending} iconClass="ri-close-circle-line"  
            message={`Do you want to continue?`} title={t("Cancelling Shipping Plan")}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    cancelInboundMutation.mutate({
                        inboundId: inbound.inboundId
                    });
                }
                else {
                    hide();
                }
            }} />}
    </>;
}

export default ShippingPlanView;