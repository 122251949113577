import { type EnumSelectInput } from "helpers/types";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { InboundPackingMethodList, type InboundPackingMethod } from "api/types/contracts/inbound";
import { Input, Label } from "reactstrap";

const InboundPackingMethodGridSelect = (props: EnumSelectInput<InboundPackingMethod>) => {
    const { t } = useTranslation();
    const { value, onChange, isMulti, disabledCallback, placeholder, ...rest } = props;

    const options = useMemo(() => InboundPackingMethodList.map(p => ({
        value: p,
        label: t(`enums:InboundPackingMethod.${p}`),
        description: t(`enums:InboundPackingMethod.${p}.description`),
        disabled: disabledCallback?.(p) ?? false
    })), [t]);

    if (isMulti) {
        return "Multi select not supported";
    }

    return <>
        <div className="hstack gap-3 align-items-stretch">
            {options.map(e => <div className="form-check card-radio w-100" key={e.value}>
                <Input name="inboundPackingMethod" disabled={e.disabled}
                    type="radio" className="form-check-input" id={e.value}
                    checked={value === e.value} onChange={() => onChange?.(e.value)} />
                <Label className="form-check-label h-100" htmlFor={e.value}>
                    <img src={`/inboundPackingMethods/${e.value}.svg`} className="float-end w-25" alt={e.value} />
                    <span className="hstack gap-3 align-items-start">
                        <div className="vstack gap-2">
                            <span className="fs-14 text-wrap d-block">
                                <strong>{e.label}</strong>
                            </span>
                            <span className="fs-12 fw-light text-wrap d-block">
                                {e.description}
                            </span>
                        </div>
                    </span>
                </Label>
            </div>)}
        </div>
    </>;
};

export default InboundPackingMethodGridSelect;
