import { useMutation } from "@tanstack/react-query";
import type { InboundContract, InboundForm, InboundPackingMethod } from "api/types/contracts/inbound";
import BusyOverlay from "Components/Common/BusyOverlay";
import InboundPackingMethodGridSelect from "Components/EnumSelects/InboundPackingMethodGridSelect";
import NumberInput from "Components/Form/NumberInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { useFormik } from "formik";
import type { TypedShape } from "helpers/types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, CardHeader, Label } from "reactstrap";
import * as Yup from "yup";

type PackingDetailsStepValues = Pick<InboundForm, "packingMethod" | "boxCount">;

type PackingDetailsStepProps = {
    editable: boolean,
    collapsed: boolean,
    inbound: InboundContract | undefined,
    onEdit: () => void,
    onSaved: (values: PackingDetailsStepValues) => Promise<void>
}

const PackingDetailsStep = (props: PackingDetailsStepProps) => {
    const { t } = useTranslation();

    const validation = useFormik({
        initialValues: {
            packingMethod: props.inbound?.packingMethod,
            boxCount: props.inbound?.boxCount
        } as PackingDetailsStepValues,
        validationSchema: Yup.object<PackingDetailsStepValues, TypedShape<PackingDetailsStepValues>>({
            packingMethod: Yup.string<InboundPackingMethod>().required(t("Packing Method is required")),
            boxCount: Yup.number().when("packingMethod", ([packingMethod], schema) => {
                if (packingMethod === "multipleBoxes") {
                    return schema.required(t("Box Count is required")).min(2, t("Box Count must be at least 2")).max(100, t("Box Count must be at most 100"));
                }

                return schema.notRequired();
            })
        }),
        onSubmit: values => {
            saveMutation.mutate(values);
        }
    });

    useEffect(() => {
        validation.setValues({
            packingMethod: props.inbound?.packingMethod,
            boxCount: props.inbound?.boxCount
        });
    }, [props.inbound]);

    const saveMutation = useMutation({
        mutationFn: props.onSaved
    });

    useEffect(() => {
        if (validation.values.packingMethod === "unknown") {
            validation.setFieldValue("boxCount", undefined);
        }
        else if (validation.values.packingMethod === "oneBox") {
            validation.setFieldValue("boxCount", 1);
        }
        else {
            validation.setFieldValue("boxCount", Math.max(validation.values.boxCount ?? 0, 2));
        }
    }, [validation.values.packingMethod]);

    return <>
        <Card>
            <CardHeader className="py-1">
                <div className="hstack gap-3">
                    {props.collapsed ? <i className="ri-checkbox-circle-fill text-success ri-3x"></i>
                    : <i className="ri-checkbox-circle-line text-light-emphasis ri-3x"></i>}
                    <div>
                        <h4 className="card-title">{t("Packing Details")}</h4>
                        <p className="card-subtitle">{t("paragraphs:ShippingPlanPackingDetailsHeader")}</p>
                    </div>
                    {props.collapsed ? <>
                        <div className="ms-auto text-muted">
                            {t(`enums:InboundPackingMethod.${validation.values.packingMethod}`)}
                        </div>
                        <Button color="outline-info" disabled={!props.editable} className="btn-label" onClick={props.onEdit}>
                            <i className="label-icon ri-pencil-line"></i>
                            {t("Edit")}
                        </Button>
                    </> : <>
                        <BusyOverlay busy={saveMutation.isPending} size="sm" overlayClassName="ms-auto">
                            <ValidatorButton validation={validation} color="info" className="btn-label" onClick={() => {
                                validation.submitForm();
                            }}>
                                <i className="label-icon ri-save-3-line"></i>
                                {t("Save & Continue")}
                            </ValidatorButton>
                        </BusyOverlay>
                    </>}
                </div>
            </CardHeader>
            {!props.collapsed && <CardBody>
                <div className="px-5 py-4" style={{ minHeight: "400px" }}>
                    <h5 className="">{t("paragraphs:ShippingPlanPackingDetailsTitle")}</h5>
                    <p>{t("paragraphs:ShippingPlanPackingDetailsDescription")}</p>
                    <ValidationWrapper validation={validation} field="packingMethod">
                        <InboundPackingMethodGridSelect />
                    </ValidationWrapper>
                    {validation.values.packingMethod === "multipleBoxes" && <>
                        <div className="d-flex mt-2">
                            <div className="ms-auto p-3" style={{ width: "33.33%" }}>
                                <span className="hstack gap-3 align-items-start">
                                    <div className="vstack gap-3">
                                        <span className="fs-14 fw-bold">
                                            <strong>{t("Multiple Box Setup")}</strong>
                                        </span>
                                        <span className="fs-12">
                                            {t("paragraphs:ShippingPlanPackingDetailsMultipleBoxSetupDescription")}
                                        </span>
                                        <div>
                                            <Label className="small fw-bold">{t("Number of Boxes")}</Label>
                                            <div className="form-icon">
                                                <ValidationWrapper validation={validation} field="boxCount">
                                                    <NumberInput className="form-control-icon" min={2} max={100} />
                                                </ValidationWrapper>
                                                <i className="ri-mail-unread-line"></i>
                                            </div>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </>}
                </div>
            </CardBody>}
        </Card>
    </>;
}

export default PackingDetailsStep;