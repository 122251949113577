import { InfoAlert } from "Components/Alerts"
import TruncatableText from "Components/Common/TruncatableText"
import ProductIdentifiersDisplay, { ProductIdentifiersHeader } from "Components/Displays/ProductIdentifiersDisplay"
import ProductImageDisplay from "Components/Displays/ProductImageDisplay"
import { useAppSelector } from "Components/Hooks/StoreHooks"
import { useTranslation } from "react-i18next"
import { Table } from "reactstrap"

type ProductsProps = {
    
}

const Products = (props: ProductsProps) => {
    const { t } = useTranslation();

    const { loading, inbound, error } = useAppSelector(
        (state) => ({
            error: state.Inbound.error,
            inbound: state.Inbound.processingItem!,
            loading: state.Inbound.loading
        })
    ); 

    return inbound.items?.length !== 0 ? <>
        <div className="table-responsive table-card mt-2">
            <Table striped borderless className="table-nowrap mb-0 align-middle">
                <thead>
                    <tr>
                        <th></th>
                        <th>{t("SKU")}</th>
                        <th><ProductIdentifiersHeader /></th>
                        <th>{t("Name")}</th>
                        <th>{t("Units Expected")}</th>
                        <th>{t("Units Located")}</th>
                        <th>{t("Waiting")}</th>
                    </tr>
                </thead>
                <tbody>
                    {inbound.items.map((item, index) => <tr key={index}>
                        <td>
                            <ProductImageDisplay product={item.product} />
                        </td>
                        <td>{item.product.sku}</td>
                        <td><ProductIdentifiersDisplay product={item.product} /></td>
                        <td><TruncatableText maxLines={2}>{item.product.name}</TruncatableText></td>
                        <td>{item.quantity}</td>
                        <td>{item.accepted}</td>
                        <td>{item.quantity === item.accepted ? <span className="text-success">0</span> : <span className="text-warning">{item.quantity - item.accepted}</span>}</td>
                    </tr>)}
                </tbody>
            </Table>
        </div>
    </>
    : <>
        <InfoAlert className="mb-0">{t("No products found")}</InfoAlert>
    </>
}

export default Products;
