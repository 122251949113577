import BusyOverlay from 'Components/Common/BusyOverlay';
import { useTenant } from 'Components/Hooks/TenantHooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import offlineImage from 'assets/images/auth-offline.gif'

const Offline = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { refresh } = useTenant();

    const reload = async () => {
        setLoading(true);
        await refresh();
        setLoading(false);
    }
    
    return <>
        <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
            <div className="bg-overlay"></div>
            <div className="auth-page-content overflow-hidden pt-lg-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col xl={5}>
                            <Card className="overflow-hidden">
                                <CardBody className="p-4">
                                    <div className="text-center">
                                        <img src={offlineImage} alt="" height="210" />
                                        <h3 className="mt-4 fw-semibold">
                                            We're currently offline
                                        </h3>
                                        <p className="text-muted mb-4 fs-14">
                                            You aren't connected to the internet. 
                                            When you're back online refresh the page or hit the button below
                                        </p>
                                        <BusyOverlay busy={loading} size='sm' inline>
                                            <Button color="success" className="btn-border btn-label" onClick={reload}>
                                                <i className="ri-refresh-line label-icon align-middle"></i> {t("Refresh")}
                                            </Button>
                                        </BusyOverlay>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    </>;
};

export default Offline;