import type { UserType } from "api/types/contracts/users";
import type { Operation, Permission } from "../helpers/permissions";

export type MenuItem = {
    id: string,
    label: string,
    icon?: string,
    link?: string | "/#",
    isHeader?: false,
    openState: boolean,
    permission?: Permission,
    operation?: "c" | "r" | "w" | "d",
    userType?: UserType,
    badgeColor?: "success" | "danger" | "warning" | "primary" | "info",
    badgeName?: string,
    parent?: MenuItem,
    children?: MenuItem[]
}

export type HeaderMenuItem = {
    label: string,
    isHeader: true,
    permissions: Permission[]
}

const navigation: (MenuItem | HeaderMenuItem)[] = [{
    id: "dashboard",
    label: "Dashboard",
    icon: "ri-dashboard-2-line",
    link: "/dashboard",
    openState: false
}, {
    label: "Management",
    isHeader: true,
    permissions: ["management.tenants", "management.company", "management.warehouse", "management.customer", "management.prepservice"]
}, {
    id: "companies",
    label: "Companies",
    icon: "ri-building-line",
    link: "/companies",
    permission: "management.company",
    openState: false
}, {
    id: "warehouses",
    label: "Warehouses",
    icon: "ri-building-3-line",
    link: "/warehouses",
    permission: "management.warehouse",
    openState: false
}, {
    id: "locations",
    label: "Locations",
    icon: "ri-archive-drawer-line",
    link: "/locations",
    permission: "management.warehouse",
    openState: false
}, {
    id: "bins",
    label: "Bins",
    icon: "ri-inbox-line",
    link: "/bins",
    permission: "management.warehouse",
    openState: false
}, {
    id: "customers",
    label: "Customers",
    icon: "ri-user-3-line",
    link: "/customers",
    permission: "management.customer",
    openState: false
}, {
    id: "prepServices",
    label: "Prep Services",
    icon: " ri-artboard-2-line",
    link: "/prep-services",
    permission: "management.prepservice",
    openState: false
}, {
    id: "shipping",
    label: "Shipping",
    icon: "ri-truck-line",
    link: "#",
    permission: "management.carrier",
    openState: false,
    children: [{
        id: "carriers",
        label: "Carriers",
        link: "/carriers",
        openState: false
    }, {
        id: "oversize-rules",
        label: "Oversize Rules",
        link: "/carrier-rules/oversize-rules",
        openState: false
    }, {
        id: "overweight-rules",
        label: "Overweight Rules",
        link: "/carrier-rules/overweight-rules",
        openState: false
    }, {
        id: "insurance-rules",
        label: "Insurance Rules",
        link: "/carrier-rules/insurance-rules",
        openState: false
    }, {
        id: "import-tax-rules",
        label: "Import Tax Pricing Rules",
        link: "/carrier-rules/import-tax-rules",
        openState: false
    }]
}, {
    id: "printers",
    label: "Printer Management",
    icon: "ri-printer-line",
    link: "#",
    openState: false,
    permission: "management.printer",
    children: [{
        id: "printers",
        label: "Printers",
        link: "/printers",
        permission: "management.printer",
        openState: false
    }, {
        id: "printer-setup",
        label: "Printer Setup",
        link: "/printing-setup",
        permission: "management.printer",
        openState: false
    }]
}, {
    id: "putaway",
    label: "Putaway",
    icon: "ri-shopping-basket-line",
    link: "#",
    openState: false,
    permission: "receiving.grn",
    children: [{
        id: "putaway-bin",
        label: "Putaway Bin",
        link: "/bin-putaway",
        openState: false
    }, {
        id: "putaway-item",
        label: "Putaway Item",
        link: "/item-putaway",
        openState: false
    }]
}, {
    label: "Receiving",
    isHeader: true,
    permissions: ["receiving.inbound", "receiving.gateentry", "receiving.grn"]
}, {
    id: "shippingPlans",
    label: "Shipping Plans",
    icon: "ri-mail-send-fill",
    link: "#",
    permission: "receiving.inbound",
    openState: false,
    children: [{
        id: "create",
        label: "Create New Plan",
        permission: "receiving.inbound",
        operation: "c",
        link: "/shipping-plan/create",
        openState: false
    }, {
        id: "list",
        label: "List Plans",
        link: "/shipping-plans",
        openState: false
    }]
},
//{
//     id: "inbounds",
//     label: "Inbounds",
//     icon: "ri-mail-send-fill",
//     link: "/inbounds",
//     permission: "receiving.inbound",
//     openState: false
// }, {
//     id: "gate-entry",
//     label: "Gate Entry",
//     icon: "ri-share-forward-2-line",
//     link: "/gate-entry",
//     permission: "receiving.gateentry",
//     openState: false
// }, {
//     id: "receivings",
//     label: "Receivings",
//     icon: "ri-inbox-archive-line",
//     link: "/receivings",
//     permission: "receiving.grn",
//     openState: false
// }
{
    label: "Inventory",
    isHeader: true,
    permissions: []
}, {
    id: "products",
    label: "Products",
    icon: "ri-price-tag-3-line",
    link: "/products",
    openState: false
}, {
    label: "FBA Prep & Shipping",
    isHeader: true,
    permissions: ["shipping.fbashipping"]
}, {
    id: "getaquote",
    label: "Get a Quote",
    icon: "ri-shopping-cart-line",
    link: "/get-a-quote",
    permission: "shipping.fbashipping",
    operation: "c",
    openState: false
}, {
    id: "fbashipments",
    label: "FBA Shipments",
    icon: "ri-amazon-fill",
    link: "/fba-shipments",
    permission: "shipping.fbashipping",
    openState: false
}, {
    label: "Billing",
    isHeader: true,
    permissions: []
}, {
    id: "my-wallet",
    label: "My Wallet",
    icon: "ri-wallet-3-line",
    link: "/my-wallet",
    permission: "billing.credit",
    operation: "c",
    openState: false
}, {
    id: "credit-requests",
    label: "Credit Requests",
    icon: "ri-bank-line",
    link: "/credit-requests",
    permission: "billing.credit",
    operation: "w",
    openState: false
}, {
    id: "invoice-list",
    label: "Invoices",
    icon: "ri-file-list-3-line",
    link: "/invoices",
    openState: false
}];

const fillParent = (item: MenuItem) => {
    if (item.children)
    {
        for (const subItem of item.children) {
            subItem.parent = item;

            fillParent(subItem);
        }
    }
}

for (const item of navigation) {
    if (!("isHeader" in item)) {
        fillParent(item);
    }
}

export default navigation;