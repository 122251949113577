import { type InboundItemStatus } from "api/types/contracts/inbound";
import classNames from "classnames";
import { StatusBadgeProps } from "helpers/types";
import { useTranslation } from "react-i18next";

const InboundItemStatusMap: Record<InboundItemStatus, { className: string }> = {
    "unreceived": { className: "text-dark-subtle" },
    "received": { className: "text-success" },
    "overReceived": { className: "text-danger" },
    "partiallyReceived": { className: "text-dark" },
    "underReceived": { className: "text-warning" }
};

const InboundItemStatusDisplay = (props: StatusBadgeProps<InboundItemStatus>) => {
    const { t } = useTranslation("enums");

    if (props.value === undefined) {
        return <></>;
    }

    const statusName = t(`InboundItemStatus.${props.value}`);
    const { className } = InboundItemStatusMap[props.value];

    return <span className={classNames("text-nowrap", className)}>
        <i className="bx bxs-cube me-1"></i>
        <span>{statusName}</span>
    </span>
}

export default InboundItemStatusDisplay;