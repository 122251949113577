import { ForwardedRef, RefAttributes, forwardRef, useEffect, useId, useImperativeHandle, useRef, useState } from 'react';
import classNames from 'classnames';
// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { FilePondInitialFile, FileStatus, type FilePondFile } from 'filepond';
import config from 'config';
import { withCdn } from 'helpers/urlHelper';
import { getLoggedInUser } from 'helpers/localStorage';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export type FileUploadProps = {
    initialFiles?: string[],
    multiple?: boolean,
    maxFiles?: number,
    name?: string,
    accept?: string[],
    fetchOnly?: boolean,
    onInput?: () => void,
    onUploaded?: (files: string[]) => void
}

export type FileUploadRef = {
    uploadFileFromUrl: (file: string) => void,
    reset: () => void
}

const FileUpload = (props: FileUploadProps, ref: ForwardedRef<FileUploadRef>) => {
    let initialFiles: FilePondInitialFile[] | undefined;
    if (props.initialFiles) {
        initialFiles = props.initialFiles.filter(f => f)?.map<FilePondInitialFile>(f => ({
            options: {
                type: "local"
            },
            source: withCdn(f)
        }));
    }
    const id = useId();

    const [files, setFiles] = useState<any[]>();
    const filePondRef = useRef<FilePond>(null);

    const onProcessFiles = () => {
        const currentFiles = filePondRef.current?.getFiles();
        
        const uploadedFiles = currentFiles?.filter(f => f.status === FileStatus.PROCESSING_COMPLETE || f.status === FileStatus.INIT)?.map(f => f.serverId) || [];

        if (props.onUploaded) {
            
            props.onUploaded(uploadedFiles);
        }
    }

    const onAddFiles = (e: any, file: FilePondFile) => {
        if (props.onInput && file.status !== FileStatus.IDLE) {
            props.onInput();
        }
    }

    useImperativeHandle(ref, () => {
        return {
            uploadFileFromUrl: (file: string) => {
                //fetch(file).then(r => r.blob()).then(filePondRef.current?.addFile);
                setFiles([{
                    options: {
                        type: "input"
                    },
                    source: withCdn(file)
                }])
            },
            reset: () => {
                filePondRef.current?.removeFiles();
                setFiles([]);
            }
        };
    }, []);

    useEffect(() => {
        onProcessFiles();

    // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [files]);
    
    return <>
        <FilePond
            ref={filePondRef}
            files={files !== undefined ? files : initialFiles}
            chunkUploads
            instantUpload
            //chunkForce
            acceptedFileTypes={props.accept}
            onaddfile={onAddFiles}
            onupdatefiles={setFiles}
            onprocessfiles={onProcessFiles}
            onremovefile={onProcessFiles}
            server={props.fetchOnly ? {} : {
                url: config.api.API_URL,
                process: '/api/upload/process',
                restore: '/api/upload/restore?fileId=',
                fetch: '/api/upload/fetch?url=',
                load: (source, load, error, progress, abort, headers) => {
                    fetch(source).then(r => r.blob()).then(load).catch(error);
                    return {
                        abort: () => {
                            abort();
                        },
                    };
                },
                revert: null,
                headers: {
                    "Authorization": `Bearer ${getLoggedInUser()?.token}`,
                    "X-App-Name": "TylocWeb"
                }
            }}
            allowMultiple={props.multiple}
            maxFiles={props.maxFiles}
            name="file"
            id={`fileupload_${id}`}
            className={classNames("filepond", { 
                "filepond-input-multiple" : props.multiple 
            })}
        />
    </>;
};

export default forwardRef(FileUpload) as (
    props: FileUploadProps & RefAttributes<FileUploadRef>
) => ReturnType<typeof FileUpload>;